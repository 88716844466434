import { useTheme } from "@emotion/react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  Typography,
} from "@mui/material";

import React from "react";
import FlexBetween from "./FlexBetween";

const NotificationCard = ({ title, image, description, pushed,chip, date,pushNotificationButton }) => {
  const theme = useTheme();
  function getChipProps(chip) {
    if (chip === "Buy 1 Get 1") {
      return {
        label: chip,
        style: {
          borderColor: "#D23030",
          color: "#D23030",
        },
      };
    }
    if (chip === "Buy 2 Get 2") {
      return {
        label: chip,
        style: {
          borderColor: "#FBAD13",
          color: "#FBAD13",
        },
      };
    } else {
      return {
        label: chip,
        style: {
          borderColor: "#66BB6A",
          color: "#66BB6A",
        },
      };
    }
  }

  const pushNotificationHandler = (id) => {
    console.log("pushNotificationHandler ii:")
  };

  return (
    <Card sx={{ maxWidth: 445, width:"auto" }}>
      <CardContent>
        <FlexBetween gap="0.5rem">
          <CardMedia
            component="img"
            sx={{ width: 60, height: 60, borderRadius: "50%" }}
            alt="Live from space album cover"
            image={image}
            title="green iguana"
          />
          <Box>
            <Typography gutterBottom variant="h5" component="div">
              {title}
            </Typography>
            <Typography variant="body2" color="text.secondary">
            {date}
            {/* 10 April 2024 */}
          </Typography>
            {/* <Chip variant="outlined" size="small" {...getChipProps(chip)} /> */}
          </Box>
        </FlexBetween>
        <Box mt='10px'>
          <Typography variant="body2" color="text.secondary">
            {description}
          </Typography>
          
        </Box>
      </CardContent>
      <CardActions>
        <Box sx={{ display: "flex", justifyContent: "center", width: "100%", mb:"10px" }}>
         {pushed ? <Button
            variant="primary"
            size="small"
            sx={{
              backgroundColor: theme.palette.secondary[400],
              color: theme.palette.primary[600],
              "&:hover": {
                backgroundColor: theme.palette.secondary[500],
                color: "#000000",
              },
            }}
            onClick={pushNotificationButton}
          >
            Repush Notification
          </Button>
          :
          <Button
          variant="primary"
          size="small"
          sx={{
            backgroundColor: theme.palette.secondary[400],
            color: theme.palette.primary[600],
            "&:hover": {
              backgroundColor: theme.palette.secondary[500],
              color: "#000000",
            },
          }}
          onClick={pushNotificationButton}
        >
          Push Notification
        </Button>
          }
        </Box>
      </CardActions>
    </Card>
  );
};

export default NotificationCard;
