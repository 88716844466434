import { Box, IconButton, Menu, MenuItem } from "@mui/material";
import React from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";

const MoreMenu = ({ options }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMore = Boolean(anchorEl);
  const handleMoreClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMoreClose = () => {
    setAnchorEl(null);
  };
  return (
    <Box>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={openMore ? "long-menu" : undefined}
        aria-expanded={openMore ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleMoreClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={openMore}
        onClose={handleMoreClose}
        PaperProps={{
          style: {
            maxHeight: 48 * 4.5,
            width: "20ch",
          },
        }}
      >
        {options?.map((option) => (
          <MenuItem
            key={option}
            selected={option === "Pyxis"}
            onClick={() => {
              handleMoreClose();
              option === "Export to Excel"
                ? console.log("Export to excel Clicked")
                : // <CSVLink>
                  navigate(`/${option.toLowerCase().replace(/\s+/g, "")}`);
            }}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default MoreMenu;
