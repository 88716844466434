import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import {
  useTheme,
  InputAdornment,
  IconButton,
  Avatar,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Paper,
  Box,
  Grid,
  Link,
  Tooltip,
  CircularProgress
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import Typography from "@mui/material/Typography";
import { ThemeProvider } from "@mui/material/styles";
import { Formik } from "formik";
import * as yup from "yup";
import Logo from "../../assets/mzayaLogo.png";
import MzayaImage from "../../assets/MzayaLogoLg.jpeg";
import Header from "../../components/Header";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../features/auth/authSlice";
import { clearMessage } from "../../features/auth/messageSlice";

import {
  fetchUserDetails, getUserDetails
  
} from "../../features/users/userSlice";

import { useNavigate } from "react-router-dom";
function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://www.golalita.com/">
        GoLalita Premium
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function SignIn() {
  const [showPassword, setShowPassword] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const theme = useTheme();

  const navigate = useNavigate();
  const { isLoggedIn } = useSelector((state) => state.auth);
  const { message } = useSelector((state) => state.message);

  const handleSubmit = (values) => {
    console.log(values);
    setLoading(true);

    dispatch(login({ username: values.email,password: values.password }))
      .unwrap()
      .then((res) => {
        if(res.user.accessToken){
          userDetailsHandler();
          navigate("/dashboard");
          setLoading(false);
        }
        // navigate("/profile");
        console.log("handleSubmit login dispacth res:",res)
        //window.location.reload();
      })
      .catch((err) => {
        console.log("handleSubmit login dispacth err:",err)
        setLoading(false);
      });
  };

  const userDetailsHandler = () => {
    console.log("userDetailsHandler")
    dispatch(fetchUserDetails());
  };


  const handleUserLogin = (formValue) => {
    const { username, password } = formValue;
    console.log(formValue);
    // setLoading(true);

    // dispatch(login({ username, password }))
    //   .unwrap()
    //   .then(() => {
    //     // navigate("/profile");
    //     console.log(formValue)
    //     window.location.reload();
    //   })
    //   .catch(() => {
    //     setLoading(false);
    //   });
  };

  const initialValues = {
    email: "",
    password: "",
  };

  const signInSchema = yup.object().shape({
    email: yup
      .string()
      .email("This is not a valid email.")
      .required("Email is requried!"),
    password: yup
      .string()
      // .test(
      //   "len",
      //   "The password must be between 6 and 40 characters.",
      //   (val) =>
      //     val && val.toString().length >= 6 && val.toString().length <= 40
      // )
      .required("Password is required!"),
  });

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  React.useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${MzayaImage})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              p: "20px",
              alignItems: "end",
              backgroundColor: theme.palette.background.alt,
            }}
          >
            {" "}
            <Link
              href="/"
              sx={{
                "&:hover": {
                  color: theme.palette.secondary[500],
                },
              }}
            >
              {" "}
              <Tooltip title="Dashboard">
                <ExitToAppIcon fontSize="large" />
                {/* <DataSaverOffIcon fontSize="large"/> */}
              </Tooltip>
            </Link>
          </Box>
          <Box
            sx={{
              //   my: 8,
              //   mx: 4,
              p: 10,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              backgroundColor: theme.palette.background.alt,
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "black" }}>
              <img src={Logo} alt="Mzaya Premium Loyalty Program" />
            </Avatar>
            {/* <Typography component="h1" variant="h5">
              Sign in
            </Typography> */}
            <Header subtitle="Sign In" />
            <Formik
              onSubmit={handleSubmit}
              initialValues={initialValues}
              validationSchema={signInSchema}
              style={{ marginTop: "20px" }}
            >
              {({
                values,
                errors,
                touched,
                // setFieldValue,
                handleBlur,
                handleChange,
                handleSubmit,
              }) => (
                <Box
                  component="form"
                  noValidate
                  onSubmit={handleSubmit}
                  sx={{ mt: 1 }}
                >
                  <TextField
                    margin="normal"
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                    autoFocus
                    error={!!touched.email && !!errors.email}
                    helperText={touched.email && errors.email}
                  />

                  <TextField
                    margin="normal"
                    fullWidth
                    name="password"
                    label="Password"
                    value={values.password}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type={showPassword ? "text" : "password"}
                    id="password"
                    autoComplete="current-password"
                    error={!!touched.password && !!errors.password}
                    helperText={touched.password && errors.password}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        value="remember"
                        sx={{
                          "&.Mui-checked": {
                            color: theme.palette.secondary[500],
                          },
                        }}
                      />
                    }
                    label="Remember me"
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{
                      mt: 3,
                      mb: 2,
                      backgroundColor: theme.palette.secondary[400],
                      color: theme.palette.primary[600],
                      "&:hover": {
                        backgroundColor: theme.palette.secondary[500],
                        color: "#000000",
                      },
                    }}
                  >
                  {loading ? <CircularProgress size={20} />:"Sign In"}
                  </Button>
                  <Grid container>
                    <Grid item xs>
                      <Link
                        href="/forgotpassword"
                        variant="body2"
                        sx={{
                          "&:hover": {
                            color: theme.palette.secondary[500],
                          },
                        }}
                      >
                        Forgot password?
                      </Link>
                    </Grid>
                    <Grid item>
                      <Link
                        href="/signup"
                        variant="body2"
                        sx={{
                          "&:hover": {
                            color: theme.palette.secondary[500],
                          },
                        }}
                      >
                        {"Don't have an account? Sign Up"}
                      </Link>
                    </Grid>
                  </Grid>
                  <Copyright sx={{ mt: 5, mb: 11 }} />
                </Box>
              )}
            </Formik>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
