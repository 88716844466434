
import React, { useEffect, useState ,useMemo} from "react";
import {
  Box,
  Button,
  Chip,
  Tooltip,
  useMediaQuery,
  Tab,
  Tabs,
  Typography,
  TextField
} from "@mui/material";
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import FlexBetween from "../../components/FlexBetween";
import Header from "../../components/Header";
import AddIcon from "@mui/icons-material/Add";
import PublicIcon from "@mui/icons-material/Public";
import PublicOffIcon from "@mui/icons-material/PublicOff";
import DoneIcon from "@mui/icons-material/Done";
import WarningIcon from "@mui/icons-material/Warning";
import FilterOrganization from "../../components/FilterOrganization";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { DataGrid,GridToolbar } from "@mui/x-data-grid";
import { useTheme } from "@emotion/react";
import Filters from "../../components/FilterLoyalityPoints";


import {
  sendloyaltypoints, get_loyalty_sent, getSentLoyalityPointsData
} from "../../features/loyalityPoints/loyalityPointSlice";

const LoyalityPoints = () => {
  // const theme = useTheme();

  const dispatch = useDispatch();
  const isNonMobile = useMediaQuery("(min-width: 1000px)");

  const sentLoyalityPoints = useSelector(getSentLoyalityPointsData());
  console.log("sentLoyalityPoints 1:", sentLoyalityPoints)
  const [value, setValue] = useState(0);

  useEffect(() => {
    dispatch(get_loyalty_sent({
      "senderId":"64a5212976f2b543458585b3", // obligatoire 
      "typeReceiver":"golalita"
      }));

  }, [dispatch]);

  const onSearchHandler = (i)=>{
    console.log("onSearchHandler indexzzzzzzzz:",i)
    dispatch(get_loyalty_sent(i));
  }
  // useMemo(() => {
  //   searchHandler();
  // }, []);


  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };


  function getChipProps(params) {
    if (params.value === "Pending") {
      return {
        icon: <WarningIcon style={{ fill: "#D23030" }} />,
        label: params.value,
        style: {
          borderColor: "#D23030",
          color: "#D23030",
        },
      };
    } else {
      return {
        icon: <DoneIcon style={{ fill: "#66BB6A" }} />,
        label: params.value,
        style: {
          borderColor: "#66BB6A",
          color: "#66BB6A",
        },
      };
    }
  }

  function getModeProps(params) {
    if (params.value === "Online") {
      return {
        icon: <PublicIcon style={{ fill: "black" }} />,
        label: params.value,

        style: {
          backgroundColor: "#ffda85",
          color: "black",
        },
      };
    } else {
      return {
        icon: <PublicOffIcon style={{ fill: "black" }} />,
        label: params.value,
        style: {
          borderColor: "#2D68EC",
          color: "black",
        },
      };
    }
  }

  const handleClick = (event, cellValues) => {
    console.log(cellValues.row._id); //copying registration codes
  };

  const columns = [
    {
      field: "_id",
      headerName: "ID",
      flex: 0.7,
      sortable: true,
    },
    {
      field: "organizaionInfo",
      headerName: "Sender",
      flex: 0.5,
      backgroundColor:'red' ,
      sortable: true,
      renderCell: (params) => (
        // console.log("renderCell params: ",params)
        <div style={{ whiteSpace: 'nowrap', overflow: 'scroll', textOverflow: 'ellipsis' }}>
          {params.value.orgName ? params.value.orgName : ''}
        </div>
      ),
    },

    {
      field: "toTypes",
      headerName: "Receiver",
      flex: 0.5,
    },
    {
      field: "redeemCost",
      headerName: "Points",
      flex: 0.5,
    },

    // {
    //   field: "status",
    //   headerName: "Status",
    //   valueOptions: ["Verified", "Pending"],
    //   flex: 0.5,
    //   renderCell: (params) => {
    //     return (
    //       <Chip variant="outlined" size="small" {...getChipProps(params)} />
    //     );
    //   },
    // },
    // {
    //   flex: 0.1,
    //   renderCell: (cellValues) => {
    //     return (
    //       <Tooltip title="copy code">
    //         <ContentCopyIcon
    //           onClick={(event) => {
    //             handleClick(event, cellValues);
    //           }}
    //         />
    //       </Tooltip>
    //     );
    //   },
    // },
  ];

  const columnsGolalita = [
    {
      field: "_id",
      headerName: "ID",
      flex: 0.7,
      sortable: true,
    },
    {
      field: "organizaionInfo",
      headerName: "Sender",
      flex: 0.5,
      backgroundColor:'red' ,
      sortable: true,
      renderCell: (params) => (
        // console.log("renderCell params: ",params)
        <div style={{ whiteSpace: 'nowrap', overflow: 'scroll', textOverflow: 'ellipsis' }}>
          {params.value.orgName ? params.value.orgName : ''}
        </div>
      ),
    },
    {
      field: "toTypes",
      headerName: "Receiver",
      flex: 0.5,
    },
    {
      field: "redeemCost",
      headerName: "Points",
      flex: 0.5,
    },
    // {
    //   flex: 0.1,
    //   renderCell: (cellValues) => {
    //     return (
    //       <Tooltip title="copy code">
    //         <ContentCopyIcon
    //           onClick={(event) => {
    //             handleClick(event, cellValues);
    //           }}
    //         />
    //       </Tooltip>
    //     );
    //   },
    // },
  ];
  const columnsMerchant = [
    {
      field: "_id",
      headerName: "ID",
      flex: 0.7,
      sortable: true,
    },
    {
      field: "MerchantInfo",
      headerName: "Sender",
      flex: 0.5,
      backgroundColor:'red' ,
      sortable: true,
      renderCell: (params) => (
        // console.log("renderCell params: ",params)
        <div style={{ whiteSpace: 'nowrap', overflow: 'scroll', textOverflow: 'ellipsis' }}>
          {params.value.merchName ? params.value.merchName : ''}
        </div>
      ),
    },

    {
      field: "toTypes",
      headerName: "Receiver",
      flex: 0.5,
    },
    {
      field: "redeemCost",
      headerName: "Points",
      flex: 0.5,
    },
    // {
    //   flex: 0.1,
    //   renderCell: (cellValues) => {
    //     return (
    //       <Tooltip title="copy code">
    //         <ContentCopyIcon
    //           onClick={(event) => {
    //             handleClick(event, cellValues);
    //           }}
    //         />
    //       </Tooltip>
    //     );
    //   },
    // },
  ];

  //   rows
  const rows = sentLoyalityPoints != [] && sentLoyalityPoints[0] && sentLoyalityPoints[0].Organisation;
  const rowsGolalita = sentLoyalityPoints != [] && sentLoyalityPoints[0] && sentLoyalityPoints[0].Golalita;
  const rowsMerchant = sentLoyalityPoints != [] && sentLoyalityPoints[0] && sentLoyalityPoints[0].Merchant;
  // [
  //   {
  //     id: 1,
  //     _id: "63e026fd24cddc4076c1721e",
  //     clientName: "Johnas Peter",
  //     mode: "Online",
  //     status: "Verified",
  //     date: "12 Jan 2023 16:00",
  //     total: "QR 1200",
  //   },
  //   {
  //     id: 2,
  //     _id: "33a026fd24cddc4076c1721h",
  //     clientName: "Smith Peter",
  //     mode: "Online",
  //     status: "Verified",
  //     date: "12 Feb 2023 16:00",
  //     total: "QR 1200",
  //   },
  //   {
  //     id: 3,
  //     _id: "57e026fd24cddc40786c1721e",
  //     clientName: "Fatima Khalid",
  //     mode: "Online",
  //     status: "Pending",
  //     date: "22 Mar 2023 16:00",
  //     total: "QR 1200",
  //   },
  //   {
  //     id: 4,
  //     _id: "57e026fd24cddc40786c1721e",
  //     clientName: "Thahsin Shaik",
  //     mode: "Online",
  //     status: "Verified",
  //     date: "11 Mar 2023 16:00",
  //     total: "QR 1300",
  //   }
  // ];

  return (
    <Box m="1.5rem 2.5rem">
      <FlexBetween gap="1.5rem">
        <Header title="Loyality Points" />
        <FlexBetween gap="1.5rem" alignItems="center">
          <Link to="/addLoyalityPoints" style={{ textDecoration: "none" }}>
            <Button
              variant="contained"
              // sx={{
              //   backgroundColor: theme.palette.secondary[400],
              //   color: theme.palette.primary[600],
              //   "&:hover": {
              //     backgroundColor: theme.palette.secondary[500],
              //     color: "#000000",
              //   },
              // }}
              endIcon={<AddIcon />}
            >
              Add Loyality Points
            </Button>
          </Link>
        </FlexBetween>
      </FlexBetween>
      <Filters onSearchHandler={onSearchHandler} />
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChangeTab}
            aria-label="basic tabs example"
          >
            <Tab label="Point sent" {...a11yProps(0)} />
            <Tab label="Point received" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": {
                gridColumn:  "span 4",
              },
            }}
          >
            <Box
              mt="30px"
              height="45vh"
              width="100%"
              // sx={{
              //   "& .MuiDataGrid-root": {
              //     border: "none",
              //   },
              //   "& .MuiDataGrid-cell": {
              //     borderBottom: "none",
              //   },
              //   "& .MuiDataGrid-columnHeaders": {
              //     backgroundColor: theme.palette.background.alt,
              //     color: theme.palette.secondary[100],
              //     borderBottom: "none",
              //   },
              //   "& .MuiDataGrid-virtualScroller": {
              //     backgroundColor: theme.palette.primary.light,
              //   },
              //   "& .MuiDataGrid-footerContainer": {
              //     backgroundColor: theme.palette.background.alt,
              //     color: theme.palette.secondary[100],
              //     borderTop: "none",
              //   },
              //   "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              //     color: `${theme.palette.secondary[200]} !important`,
              //   },

              //   "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              // }}
            >
 {rowsGolalita && rowsGolalita.length != 0 && (
              <>
              {/* <h4>Merchant</h4> */}
              <DataGrid
                  // loading={isLoading || !data}
                  getRowId={(row) => row._id}
                  rows={rowsGolalita}
                  columns={columnsGolalita}
                  slots={{ toolbar: GridToolbar }}
                />
                </>
              )}

              {rows && rows.length != 0 && (
              <>
              {/* <h4>Organisation</h4> */}
              <DataGrid
                  // loading={isLoading || !data}
                  getRowId={(row) => row._id}
                  rows={rows}
                  columns={columns}
                  slots={{ toolbar: GridToolbar }}
                />
                </>
              )}
               {rowsMerchant && rowsMerchant.length != 0 && (
              <>
              {/* <h4>Merchant</h4> */}
              <DataGrid
                  // loading={isLoading || !data}
                  getRowId={(row) => row._id}
                  rows={rowsMerchant}
                  columns={columnsMerchant}
                  slots={{ toolbar: GridToolbar }}
                />
                </>
              )}
            </Box>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": {
                gridColumn: isNonMobile ? undefined : "span 4",
              },
            }}
          >
            <Box
              mt="30px"
              height="45vh"
              width="100%"
              // sx={{
              //   "& .MuiDataGrid-root": {
              //     border: "none",
              //   },
              //   "& .MuiDataGrid-cell": {
              //     borderBottom: "none",
              //   },
              //   "& .MuiDataGrid-columnHeaders": {
              //     backgroundColor: theme.palette.background.alt,
              //     color: theme.palette.secondary[100],
              //     borderBottom: "none",
              //   },
              //   "& .MuiDataGrid-virtualScroller": {
              //     backgroundColor: theme.palette.primary.light,
              //   },
              //   "& .MuiDataGrid-footerContainer": {
              //     backgroundColor: theme.palette.background.alt,
              //     color: theme.palette.secondary[100],
              //     borderTop: "none",
              //   },
              //   "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              //     color: `${theme.palette.secondary[200]} !important`,
              //   },

              //   "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              // }}
            >
              {rows && ( //<h1>cheks 1</h1>
                  <DataGrid
                    // loading={isLoading || !data}
                    getRowId={(row) => row._id}
                    rows={rows && rows}
                    columns={columns}
                    slots={{ toolbar: GridToolbar }}
                  />
                )}
            </Box>
          </Box>
        </CustomTabPanel>
      </Box>
      {/* <FilterOrganization /> */}
      {/* <Box
        mt="30px"
        height="75vh"
        width="100%"
        // sx={{
        //   "& .MuiDataGrid-root": {
        //     border: "none",
        //   },
        //   "& .MuiDataGrid-cell": {
        //     borderBottom: "none",
        //   },
        //   "& .MuiDataGrid-columnHeaders": {
        //     backgroundColor: theme.palette.background.alt,
        //     color: theme.palette.secondary[100],
        //     borderBottom: "none",
        //   },
        //   "& .MuiDataGrid-virtualScroller": {
        //     backgroundColor: theme.palette.primary.light,
        //   },
        //   "& .MuiDataGrid-footerContainer": {
        //     backgroundColor: theme.palette.background.alt,
        //     color: theme.palette.secondary[100],
        //     borderTop: "none",
        //   },
        //   "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
        //     color: `${theme.palette.secondary[200]} !important`,
        //   },

        //   "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
        // }}
      >
        {rows &&
          <DataGrid
              // loading={isLoading || !data}
               getRowId={(row) => row._id}
              rows={rows}
              columns={columns}
              slots={{ toolbar: GridToolbar }}
            />
        }
      </Box> */}
    </Box>
  );
};

export default LoyalityPoints;
