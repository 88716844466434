import { alpha, styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";

const YellowSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: theme.palette.secondary[600],
    "&:hover": {
      backgroundColor: alpha(
        theme.palette.secondary[600],
        theme.palette.action.hoverOpacity
      ),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: theme.palette.secondary[600],
  },
}));

export default YellowSwitch;
