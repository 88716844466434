import React, { useState } from "react";
import Header from "../../components/Header";
import {
  Box,
  useTheme,
  useMediaQuery,
  Button,
  TextField,
  MenuItem,
  Typography,
  Autocomplete,
  FormGroup,
  FormControl,
  FormLabel,
  FormControlLabel,
  styled,
  Tab,
  Tabs
  // InputAdornment,
} from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import FlexBetween from "../../components/FlexBetween";
import InputAdornment from "@mui/material/InputAdornment";
import { Formik } from "formik";
import * as yup from "yup";
import YellowSwitch from "../../components/YellowSwitch";
import ImgPreview from "../../components/ImgPreview";
import { useDispatch, useSelector } from "react-redux";
import { setIsEditingOrg } from "../../features/organizations/editSlice";
import { getOrganizationById, saveNewOrganization,updateOrganization } from "../../features/organizations/organizationSlice";
import { useParams ,useNavigate, Link} from "react-router-dom";
import PropTypes from 'prop-types';

// import CountrySelect from "../../components/CountrySelect";

const OrganizationForm = () => {
  const theme = useTheme();
  const isNonMobile = useMediaQuery("(min-width: 1000px)");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isEditingOrg = useSelector(setIsEditingOrg);
  const params = useParams();
  const itemToEdit = useSelector(getOrganizationById(params.id));
  const [countryValue, setCountryValue] = useState(itemToEdit != undefined && itemToEdit.countryValue ?
     countries.find((el, i) => el.label == itemToEdit.countryValue)
   : null);
  const [open, setOpen] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState(itemToEdit?.orgBanner ?? []);
  const [selectedLogo, setSelectedLogo] = useState(itemToEdit?.orgLogo ??[]);
  const [orgPubEmail, setOrgPubEmail] = useState(itemToEdit?.orgPubEmail);
  const [orgPvtEmail, setOrgPvtEmail] = useState(itemToEdit?.orgPvtEmail);
  const [orgPubContact, setOrgPubContact] = useState(itemToEdit?.orgPubContact);
  const [orgPvtContact, setOrgPvtContact] = useState(itemToEdit?.orgPvtContact);
  const [switchState, setSwitchState] = useState({
    show: true,
    available: true,
    archieve: true,
    bookingAvailable: true,
    loyaltyPoints: false,
  });
  const [orgSocialMedia, setorgSocialMedia] = useState({
    orgFacebook: "",
    orgInstagram: "",
    orgOtherLinks: "",
  });
  const [value, setValue] = React.useState(0);
console.log("itemToEdit:",itemToEdit)

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  // console.log(switchState);
  // console.log(countryValue, "country value");
  const handleSwitchChange = (event) => {
    setSwitchState({
      ...switchState,
      [event.target.name]: event.target.checked,
    });
  };

  const handleSocialMedia = (event) => {
    setorgSocialMedia({
      ...orgSocialMedia,
      [event.target.name]: event.target.value,
    });
  };

  function deleteImageHandler(image) {
    setSelectedFiles(selectedFiles.filter((e) => e !== image));
    URL.revokeObjectURL(image);
  }

  function deleteLogoHandler(image) {
    setSelectedLogo(selectedLogo.filter((e) => e !== image));
    URL.revokeObjectURL(image);
  }

  function handleorgPubEmail(e) {
    console.log("handleorgPubEmail:",e)
    if ((e.charCode || e.keyCode) === 13) {
      e.preventDefault();
    }
    if (e.key !== "Enter") return;
    const value = e.target.value;
    if (!value.trim()) return;
    setOrgPubEmail([...orgPubEmail, value]);
    e.target.value = "";
    return orgPubEmail;
  }
  function removePubEmail(index) {
    setOrgPubEmail(orgPubEmail.filter((el, i) => i !== index));
    return orgPubEmail;
  }

  function handleorgPvtEmail(e) {
    if ((e.charCode || e.keyCode) === 13) {
      e.preventDefault();
    }
    if (e.key !== "Enter") return;
    const value = e.target.value;
    if (!value.trim()) return;
    setOrgPvtEmail([...orgPvtEmail, value]);
    e.target.value = "";
    return orgPvtEmail;
  }
  function removePvtEmail(index) {
    setOrgPvtEmail(orgPvtEmail.filter((el, i) => i !== index));
    return orgPvtEmail;
  }

  function handleorgPvtContact(e) {
    if ((e.charCode || e.keyCode) === 13) {
      e.preventDefault();
    }
    if (e.key !== "Enter") return;
    const value = e.target.value;
    if (!value.trim()) return;
    setOrgPvtContact([...orgPvtContact, value]);
    e.target.value = "";
    return orgPvtContact;
  }
  function removePvtContact(index) {
    setOrgPvtContact(orgPvtContact.filter((el, i) => i !== index));
    return orgPvtContact;
  }

  function handleorgPubContact(e) {
    if ((e.charCode || e.keyCode) === 13) {
      e.preventDefault();
    }
    if (e.key !== "Enter") return;
    const value = e.target.value;
    if (!value.trim()) return;
    setOrgPubContact([...orgPubContact, value]);
    e.target.value = "";
    return orgPubContact;
  }
  function removePubContact(index) {
    setOrgPubContact(orgPubContact.filter((el, i) => i !== index));
    return orgPubContact;
  }

  const createNewOrganization = (data) => {
    console.log("createNewOrganization data:",data)
    dispatch(saveNewOrganization(data))
      .unwrap()
      .then((res) => {
        console.log("createNewOrganization res:",res)
        if(res.success){
          navigate("/organizations");
        }
        // 
      });
  };

  const updateOrganizationHandler = (data) => {
    console.log("updateOrganizationHandler data:",data)
    dispatch(updateOrganization(data))
      .unwrap()
      .then((res) => {
        console.log("updateOrganizationHandler res:",res)
        if(res.success){
          navigate("/organizations");
        }
        // 
      });
  };

  function isHttpUrl(value) {
    const urlPattern = /^http:\/\//;
    return urlPattern.test(value);
  }

  const handleFormSubmit = (values,type) => {
    console.log("OrganizationForm Values11111:", values);
    console.log("OrganizationForm orgPubEmail:", orgPubEmail);
    console.log("OrganizationForm orgPvtEmail:", orgPvtEmail);

    values.countryValue = countryValue;
    values.available = switchState.available;
    values.show = switchState.show;
    values.bookingAvailable = switchState.bookingAvailable;
    values.loyaltyPoints = switchState.loyaltyPoints;
    values.archieve = switchState.archieve;
    values.orgSocialMedia = orgSocialMedia;

    var formData = new FormData();
    for (const key of Object.keys(values.orgBanner != null && values.orgBanner)) {
      formData.append("orgBanner",values.orgBanner != null && values.orgBanner[key]);
    }
    for (const key of Object.keys(values.orgLogo != null &&values.orgLogo)) {
      formData.append("orgLogo",values.orgLogo != null && values.orgLogo[key]);
    }
    orgPubEmail.map((pubEmail) => formData.append("orgPubEmail", pubEmail));
    orgPvtEmail.map((pvtEmail) => formData.append("orgPvtEmail", pvtEmail));
    orgPubContact.map((pubContact) =>
      formData.append("orgPubContact", pubContact)
    );
    orgPvtContact.map((pvtContact) =>
      formData.append("orgPvtContact", pvtContact)
    );
    formData.append("countryValue", values.countryValue);
    formData.append("orgName", values.orgName);
    formData.append("orgNameAr", values.orgNameAr);
    formData.append("orgCity", values.orgCity);
    formData.append("orgStreet", values.orgStreet);
    formData.append("orgState", values.orgState);
    formData.append("orgWebsite", values.orgWebsite);
    formData.append("orgGeoLat", values.orgGeoLat);
    formData.append("orgGeoLng", values.orgGeoLng);
    formData.append("maxDiscount", values.maxDiscount);
    formData.append("minDiscount", values.minDiscount);
    formData.append("orgWhatsappNum", values.orgWhatsappNum);
    formData.append("orgWhatsappTitle", values.orgWhatsappTitle);
    formData.append("orgWhatsappPrefilllMsg", values.orgWhatsappPrefilllMsg);
    formData.append("enterpriseRegistration", values.enterpriseRegistration);
    formData.append("registerCommercial", values.registerCommercial);
    formData.append("orgBannerVideo", values.orgBannerVideo);
    formData.append("orgContract", values.orgContract);
    formData.append("orgDescription", values.orgDescription);
    formData.append("orgDescriptionAr", values.orgDescriptionAr);
    formData.append("archieve", switchState.archieve);
    formData.append("show", switchState.show);
    formData.append("loyaltyPoints", switchState.loyaltyPoints);
    formData.append("available", switchState.available);
    formData.append("bookingAvailable", switchState.bookingAvailable);

    if (isHttpUrl(values.orgContract)) {
      console.log("It's an HTTP URL:");
      formData.append("orgContract", values.orgContract);
    } else {
      console.log("It's not an HTTP URL");
    }


    if(type == "update"){
      formData.append("organisationId", itemToEdit?._id);
      updateOrganizationHandler(formData);
    }
    else{
      createNewOrganization(formData);
    }
    console.log("vales from organiazartion form ddata", formData);
  };

  const phoneRegExp =
    /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

  const emailRegExp =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  // const latRegExp =
  //   /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$/;

  // const lngRegExp =
  //   /^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,6})?))$/;
  const validFileExtensions = {
    file: ["jpg", "gif", "png", "jpeg", "svg", "webp"],
  };

  const validPdfFileExtensions = {
    file: ["pdf"],
  };

  const validVideoFileExtensions = {
    file: [".WEBM", ".OGG", ".MP3", ".MP4"],
  };

  function isValidFileType(fileName, fileType) {
    return (
      fileName &&
      validFileExtensions[fileType].indexOf(fileName.split(".").pop()) > -1
    );
  }

  function isValidPdf(fileName, fileType) {
    return (
      fileName &&
      validPdfFileExtensions[fileType].indexOf(fileName.split(".").pop()) > -1
    );
  }

  function isValidVideo(fileName, fileType) {
    return (
      fileName &&
      validVideoFileExtensions[fileType].indexOf(fileName.split(".").pop()) > -1
    );
  }
  const addOrgnizationSchema = yup.object().shape({
    orgName: yup.string().required("required"),
    orgNameAr: yup.string().required("required"),

    countryValue: yup.mixed().required("country required"),
    // orgCategory: yup.string().required("required"),
    orgPubEmail1: yup
      .string()
      .matches(emailRegExp, "email is not valid")
      .email("invalid email")
      .required("required"),
    orgPubEmail2: yup
      .string()
      .matches(emailRegExp, "email is not valid")
      .email("invalid email"),
    orgPvtEmail1: yup
      .string()
      .matches(emailRegExp, "email is not valid")
      .email("invalid email")
      .required("required"),
    orgPvtEmail2: yup
      .string()
      .matches(emailRegExp, "email is not valid")
      .email("invalid email"),
    orgPubContact1: yup
      .string()
      .matches(phoneRegExp, "Phone number is not valid")
      .required("required"),
    orgPubContact2: yup
      .string()
      .matches(phoneRegExp, "Phone number is not valid"),
    orgPvtContact1: yup
      .string()
      .matches(phoneRegExp, "Phone number is not valid")
      .required("required"),
    orgPvtContact2: yup
      .string()
      .matches(phoneRegExp, "Phone number is not valid"),
    orgGeoLat: yup
      .string()
      // .matches(latRegExp, "Include decimals")
      .required("required"),
    orgGeoLng: yup
      .string()
      // .matches(lngRegExp, "Include decimals")
      .required("required"),
    // orgLogo: yup
    //   .mixed()
    //   .required()
    //   .test("is-valid-type", "Not a valid image type", (value) =>
    //     isValidFileType(value && value.name.toLowerCase(), "file")
    //   ),
    // orgBanner: yup
    //   .mixed()
    //   .required()
    //   .test("is-valid-type", "Not a valid image type", (value) =>
    //     isValidFileType(value && value.name.toLowerCase(), "file")
    //   ),

    // orgContract: yup
    //   .mixed()
    //   .required("Contract is Requried")
    //   .test("is-valid-type", "Not a valid file type", (value) =>
    //     isValidPdf(value && value.name.toLowerCase(), "file")
    //   ),
    // enterpriseRegistration: yup
    //   .mixed()
    //   .required("Requried")
    //   .test("is-valid-type", "Not a valid file type", (value) =>
    //     isValidPdf(value && value.name.toLowerCase(), "file")
    //   ),
    // registerCommercial: yup
    //   .mixed()
    //   .required("Requried")
    //   .test("is-valid-type", "Not a valid file type", (value) =>
    //     isValidPdf(value && value.name.toLowerCase(), "file")
    //   ),
    // orgBannerVideo: yup
    //   .mixed()
    //   .test("is-valid-type", "Not a valid video type", (value) =>
    //     isValidVideo(value && value.name.toLowerCase(), "file")
    //   ),
    orgWhatsappNum: yup
      .string()
      .matches(phoneRegExp, "WhatsApp number is not valid")
      .required("required"),

    orgWhatsappTitle: yup.string().required("required"),
    orgWhatsappPrefilllMsg: yup.string().required("required"),
    orgDescription: yup.string().required("required"),
    orgDescriptionAr: yup.string().required("required"),
    orgType: yup.string().required("choose Enterpise Type"),
    //orgFacebook: yup.string().required("required"),
    orgInstagram: yup.string().required("required"),
    orgCity: yup.string().required("required"),
    orgState: yup.string().required("required"),
  });
  const initialValues = {
    orgName: "",
    orgNameAr: "",
    countryValue: "",
    // orgCategory: "",
    orgPubEmail: "",
    orgPvtEmail: "",
    orgPubContact: "",
    orgPvtContact: "",
    orgGeoLat: "",
    orgGeoLng: "",
    orgStreet: "",
    orgCity: "",
    orgState: "",
    orgWhatsappNum: "",
    orgWhatsappTitle: "",
    orgWhatsappPrefilllMsg: "",
    orgDescription: "",
    orgDescriptionAr: "",
    minDiscount: 0,
    maxDiscount: 0,
    orgLogo: null,
    orgBanner: null,
    orgBannerVideo: "",
    orgContract: "",
    enterpriseRegistration: "",
    registerCommercial: "",
    // orgType: "",
    // orgFacebook: "",
    // orgInstagram: "",
    orgWebsite: "",
    orgDescriptionAr: "",
    orgDescription: "",
    // orgOtherLinks: "",
  };


  const savedValues = {
    orgName: itemToEdit?.orgName,
    orgNameAr: itemToEdit?.orgNameAr,
    orgPubEmail: itemToEdit?.orgPubEmail,
    orgPvtEmail: itemToEdit?.orgPvtEmail,
    orgPubContact: itemToEdit?.orgPubContact,
    orgStreet: itemToEdit?.orgStreet,
    orgGeoLat: itemToEdit?.orgGeoLat,
    orgGeoLng: itemToEdit?.orgGeoLng,
    orgCity: itemToEdit?.orgCity,
    orgState: itemToEdit?.orgState,
    orgWhatsappNum: itemToEdit?.orgWhatsappNum,
    orgWhatsappTitle: itemToEdit?.orgWhatsappTitle,
    orgWhatsappPrefilllMsg: itemToEdit?.orgWhatsappPrefilllMsg,
    minDiscount: itemToEdit?.minDiscount,
    maxDiscount: itemToEdit?.maxDiscount,
    // orgCategory: itemToEdit?.orgCategory,
    orgDescription: itemToEdit?.orgDescription,
    orgDescriptionAr: itemToEdit?.orgDescriptionAr,
    orgFacebook: itemToEdit?.orgFacebook,
    countryValue: itemToEdit?.countryValue,
    orgWebsite: itemToEdit?.orgWebsite,
    enterpriseRegistration: itemToEdit?.enterpriseRegistration,
    registerCommercial: itemToEdit?.registerCommercial,
    orgBannerVideo: itemToEdit?.orgBannerVideo,
    orgContract: itemToEdit?.orgContract,
    archieve: itemToEdit?.archieve,
    show: itemToEdit?.show,
    loyaltyPoints: itemToEdit?.loyaltyPoints,
    available: itemToEdit?.available,
    bookingAvailable: itemToEdit?.bookingAvailable,
    // available: itemToEdit?.available,
    // bookingAvailable: itemToEdit?.bookingAvailable,
    // loyaltyPoints: itemToEdit?.loyaltyPoints,
    delivery: itemToEdit?.delivery,
  };


  return (
    <Box m="1.5rem 2.5rem">
      <FlexBetween gap="1.5rem">
        <Header title="Join Us" subtitle="and increase your sales" />
      </FlexBetween>

     
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={
          isEditingOrg.payload.isEditingOrg.isEditing &&
          itemToEdit
            ? savedValues
            : 
            initialValues
        }
        validationSchema={addOrgnizationSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            {/* {console.log("organisation form values:",values)} */}
             <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
  <Tabs value={value} onChange={handleChangeTab} aria-label="basic tabs example">
    <Tab label="Info" {...a11yProps(0)} />
    <Tab label="Contacts" {...a11yProps(1)} />
    <Tab label="Documents" {...a11yProps(2)} />
    <Tab label="Settings" {...a11yProps(3)} />
  </Tabs>
</Box>
<CustomTabPanel value={value} index={0}>
<Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              {/* <TextField
                fullWidth
                variant="filled"
                value={values.orgType}
                select // tell TextField to render select
                label="Enterprise Type"
                onBlur={handleBlur}
                onChange={handleChange}
                name="orgType"
                defaultValue=""
                error={!!touched.orgType && !!errors.orgType}
                helperText={touched.orgType && errors.orgType}
                sx={{ gridColumn: "span 4" }}
              >
                <MenuItem key={1} value="Merchant">
                  organt
                </MenuItem>
                <MenuItem key={2} value="Branch">
                  Branch
                </MenuItem>
              </TextField> */}
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Organization Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.orgName}
                name="orgName"
                error={!!touched.orgName && !!errors.orgName}
                helperText={touched.orgName && errors.orgName}
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Organization Name (in arabic)"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.orgNameAr}
                name="orgNameAr"
                error={!!touched.orgNameAr && !!errors.orgNameAr}
                helperText={touched.orgNameAr && errors.orgNameAr}
                sx={{ gridColumn: "span 2" }}
              />

             
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Street"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.orgStreet}
                name="orgStreet"
                error={!!touched.orgStreet && !!errors.orgStreet}
                helperText={touched.orgStreet && errors.orgStreet}
                sx={{ gridColumn: "span 1" }}
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Geo Latititude"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.orgGeoLat}
                name="orgGeoLat"
                error={!!touched.orgGeoLat && !!errors.orgGeoLat}
                helperText={touched.orgGeoLat && errors.orgGeoLat}
                sx={{ gridColumn: "span 1" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Geo Longitude"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.orgGeoLng}
                name="orgGeoLng"
                error={!!touched.orgGeoLng && !!errors.orgGeoLng}
                helperText={touched.orgGeoLng && errors.orgGeoLng}
                sx={{ gridColumn: "span 1" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="City"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.orgCity}
                name="orgCity"
                error={!!touched.orgCity && !!errors.orgCity}
                helperText={touched.orgCity && errors.orgCity}
                sx={{ gridColumn: "span 1" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="State"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.orgState}
                name="orgState"
                error={!!touched.orgState && !!errors.orgState}
                helperText={touched.orgState && errors.orgState}
                sx={{ gridColumn: "span 2" }}
              />
              <Autocomplete
                id="country-select"
                value={countryValue}
                onChange={(event, newValue) => {
                  console.log("onChange country value:",newValue)
                  setCountryValue(newValue);
                }}
                sx={{ gridColumn: "span 2" }}
                options={countries}
                autoHighlight
                defaultValue={countryValue}
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                getOptionLabel={(option) => option.label}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    //   sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    display="grid"
                    gap="30px"
                    gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                    sx={{
                      "& > img": { mr: 2, flexShrink: 0 },
                      "& > div": {
                        gridColumn: isNonMobile ? undefined : "span 4",
                      },
                    }}
                    {...props}
                  >
                    <img
                      loading="lazy"
                      width="20"
                      src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                      srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                      alt=""
                    />
                    {option.label} ({option.code}) +{option.phone}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="filled"
                    label="Choose a country"
                    error={!!touched.countryValue && !!errors.countryValue}
                    helperText={touched.countryValue && errors.countryValue}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: values.countryValue ? (
                        <InputAdornment
                          position="start"
                          onClick={() => {
                            setOpen(true);
                          }}
                          style={{
                            marginTop: "0px",
                          }}
                        >
                          <img
                            loading="lazy"
                            width="20"
                            margin="-1rem"
                            src={countryValue && countryValue.code && `https://flagcdn.com/w20/${countryValue.code.toLowerCase()}.png`}
                            srcSet={countryValue && countryValue.code && `https://flagcdn.com/w40/${countryValue.code.toLowerCase()}.png 2x`}
                            alt=""
                          />
                        </InputAdornment>
                      ) : null,
                    }}
                  />
                )}
              />

              {/* <TextField
                fullWidth
                variant="filled"
                type="number"
                label="Enter the Minimum Discount"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.minDiscount}
                name="minDiscount"
                error={!!touched.minDiscount && !!errors.minDiscount}
                helperText="If you provide discount else keep this feild empty"
                sx={{ gridColumn: "span 2" }}
              /> */}
              {/* <TextField
                fullWidth
                variant="filled"
                type="number"
                label="Enter the Max Discount"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.maxDiscount}
                name="maxDiscount"
                error={!!touched.maxDiscount && !!errors.maxDiscount}
                helperText="If you provide discount else keep this feild empty"
                sx={{ gridColumn: "span 2" }}
              /> */}

             

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Describe your Oragnization"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.orgDescription}
                multiline
                rows={5}
                name="orgDescription"
                error={!!touched.orgDescription && !!errors.orgDescription}
                helperText={touched.orgDescription && errors.orgDescription}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Describe your Oragnization (arabic)"
                onBlur={handleBlur}
                onChange={handleChange}
                multiline
                rows={5}
                value={values.orgDescriptionAr}
                name="orgDescriptionAr"
                error={!!touched.orgDescriptionAr && !!errors.orgDescriptionAr}
                helperText={touched.orgDescriptionAr && errors.orgDescriptionAr}
                sx={{ gridColumn: "span 2" }}
              />
             
            </Box>
</CustomTabPanel>
<CustomTabPanel value={value} index={1}>

<Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >

<TextField
                fullWidth
                variant="filled"
                type="text"
                label="WhatsApp Number"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.orgWhatsappNum}
                name="orgWhatsappNum"
                error={!!touched.orgWhatsappNum && !!errors.orgWhatsappNum}
                helperText={touched.orgWhatsappNum && errors.orgWhatsappNum}
                sx={{ gridColumn: "span 1" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="WhatsApp Title"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.orgWhatsappTitle}
                name="orgWhatsappTitle"
                error={!!touched.orgWhatsappTitle && !!errors.orgWhatsappTitle}
                helperText={touched.orgWhatsappTitle && errors.orgWhatsappTitle}
                sx={{ gridColumn: "span 1" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="WhatsApp PreFill Message"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.orgWhatsappPrefilllMsg}
                name="orgWhatsappPrefilllMsg"
                error={
                  !!touched.orgWhatsappPrefilllMsg &&
                  !!errors.orgWhatsappPrefilllMsg
                }
                helperText={
                  touched.orgWhatsappPrefilllMsg &&
                  errors.orgWhatsappPrefilllMsg
                }
                sx={{ gridColumn: "span 2" }}
              />
               <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gridColumn: "span 4",
                }}
              >
                <TagsContainer>
                  {orgPubEmail && orgPubEmail.map((orgPubEmail, index) => (
                    <TagItem key={index}>
                      <TagText>{orgPubEmail}</TagText>
                      <TagClose
                        className="close"
                        onClick={() => removePubEmail(index)}
                      >
                        &times;
                      </TagClose>
                    </TagItem>
                  ))}
                  <TagInput
                    onKeyDown={handleorgPubEmail}
                    type="text"
                    className="tags-input"
                    placeholder="Enter Public Emails"
                    key="pubEmails"
                  />
                  {/* <button onClick={test()}> okaay </button> */}
                </TagsContainer>
                <TagsContainer>
                  {orgPvtEmail && orgPvtEmail.map((orgPvtEmail, index) => (
                    <TagItem key={index}>
                      <TagText>{orgPvtEmail}</TagText>
                      <TagClose
                        className="close"
                        onClick={() => removePvtEmail(index)}
                      >
                        &times;
                      </TagClose>
                    </TagItem>
                  ))}
                  <TagInput
                    onKeyDown={handleorgPvtEmail}
                    type="text"
                    className="tags-input"
                    placeholder="Enter Private Emails"
                    key="pvtEmails"
                  />
                  {/* <button onClick={test()}> okaay </button> */}
                </TagsContainer>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gridColumn: "span 4",
                }}
              >
                <TagsContainer>
                  {orgPvtContact && orgPvtContact.map((orgPvtContact, index) => (
                    <TagItem key={index}>
                      <TagText>{orgPvtContact}</TagText>
                      <TagClose
                        className="close"
                        onClick={() => removePvtContact(index)}
                      >
                        &times;
                      </TagClose>
                    </TagItem>
                  ))}
                  <TagInput
                    onKeyDown={handleorgPvtContact}
                    type="text"
                    className="tags-input"
                    placeholder="Contact Number (Sales & Marketing)"
                  />
                  {/* <button onClick={test()}> okaay </button> */}
                </TagsContainer>
                <TagsContainer>
                  {orgPubContact && orgPubContact.map((orgPubContact, index) => (
                    <TagItem key={index}>
                      <TagText>{orgPubContact.number}</TagText>
                      <TagClose
                        className="close"
                        onClick={() => removePubContact(index)}
                      >
                        &times;
                      </TagClose>
                    </TagItem>
                  ))}
                  <TagInput
                    onKeyDown={handleorgPubContact}
                    type="text"
                    className="tags-input"
                    placeholder="Contact Number (Customer Service)"
                  />
                  {/* <button onClick={test()}> okaay </button> */}
                </TagsContainer>
              </Box>

  
  <Box sx={{ gridColumn: "span 4" }}>
                <Typography variant="h5">Social Media Links</Typography>
              </Box>
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Instagram"
               // onBlur={handleBlur}
                onChange={(e) => {
                  // handleChange();
                  handleSocialMedia(e);
                }}
                value={values.orgInstagram}
                name="orgInstagram"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <InstagramIcon />
                    </InputAdornment>
                  ),
                }}
                error={!!touched.orgInstagram && !!errors.orgInstagram}
                helperText={touched.orgInstagram && errors.orgInstagram}
                sx={{ gridColumn: "span 1" }}
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Facebook"
              //  onBlur={handleBlur}
                onChange={(e) => {
                  // handleChange();
                  handleSocialMedia(e);
                }}
                value={values.orgFacebook}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FacebookIcon />
                    </InputAdornment>
                  ),
                }}
                name="orgFacebook"
                error={!!touched.orgFacebook && !!errors.orgFacebook}
                helperText={touched.orgFacebook && errors.orgFacebook}
                sx={{ gridColumn: "span 1" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Website"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.orgWebsite}
                name="orgWebsite"
                error={!!touched.orgWebsite && !!errors.orgWebsite}
                helperText={touched.orgWebsite && errors.orgWebsite}
                sx={{ gridColumn: "span 1" }}
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Other Social Media Links"
                onBlur={handleBlur}
                onChange={(e) => {
                  // handleChange();
                  handleSocialMedia(e);
                }}
                value={values.orgOtherLinks}
                name="orgOtherLinks"
                error={!!touched.orgOtherLinks && !!errors.orgOtherLinks}
                helperText={touched.orgOtherLinks && errors.orgOtherLinks}
                sx={{ gridColumn: "span 1" }}
              />
              
  </Box>
</CustomTabPanel>
<CustomTabPanel value={value} index={2}>

<Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
            <Box sx={{ gridColumn: "span 4" }}>
              <Typography variant="h5">Upload Brand Logos</Typography>
            </Box>
            <Box
              sx={{
                gridColumn: "span 1",
                borderBottom: "1px solid white",
                marginTop: "-20px",
                paddingBottom: "-10px",
              }}
            >
              {" "}
              <input
                type="file"
                multiple
                onChange={(event) => {
                  setFieldValue("orgLogo", event.currentTarget.files);
                  if (event.target.files) {
                    const filesArray = Array.from(event.target.files).map(
                      (file) => URL.createObjectURL(file)
                    );

                    setSelectedLogo((prevImages) =>
                      prevImages.concat(filesArray)
                    );
                    Array.from(event.target.files).map(
                      (file) => URL.revokeObjectURL(file) // avoid memory leak
                    );
                  }
                }}
              />
              {selectedLogo != [] && (
                <ImgPreview
                  source={selectedLogo}
                  deleteImageHandler={deleteLogoHandler}
                />
              )}
            </Box>

            <Box sx={{ gridColumn: "span 4" }}>
              <Typography variant="h5">Upload Brand Banners</Typography>
            </Box>
            <Box
              sx={{
                gridColumn: "span 1",
                borderBottom: "1px solid white",
                marginTop: "-20px",
                paddingBottom: "-10px",
              }}
            >
              {" "}
              <input
                type="file"
                multiple
                onChange={(event) => {
                  setFieldValue("orgBanner", event.currentTarget.files);
                  if (event.target.files) {
                    const filesArray = Array.from(event.target.files).map(
                      (file) => URL.createObjectURL(file)
                    );

                    setSelectedFiles((prevImages) =>
                      prevImages.concat(filesArray)
                    );
                    Array.from(event.target.files).map(
                      (file) => URL.revokeObjectURL(file) // avoid memory leak
                    );
                  }
                }}
              />
              {selectedFiles != [] && (
                <ImgPreview
                  source={selectedFiles}
                  deleteImageHandler={deleteImageHandler}
                />
              )}
            </Box>
            <Box sx={{ gridColumn: "span 4" }}>
              <Typography variant="h5">Upload Brand Banner Video</Typography>
            </Box>

            <Box sx={{ gridColumn: "span 4" }}>
            {itemToEdit?.orgBannerVideo[0] && values.orgBannerVideo && !values.orgBannerVideo.name &&  <Link  style={{color: "white",fontSize:"11px",width:'100%'}} target="_blank" to={itemToEdit?.orgBannerVideo[0]}>{itemToEdit?.orgBannerVideo[0]}</Link>}
            </Box>
            <TextField
              fullWidth
              variant="standard"
              type="file"
              onBlur={handleBlur}
              name="orgBannerVideo"
              // write a function for handle change when uplaoding a picture for the product with e.currentTarget.files[0] use state
              onChange={(event) => {
                setFieldValue("orgBannerVideo", event.currentTarget.files[0]);
              }}
              error={!!touched.orgBannerVideo && !!errors.orgBannerVideo}
              helperText={touched.orgBannerVideo && errors.orgBannerVideo}
              sx={{
                textAlign: "center",
                gridColumn: "span 4",
              }}
            />

            <Box sx={{ gridColumn: "span 4" }}>
              <Typography variant="h5">Upload Organization Docs</Typography>
            </Box>
            {/* <TextField
              fullWidth
              variant="standard"
              type="file"
              onBlur={handleBlur}
              name="enterpriseRegistration"
              onChange={(event) => {
                setFieldValue(
                  "enterpriseRegistration",
                  event.currentTarget.files[0]
                );
              }}
              error={
                !!touched.enterpriseRegistration &&
                !!errors.enterpriseRegistration
              }
              helperText="Upload Commercial Registration Contract (pdf)"
              sx={{
                textAlign: "center",
                gridColumn: "span 2",
              }}
            /> */}
            <Box sx={{ gridColumn: "span 4" }}>
            {itemToEdit?.orgContract && values.orgContract && !values.orgContract.name &&  <Link  style={{color: "white",fontSize:"11px",width:'100%'}} target="_blank" to={itemToEdit?.orgContract}>{itemToEdit?.orgContract}</Link>}
            </Box>
            <TextField
              fullWidth
              variant="standard"
              type="file"
              onBlur={handleBlur}
              name="orgContract"
              onChange={(event) => {
                setFieldValue("orgContract", event.currentTarget.files[0]);
              }}
              error={!!touched.orgContract && !!errors.orgContract}
              helperText="Upload Merchandise Contract (pdf)"
              sx={{
                textAlign: "center",
                gridColumn: "span 2",
                zIndex: 2,
              }}
            />
            {/* <TextField
              fullWidth
              variant="standard"
              type="file"
              onBlur={handleBlur}
              // write a function for handle change when uplaoding a picture for the product with e.currentTarget.files[0] use state
              onChange={(event) => {
                setFieldValue(
                  "registerCommercial",
                  event.currentTarget.files[0]
                );
              }}
              error={
                !!touched.registerCommercial && !!errors.registerCommercial
              }
              helperText="Upload Enterprise Licience (pdf)"
              sx={{
                textAlign: "center",
                gridColumn: "span 2",
              }}
            /> */}

  </Box>
</CustomTabPanel> 
<CustomTabPanel value={value} index={3}>

<Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
   <Box
                sx={{
                  textAlign: "center",
                  gridColumn: "span 4",
                }}
              >
                <FormControl component="fieldset" variant="standard">
                  <FormLabel
                    sx={{
                      color: theme.palette.secondary[500],
                    }}
                    component="legend"
                  >
                    Actions
                  </FormLabel>
                  <FormGroup
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      gap: "1.3rem",
                    }}
                  >
                    <FormControlLabel
                      control={
                        <YellowSwitch
                          checked={switchState.show}
                          onChange={handleSwitchChange}
                          name="show"
                        />
                      }
                      label="Show / Hide"
                    />
                    <FormControlLabel
                      control={
                        <YellowSwitch
                          checked={switchState.archieve}
                          onChange={handleSwitchChange}
                          name="archieve"
                        />
                      }
                      label="Archieve"
                    />
                    <FormControlLabel
                      control={
                        <YellowSwitch
                          checked={switchState.available}
                          onChange={handleSwitchChange}
                          name="available"
                        />
                      }
                      label="Available"
                    />
                    <FormControlLabel
                      control={
                        <YellowSwitch
                          checked={switchState.bookingAvailable}
                          onChange={handleSwitchChange}
                          name="bookingAvailable"
                        />
                      }
                      label="Booking Available"
                    />
                    <FormControlLabel
                      control={
                        <YellowSwitch
                          checked={switchState.loyaltyPoints}
                          onChange={handleSwitchChange}
                          name="loyaltyPoints"
                        />
                      }
                      label="Loyalty Points"
                    />
                  </FormGroup>
                </FormControl>
              </Box>
  </Box>
</CustomTabPanel>
</Box>
            
            <Box display="flex" justifyContent="end" mt="20px">
              
              {isEditingOrg.payload.isEditingOrg.isEditing &&
              itemToEdit ? (
                <Button
                type="submit"
                color="secondary"
                variant="contained"
                onClick={() => handleFormSubmit(values,"update")}
              >
                Update Organization
              </Button>
              ) : (
                <Button
                type="submit"
                color="secondary"
                variant="contained"
                onClick={() => handleFormSubmit(values)}
              >
                Add New Organization
              </Button>
              )}
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default OrganizationForm;

const countries = [
  { code: "AD", label: "Andorra" },
  {
    code: "AE",
    label: "United Arab Emirates",
    phone: "971",
  },
  { code: "AF", label: "Afghanistan", phone: "93" },
  {
    code: "AG",
    label: "Antigua and Barbuda",
    phone: "1-268",
  },
  { code: "AI", label: "Anguilla", phone: "1-264" },
  { code: "AL", label: "Albania", phone: "355" },
  { code: "AM", label: "Armenia", phone: "374" },
  { code: "AO", label: "Angola", phone: "244" },
  { code: "AQ", label: "Antarctica", phone: "672" },
  { code: "AR", label: "Argentina", phone: "54" },
  { code: "AS", label: "American Samoa", phone: "1-684" },
  { code: "AT", label: "Austria", phone: "43" },
  {
    code: "AU",
    label: "Australia",
    phone: "61",
    suggested: true,
  },
  { code: "AW", label: "Aruba", phone: "297" },
  { code: "AX", label: "Alland Islands", phone: "358" },
  { code: "AZ", label: "Azerbaijan", phone: "994" },
  {
    code: "BA",
    label: "Bosnia and Herzegovina",
    phone: "387",
  },
  { code: "BB", label: "Barbados", phone: "1-246" },
  { code: "BD", label: "Bangladesh", phone: "880" },
  { code: "BE", label: "Belgium", phone: "32" },
  { code: "BF", label: "Burkina Faso", phone: "226" },
  { code: "BG", label: "Bulgaria", phone: "359" },
  { code: "BH", label: "Bahrain", phone: "973" },
  { code: "BI", label: "Burundi", phone: "257" },
  { code: "BJ", label: "Benin", phone: "229" },
  { code: "BL", label: "Saint Barthelemy", phone: "590" },
  { code: "BM", label: "Bermuda", phone: "1-441" },
  { code: "BN", label: "Brunei Darussalam", phone: "673" },
  { code: "BO", label: "Bolivia", phone: "591" },
  { code: "BR", label: "Brazil", phone: "55" },
  { code: "BS", label: "Bahamas", phone: "1-242" },
  { code: "BT", label: "Bhutan", phone: "975" },
  { code: "BV", label: "Bouvet Island", phone: "47" },
  { code: "BW", label: "Botswana", phone: "267" },
  { code: "BY", label: "Belarus", phone: "375" },
  { code: "BZ", label: "Belize", phone: "501" },
  {
    code: "CA",
    label: "Canada",
    phone: "1",
    suggested: true,
  },
  {
    code: "CC",
    label: "Cocos (Keeling) Islands",
    phone: "61",
  },
  {
    code: "CD",
    label: "Congo, Democratic Republic of the",
    phone: "243",
  },
  {
    code: "CF",
    label: "Central African Republic",
    phone: "236",
  },
  {
    code: "CG",
    label: "Congo, Republic of the",
    phone: "242",
  },
  { code: "CH", label: "Switzerland", phone: "41" },
  { code: "CI", label: "Cote d'Ivoire", phone: "225" },
  { code: "CK", label: "Cook Islands", phone: "682" },
  { code: "CL", label: "Chile", phone: "56" },
  { code: "CM", label: "Cameroon", phone: "237" },
  { code: "CN", label: "China", phone: "86" },
  { code: "CO", label: "Colombia", phone: "57" },
  { code: "CR", label: "Costa Rica", phone: "506" },
  { code: "CU", label: "Cuba", phone: "53" },
  { code: "CV", label: "Cape Verde", phone: "238" },
  { code: "CW", label: "Curacao", phone: "599" },
  { code: "CX", label: "Christmas Island", phone: "61" },
  { code: "CY", label: "Cyprus", phone: "357" },
  { code: "CZ", label: "Czech Republic", phone: "420" },
  {
    code: "DE",
    label: "Germany",
    phone: "49",
    suggested: true,
  },
  { code: "DJ", label: "Djibouti", phone: "253" },
  { code: "DK", label: "Denmark", phone: "45" },
  { code: "DM", label: "Dominica", phone: "1-767" },
  {
    code: "DO",
    label: "Dominican Republic",
    phone: "1-809",
  },
  { code: "DZ", label: "Algeria", phone: "213" },
  { code: "EC", label: "Ecuador", phone: "593" },
  { code: "EE", label: "Estonia", phone: "372" },
  { code: "EG", label: "Egypt", phone: "20" },
  { code: "EH", label: "Western Sahara", phone: "212" },
  { code: "ER", label: "Eritrea", phone: "291" },
  { code: "ES", label: "Spain", phone: "34" },
  { code: "ET", label: "Ethiopia", phone: "251" },
  { code: "FI", label: "Finland", phone: "358" },
  { code: "FJ", label: "Fiji", phone: "679" },
  {
    code: "FK",
    label: "Falkland Islands (Malvinas)",
    phone: "500",
  },
  {
    code: "FM",
    label: "Micronesia, Federated States of",
    phone: "691",
  },
  { code: "FO", label: "Faroe Islands", phone: "298" },
  {
    code: "FR",
    label: "France",
    phone: "33",
    suggested: true,
  },
  { code: "GA", label: "Gabon", phone: "241" },
  { code: "GB", label: "United Kingdom", phone: "44" },
  { code: "GD", label: "Grenada", phone: "1-473" },
  { code: "GE", label: "Georgia", phone: "995" },
  { code: "GF", label: "French Guiana", phone: "594" },
  { code: "GG", label: "Guernsey", phone: "44" },
  { code: "GH", label: "Ghana", phone: "233" },
  { code: "GI", label: "Gibraltar", phone: "350" },
  { code: "GL", label: "Greenland", phone: "299" },
  { code: "GM", label: "Gambia", phone: "220" },
  { code: "GN", label: "Guinea", phone: "224" },
  { code: "GP", label: "Guadeloupe", phone: "590" },
  { code: "GQ", label: "Equatorial Guinea", phone: "240" },
  { code: "GR", label: "Greece", phone: "30" },
  {
    code: "GS",
    label: "South Georgia and the South Sandwich Islands",
    phone: "500",
  },
  { code: "GT", label: "Guatemala", phone: "502" },
  { code: "GU", label: "Guam", phone: "1-671" },
  { code: "GW", label: "Guinea-Bissau", phone: "245" },
  { code: "GY", label: "Guyana", phone: "592" },
  { code: "HK", label: "Hong Kong", phone: "852" },
  {
    code: "HM",
    label: "Heard Island and McDonald Islands",
    phone: "672",
  },
  { code: "HN", label: "Honduras", phone: "504" },
  { code: "HR", label: "Croatia", phone: "385" },
  { code: "HT", label: "Haiti", phone: "509" },
  { code: "HU", label: "Hungary", phone: "36" },
  { code: "ID", label: "Indonesia", phone: "62" },
  { code: "IE", label: "Ireland", phone: "353" },
  { code: "IL", label: "Israel", phone: "972" },
  { code: "IM", label: "Isle of Man", phone: "44" },
  { code: "IN", label: "India", phone: "91" },
  {
    code: "IO",
    label: "British Indian Ocean Territory",
    phone: "246",
  },
  { code: "IQ", label: "Iraq", phone: "964" },
  {
    code: "IR",
    label: "Iran, Islamic Republic of",
    phone: "98",
  },
  { code: "IS", label: "Iceland", phone: "354" },
  { code: "IT", label: "Italy", phone: "39" },
  { code: "JE", label: "Jersey", phone: "44" },
  { code: "JM", label: "Jamaica", phone: "1-876" },
  { code: "JO", label: "Jordan", phone: "962" },
  {
    code: "JP",
    label: "Japan",
    phone: "81",
    suggested: true,
  },
  { code: "KE", label: "Kenya", phone: "254" },
  { code: "KG", label: "Kyrgyzstan", phone: "996" },
  { code: "KH", label: "Cambodia", phone: "855" },
  { code: "KI", label: "Kiribati", phone: "686" },
  { code: "KM", label: "Comoros", phone: "269" },
  {
    code: "KN",
    label: "Saint Kitts and Nevis",
    phone: "1-869",
  },
  {
    code: "KP",
    label: "Korea, Democratic People's Republic of",
    phone: "850",
  },
  { code: "KR", label: "Korea, Republic of", phone: "82" },
  { code: "KW", label: "Kuwait", phone: "965" },
  { code: "KY", label: "Cayman Islands", phone: "1-345" },
  { code: "KZ", label: "Kazakhstan", phone: "7" },
  {
    code: "LA",
    label: "Lao People's Democratic Republic",
    phone: "856",
  },
  { code: "LB", label: "Lebanon", phone: "961" },
  { code: "LC", label: "Saint Lucia", phone: "1-758" },
  { code: "LI", label: "Liechtenstein", phone: "423" },
  { code: "LK", label: "Sri Lanka", phone: "94" },
  { code: "LR", label: "Liberia", phone: "231" },
  { code: "LS", label: "Lesotho", phone: "266" },
  { code: "LT", label: "Lithuania", phone: "370" },
  { code: "LU", label: "Luxembourg", phone: "352" },
  { code: "LV", label: "Latvia", phone: "371" },
  { code: "LY", label: "Libya", phone: "218" },
  { code: "MA", label: "Morocco", phone: "212" },
  { code: "MC", label: "Monaco", phone: "377" },
  {
    code: "MD",
    label: "Moldova, Republic of",
    phone: "373",
  },
  { code: "ME", label: "Montenegro", phone: "382" },
  {
    code: "MF",
    label: "Saint Martin (French part)",
    phone: "590",
  },
  { code: "MG", label: "Madagascar", phone: "261" },
  { code: "MH", label: "Marshall Islands", phone: "692" },
  {
    code: "MK",
    label: "Macedonia, the Former Yugoslav Republic of",
    phone: "389",
  },
  { code: "ML", label: "Mali", phone: "223" },
  { code: "MM", label: "Myanmar", phone: "95" },
  { code: "MN", label: "Mongolia", phone: "976" },
  { code: "MO", label: "Macao", phone: "853" },
  {
    code: "MP",
    label: "Northern Mariana Islands",
    phone: "1-670",
  },
  { code: "MQ", label: "Martinique", phone: "596" },
  { code: "MR", label: "Mauritania", phone: "222" },
  { code: "MS", label: "Montserrat", phone: "1-664" },
  { code: "MT", label: "Malta", phone: "356" },
  { code: "MU", label: "Mauritius", phone: "230" },
  { code: "MV", label: "Maldives", phone: "960" },
  { code: "MW", label: "Malawi", phone: "265" },
  { code: "MX", label: "Mexico", phone: "52" },
  { code: "MY", label: "Malaysia", phone: "60" },
  { code: "MZ", label: "Mozambique", phone: "258" },
  { code: "NA", label: "Namibia", phone: "264" },
  { code: "NC", label: "New Caledonia", phone: "687" },
  { code: "NE", label: "Niger", phone: "227" },
  { code: "NF", label: "Norfolk Island", phone: "672" },
  { code: "NG", label: "Nigeria", phone: "234" },
  { code: "NI", label: "Nicaragua", phone: "505" },
  { code: "NL", label: "Netherlands", phone: "31" },
  { code: "NO", label: "Norway", phone: "47" },
  { code: "NP", label: "Nepal", phone: "977" },
  { code: "NR", label: "Nauru", phone: "674" },
  { code: "NU", label: "Niue", phone: "683" },
  { code: "NZ", label: "New Zealand", phone: "64" },
  { code: "OM", label: "Oman", phone: "968" },
  { code: "PA", label: "Panama", phone: "507" },
  { code: "PE", label: "Peru", phone: "51" },
  { code: "PF", label: "French Polynesia", phone: "689" },
  { code: "PG", label: "Papua New Guinea", phone: "675" },
  { code: "PH", label: "Philippines", phone: "63" },
  { code: "PK", label: "Pakistan", phone: "92" },
  { code: "PL", label: "Poland", phone: "48" },
  {
    code: "PM",
    label: "Saint Pierre and Miquelon",
    phone: "508",
  },
  { code: "PN", label: "Pitcairn", phone: "870" },
  { code: "PR", label: "Puerto Rico", phone: "1" },
  {
    code: "PS",
    label: "Palestine, State of",
    phone: "970",
  },
  { code: "PT", label: "Portugal", phone: "351" },
  { code: "PW", label: "Palau", phone: "680" },
  { code: "PY", label: "Paraguay", phone: "595" },
  { code: "QA", label: "Qatar", phone: "974" },
  { code: "RE", label: "Reunion", phone: "262" },
  { code: "RO", label: "Romania", phone: "40" },
  { code: "RS", label: "Serbia", phone: "381" },
  { code: "RU", label: "Russian Federation", phone: "7" },
  { code: "RW", label: "Rwanda", phone: "250" },
  { code: "SA", label: "Saudi Arabia", phone: "966" },
  { code: "SB", label: "Solomon Islands", phone: "677" },
  { code: "SC", label: "Seychelles", phone: "248" },
  { code: "SD", label: "Sudan", phone: "249" },
  { code: "SE", label: "Sweden", phone: "46" },
  { code: "SG", label: "Singapore", phone: "65" },
  { code: "SH", label: "Saint Helena", phone: "290" },
  { code: "SI", label: "Slovenia", phone: "386" },
  {
    code: "SJ",
    label: "Svalbard and Jan Mayen",
    phone: "47",
  },
  { code: "SK", label: "Slovakia", phone: "421" },
  { code: "SL", label: "Sierra Leone", phone: "232" },
  { code: "SM", label: "San Marino", phone: "378" },
  { code: "SN", label: "Senegal", phone: "221" },
  { code: "SO", label: "Somalia", phone: "252" },
  { code: "SR", label: "Suriname", phone: "597" },
  { code: "SS", label: "South Sudan", phone: "211" },
  {
    code: "ST",
    label: "Sao Tome and Principe",
    phone: "239",
  },
  { code: "SV", label: "El Salvador", phone: "503" },
  {
    code: "SX",
    label: "Sint Maarten (Dutch part)",
    phone: "1-721",
  },
  {
    code: "SY",
    label: "Syrian Arab Republic",
    phone: "963",
  },
  { code: "SZ", label: "Swaziland", phone: "268" },
  {
    code: "TC",
    label: "Turks and Caicos Islands",
    phone: "1-649",
  },
  { code: "TD", label: "Chad", phone: "235" },
  {
    code: "TF",
    label: "French Southern Territories",
    phone: "262",
  },
  { code: "TG", label: "Togo", phone: "228" },
  { code: "TH", label: "Thailand", phone: "66" },
  { code: "TJ", label: "Tajikistan", phone: "992" },
  { code: "TK", label: "Tokelau", phone: "690" },
  { code: "TL", label: "Timor-Leste", phone: "670" },
  { code: "TM", label: "Turkmenistan", phone: "993" },
  { code: "TN", label: "Tunisia", phone: "216" },
  { code: "TO", label: "Tonga", phone: "676" },
  { code: "TR", label: "Turkey", phone: "90" },
  {
    code: "TT",
    label: "Trinidad and Tobago",
    phone: "1-868",
  },
  { code: "TV", label: "Tuvalu", phone: "688" },
  {
    code: "TW",
    label: "Taiwan, Province of China",
    phone: "886",
  },
  {
    code: "TZ",
    label: "United Republic of Tanzania",
    phone: "255",
  },
  { code: "UA", label: "Ukraine", phone: "380" },
  { code: "UG", label: "Uganda", phone: "256" },
  {
    code: "US",
    label: "United States",
    phone: "1",
    suggested: true,
  },
  { code: "UY", label: "Uruguay", phone: "598" },
  { code: "UZ", label: "Uzbekistan", phone: "998" },
  {
    code: "VA",
    label: "Holy See (Vatican City State)",
    phone: "379",
  },
  {
    code: "VC",
    label: "Saint Vincent and the Grenadines",
    phone: "1-784",
  },
  { code: "VE", label: "Venezuela", phone: "58" },
  {
    code: "VG",
    label: "British Virgin Islands",
    phone: "1-284",
  },
  {
    code: "VI",
    label: "US Virgin Islands",
    phone: "1-340",
  },
  { code: "VN", label: "Vietnam", phone: "84" },
  { code: "VU", label: "Vanuatu", phone: "678" },
  { code: "WF", label: "Wallis and Futuna", phone: "681" },
  { code: "WS", label: "Samoa", phone: "685" },
  { code: "XK", label: "Kosovo", phone: "383" },
  { code: "YE", label: "Yemen", phone: "967" },
  { code: "YT", label: "Mayotte", phone: "262" },
  { code: "ZA", label: "South Africa", phone: "27" },
  { code: "ZM", label: "Zambia", phone: "260" },
  { code: "ZW", label: "Zimbabwe", phone: "263" },
];

const TagsContainer = styled("div")({
  padding: ".7em",
  marginRight: "1rem",
  borderRadius: "3px",
  width: "min(80vw, 600px)",
  // marginTop: "0.2em",
  display: "flex",
  alignItems: "center",
  flexWrap: "wrap",
  gap: ".5em",
  backgroundColor: "#37393B",
  borderBottom: "1px solid white",
});

const TagItem = styled("div")({
  backgroundColor: "rgb(218, 216, 216)",
  display: "inline-block",
  padding: ".5em .75em",
  borderRadius: "20px",
  color: "black",
});

const TagClose = styled("span")({
  height: "20px",
  width: "20px",
  backgroundColor: "rgb(48, 48, 48)",
  color: "#fff",
  borderRadius: "50%",
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center",
  marginLeft: ".5em",
  fontSize: "18px",
  cursor: "pointer",
});

const TagText = styled("span")({
  textColor: "white",
});

const TagInput = styled("input")({
  flexGrow: "1",
  padding: ".5em 0",
  border: "none",
  outline: "none",
  backgroundColor: "#37393B",
  color: "rgba(255, 255, 255, 0.9)",
});
