import React, { useState, useEffect ,useMemo} from "react";
import Header from "../../components/Header";
import FlexBetween from "../../components/FlexBetween";
import {
  Box,
  useTheme,
  InputBase,
  IconButton,
  Button,
  ChipProps,
  Chip,
  useMediaQuery,
  Tooltip,
  Typography,
  Tab,
  Tabs
} from "@mui/material";
import { Search } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import DoneIcon from "@mui/icons-material/Done";
import WarningIcon from "@mui/icons-material/Warning";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import { DataGrid, GridRenderCellParams, GridToolbar } from "@mui/x-data-grid";
import { Link, useNavigate } from "react-router-dom";
import AddUser from "./UserForm";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAppfeedbacks,
  getAppfeedbacks
} from "../../features/feedbacks/feedbackSlice";

const Feedbacks = () => {
  const theme = useTheme();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const [userArchieve, setUserArchieve] = useState(false);

  const [value, setValue] = React.useState(0);

  const appFeedbacksorg = useSelector(getAppfeedbacks());

  const appFeedbacks = [{
    commentair: {
      date: "2023-10-25T09:24:50.384Z",
      text: "test APP first commentaire1",
      _id: "6538dee67c336559703f3abc",
    },
    name:'badusha',
    email:"badusha2024@gmail.com",
    offerId: "10001",
    phone:"+97430444432",
    commentText:"Very simplified flow",
    rate: 4,
    type: "APP",
    userId: "64ba6309809d1b90047a04b2",
    users: [],
    __v: 0,
    _id: "6538dee67c336559703f3abb",
    organisations:'MOI'
  },
  {
    commentair: {
      date: "2023-10-25T09:24:50.384Z",
      text: "test APP first commentaire1",
      _id: "6538dee67c336559703f3abc",
    },
    name:'imad',
    email:"sydimad@outlook.com",
    offerId: "10002",
    phone:"+97430444432",
    commentText:"test APP first commentaire1",
    rate: 3,
    type: "Offer",
    merchants:'Al Terrace',
    userId: "64ba6309809d1b90047a04b2",
    users: [],
    __v: 0,
    _id: "6538dee67c336559703f3abc",
    organisations:'SJC'
  },
  {
    commentair: {
      date: "2023-10-25T09:24:50.384Z",
      text: "test APP first commentaire1",
      _id: "6538dee67c336559703f3abc",
    },
    name:'zain',
    email:"imzain@yahoo.com",
    offerId: "10003",
    phone:"+97443897865",
    commentText:"Easy to use",
    rate: 4,
    type: "Offer",
    merchants:'Al Shurfa',
    userId: "64ba6309809d1b90047a04b2",
    users: [],
    __v: 0,
    _id: "6538dee67c336559703f3abd",
    organisations:'Masrf Al Rayyan'
  },
  {
    commentair: {
      date: "2023-10-25T09:24:50.384Z",
      text: "test APP first commentaire1",
      _id: "6538dee67c336559703f3abc",
    },
    name:'mehr',
    email:"meraproper@yahoo.com",
    offerId: "10004",
    phone:"+97443897865",
    commentText:"Easy to use",
    rate: 3,
    type: "Offer",
    merchants:'Rico superfood',
    userId: "64ba6309809d1b90047a04b2",
    users: [],
    __v: 0,
    _id: "6538dee67c336559703f3abe",
    organisations:'MOI'
  },
  {
    commentair: {
      date: "2023-10-25T09:24:50.384Z",
      text: "test APP first commentaire1",
      _id: "6538dee67c336559703f3abc",
    },
    name:'Philip',
    email:"philipfederik@gmail.com",
    offerId: "10005",
    phone:"+97443897865",
    commentText:"Nice offer",
    rate: 5,
    type: "Offer",
    merchants:'Torino Steak house',
    userId: "64ba6309809d1b90047a04b2",
    users: [],
    __v: 0,
    _id: "6538dee67c336559703f3abf",
    organisations:'Gulf Exchange'
  },
  {
    commentair: {
      date: "2023-10-25T09:24:50.384Z",
      text: "test APP first commentaire1",
      _id: "6538dee67c336559703f3abc",
    },
    name:'Abood',
    email:"Mohdabood@gmail.com",
    offerId: "10006",
    phone:"+97443897865",
    commentText:"my favourite offer",
    rate: 3,
    type: "Offer",
    merchants:'La Piazza',
    userId: "64ba6309809d1b90047a04b2",
    users: [],
    __v: 0,
    _id: "6538dee67c336559703f3abg",
    organisations:'Masrf Al Rayyan'
  },
  {
    commentair: {
      date: "2023-10-25T09:24:50.384Z",
      text: "test APP first commentaire1",
      _id: "6538dee67c336559703f3abc",
    },
    name:'Apmuhammed',
    email:"Muhammed@gmail.com",
    offerId: "10008",
    phone:"+97443897865",
    commentText:"NIce",
    rate: 4,
    type: "Offer",
    merchants:'Argan Restaurant',
    userId: "64ba6309809d1b90047a04b2",
    users: [],
    __v: 0,
    _id: "6538dee67c336559703f3abh",
    organisations:'Mazaya'
  },
  {
    commentair: {
      date: "2023-10-25T09:24:50.384Z",
      text: "test APP first commentaire1",
      _id: "6538dee67c336559703f3abc",
    },
    name:'Zakaria',
    email:"Zak006@gmail.com",
    offerId: "10009",
    phone:"+97443897865",
    commentText:"Recomende to everyone",
    rate: 3,
    type: "Offer",
    merchants:'The Local Grill',
    userId: "64ba6309809d1b90047a04b2",
    users: [],
    __v: 0,
    _id: "6538dee67c336559703f3abi",
    organisations:'Dukhan Bank'
  },
  {
    commentair: {
      date: "2023-10-25T09:24:50.384Z",
      text: "test APP first commentaire1",
      _id: "6538dee67c336559703f3abc",
    },
    name:'roshan',
    email:"roshanadvic@gmail.com",
    offerId: "100010",
    phone:"+97443897865",
    commentText:"Must try",
    rate: 3,
    type: "Merchant",
    merchants:'Patio Cafe',
    userId: "64ba6309809d1b90047a04b2",
    users: [],
    __v: 0,
    _id: "6538dee67c336559703f3abj",
    organisations:'Dukhan Bank'
  },
  {
    commentair: {
      date: "2023-10-25T09:24:50.384Z",
      text: "test APP first commentaire1",
      _id: "6538dee67c336559703f3abc",
    },
    name:'Inzamam',
    email:"inzrules@gmail.com",
    offerId: "100011",
    phone:"+97443897865",
    commentText:"Easily understandable",
    rate: 5,
    type: "APP",
    userId: "64ba6309809d1b90047a04b2",
    users: [],
    __v: 0,
    _id: "6538dee67c336559703f3abk",
    organisations:'Daam'
  },
  {
    commentair: {
      date: "2023-10-25T09:24:50.384Z",
      text: "test APP first commentaire1",
      _id: "6538dee67c336559703f3abc",
    },
    name:'Philip',
    email:"philipfederik@gmail.com",
    offerId: "100012",
    phone:"+97443897865",
    commentText:"Awesome app",
    rate: 3,
    type: "APP",
    userId: "64ba6309809d1b90047a04b2",
    users: [],
    __v: 0,
    _id: "6538dee67c336559703f3abl",
    organisations:'Masrf Al Rayyan'
  },
  {
    commentair: {
      date: "2023-10-25T09:24:50.384Z",
      text: "test APP first commentaire1",
      _id: "6538dee67c336559703f3abc",
    },
    name:'John',
    email:"johnkp@gmail.com",
    offerId: "100013",
    phone:"+97443897865",
    commentText:"Im enjoying it",
    rate: 3,
    type: "Merchant",
    merchants:'Gymkhana -katra Hills',
    userId: "64ba6309809d1b90047a04b2",
    users: [],
    __v: 0,
    _id: "6538dee67c336559703f3abm",
    organisations:'Mazaya'
  },
  {
    commentair: {
      date: "2023-10-25T09:24:50.384Z",
      text: "test APP first commentaire1",
      _id: "6538dee67c336559703f3abc",
    },
    name:'desoosa',
    email:"Jdesoosa@gmail.com",
    offerId: "100014",
    phone:"+97443897865",
    commentText:"Easily understandable",
    rate: 5,
    type: "Merchant",
    merchants:'L2 restaurant',
    userId: "64ba6309809d1b90047a04b2",
    users: [],
    __v: 0,
    _id: "6538dee67c336559703f3abn",
    organisations:'Masrf Al Rayyan'
  },
  {
    commentair: {
      date: "2023-10-25T09:24:50.384Z",
      text: "test APP first commentaire1",
      _id: "6538dee67c336559703f3abc",
    },
    name:'zain',
    email:"imzain@yahoo.com",
    offerId: "100015",
    phone:"+97443897865",
    commentText:"Easy to use",
    rate: 4,
    type: "APP",
    userId: "64ba6309809d1b90047a04b2",
    users: [],
    __v: 0,
    _id: "6538dee67c336559703f3abo",
    organisations:'Masrf Al Rayyan'
  },
  {
    commentair: {
      date: "2023-10-25T09:24:50.384Z",
      text: "test APP first commentaire1",
      _id: "6538dee67c336559703f3abc",
    },
    name:'mehr',
    email:"meraproper@yahoo.com",
    offerId: "100016",
    phone:"+97443897865",
    commentText:"Easy to use",
    rate: 3,
    type: "APP",
    userId: "64ba6309809d1b90047a04b2",
    users: [],
    __v: 0,
    _id: "6538dee67c336559703f3abp",
    organisations:'MOI'
  },
  {
    commentair: {
      date: "2023-10-25T09:24:50.384Z",
      text: "test APP first commentaire1",
      _id: "6538dee67c336559703f3abc",
    },
    name:'Philip',
    email:"philipfederik@gmail.com",
    offerId: "100017",
    phone:"+97443897865",
    commentText:"Nice offer",
    rate: 4,
    type: "APP",
    userId: "64ba6309809d1b90047a04b2",
    users: [],
    __v: 0,
    _id: "6538dee67c336559703f3abq",
    organisations:'Gulf Exchange'
  },
  {
    commentair: {
      date: "2023-10-25T09:24:50.384Z",
      text: "test APP first commentaire1",
      _id: "6538dee67c336559703f3abc",
    },
    name:'Abood',
    email:"Mohdabood@gmail.com",
    offerId: "100018",
    phone:"+97443897865",
    commentText:"my favourite application",
    rate: 5,
    type: "APP",
    userId: "64ba6309809d1b90047a04b2",
    users: [],
    __v: 0,
    _id: "6538dee67c336559703f3abr",
    organisations:'Masrf Al Rayyan'
  },




  {
    commentair: {
      date: "2023-10-25T09:24:50.384Z",
      text: "test APP first commentaire1",
      _id: "6538dee67c336559703f3abc",
    },
    name:'imad',
    email:"sydimad@outlook.com",
    offerId: "10002",
    phone:"+97430444432",
    commentText:"test APP first commentaire1",
    rate: 3,
    type: "Product",
    merchants:'Nidaaya - crown plaza',
    userId: "64ba6309809d1b90047a04b2",
    users: [],
    __v: 0,
    _id: "6538dee67c336559703f3abs",
    organisations:'SJC'
  },
  {
    commentair: {
      date: "2023-10-25T09:24:50.384Z",
      text: "test APP first commentaire1",
      _id: "6538dee67c336559703f3abc",
    },
    name:'zain',
    email:"imzain@yahoo.com",
    offerId: "10003",
    phone:"+97443897865",
    commentText:"Easy to use",
    rate: 4,
    type: "Product",
    merchants:'Al Jaber opticals',
    userId: "64ba6309809d1b90047a04b2",
    users: [],
    __v: 0,
    _id: "6538dee67c336559703f3abt",
    organisations:'Masrf Al Rayyan'
  },
  {
    commentair: {
      date: "2023-10-25T09:24:50.384Z",
      text: "test APP first commentaire1",
      _id: "6538dee67c336559703f3abc",
    },
    name:'mehr',
    email:"meraproper@yahoo.com",
    offerId: "10004",
    phone:"+97443897865",
    commentText:"Easy to use",
    rate: 3,
    type: "Product",
    merchants:'Eves Saint Laurent - Faces',
    userId: "64ba6309809d1b90047a04b2",
    users: [],
    __v: 0,
    _id: "6538dee67c336559703f3abu",
    organisations:'MOI'
  },
  {
    commentair: {
      date: "2023-10-25T09:24:50.384Z",
      text: "test APP first commentaire1",
      _id: "6538dee67c336559703f3abc",
    },
    name:'Philip',
    email:"philipfederik@gmail.com",
    offerId: "10005",
    phone:"+97443897865",
    commentText:"Nice offer",
    rate: 3,
    type: "Product",
    merchants:'Gucci - Faces',
    userId: "64ba6309809d1b90047a04b2",
    users: [],
    __v: 0,
    _id: "6538dee67c336559703f3abv",
    organisations:'Gulf Exchange'
  },
  {
    commentair: {
      date: "2023-10-25T09:24:50.384Z",
      text: "test APP first commentaire1",
      _id: "6538dee67c336559703f3abc",
    },
    name:'Abood',
    email:"Mohdabood@gmail.com",
    offerId: "10006",
    phone:"+97443897865",
    commentText:"my favourite offer",
    rate: 4,
    type: "Product",
    merchants:'Lancome _ Faces',
    userId: "64ba6309809d1b90047a04b2",
    users: [],
    __v: 0,
    _id: "6538dee67c336559703f3abw",
    organisations:'Masrf Al Rayyan'
  },
  {
    commentair: {
      date: "2023-10-25T09:24:50.384Z",
      text: "test APP first commentaire1",
      _id: "6538dee67c336559703f3abc",
    },
    name:'Apmuhammed',
    email:"Muhammed@gmail.com",
    offerId: "10008",
    phone:"+97443897865",
    commentText:"NIce",
    rate: 3,
    type: "Product",
    merchants:'Calvin Klien - Faces',
    userId: "64ba6309809d1b90047a04b2",
    users: [],
    __v: 0,
    _id: "6538dee67c336559703f3abx",
    organisations:'Mazaya'
  },
  {
    commentair: {
      date: "2023-10-25T09:24:50.384Z",
      text: "test APP first commentaire1",
      _id: "6538dee67c336559703f3abc",
    },
    name:'Zakaria',
    email:"Zak006@gmail.com",
    offerId: "100018",
    phone:"+97443897865",
    commentText:"Recomende to everyone",
    rate: 5,
    type: "Product",
    merchants:'JAC MOTORS',
    userId: "64ba6309809d1b90047a04b2",
    users: [],
    __v: 0,
    _id: "6538dee67c336559703f3aby",
    organisations:'Dukhan Bank'
  },
  {
    commentair: {
      date: "2023-10-25T09:24:50.384Z",
      text: "test APP first commentaire1",
      _id: "6538dee67c336559703f3abc",
    },
    name:'imad',
    email:"sydimad@outlook.com",
    offerId: "10002",
    phone:"+97430444432",
    commentText:"test APP first commentaire1",
    rate: 3,
    type: "Merchant",
    merchants:'Suzuki - Teyseer',
    userId: "64ba6309809d1b90047a04b2",
    users: [],
    __v: 0,
    _id: "6538dee67c336559703f3abz",
    organisations:'SJC'
  },
  {
    commentair: {
      date: "2023-10-25T09:24:50.384Z",
      text: "test APP first commentaire1",
      _id: "6538dee67c336559703f3abc",
    },
    name:'zain',
    email:"imzain@yahoo.com",
    offerId: "10003",
    phone:"+97443897865",
    commentText:"Easy to use",
    rate: 5,
    type: "Merchant",
    merchants:'Chery - Salwa Road',
    userId: "64ba6309809d1b90047a04b2",
    users: [],
    __v: 0,
    _id: "6538dee67c336559703f3abda",
    organisations:'Masrf Al Rayyan'
  },
  {
    commentair: {
      date: "2023-10-25T09:24:50.384Z",
      text: "test APP first commentaire1",
      _id: "6538dee67c336559703f3abc",
    },
    name:'mehr',
    email:"meraproper@yahoo.com",
    offerId: "10004",
    phone:"+97443897865",
    commentText:"Easy to use",
    rate: 3,
    type: "Merchant",
    merchants:'Nissan - Salwa Road',
    userId: "64ba6309809d1b90047a04b2",
    users: [],
    __v: 0,
    _id: "6538dee67c336559703f3abeb",
    organisations:'MOI'
  },
  {
    commentair: {
      date: "2023-10-25T09:24:50.384Z",
      text: "test APP first commentaire1",
      _id: "6538dee67c336559703f3abc",
    },
    name:'Philip',
    email:"philipfederik@gmail.com",
    offerId: "10005",
    phone:"+97443897865",
    commentText:"Nice offer",
    rate: 4,
    type: "Merchant",
    merchants:'Infinity - Salwa Road',
    userId: "64ba6309809d1b90047a04b2",
    users: [],
    __v: 0,
    _id: "6538dee67c336559703f3abfc",
    organisations:'Gulf Exchange'
  },
  {
    commentair: {
      date: "2023-10-25T09:24:50.384Z",
      text: "test APP first commentaire1",
      _id: "6538dee67c336559703f3abc",
    },
    name:'Abood',
    email:"Mohdabood@gmail.com",
    offerId: "10006",
    phone:"+97443897865",
    commentText:"my favourite offer",
    rate: 3,
    type: "Merchant",
    merchants:'The Auto Man Serivce',
    userId: "64ba6309809d1b90047a04b2",
    users: [],
    __v: 0,
    _id: "6538dee67c336559703f3abgd",
    organisations:'Masrf Al Rayyan'
  },
  {
    commentair: {
      date: "2023-10-25T09:24:50.384Z",
      text: "test APP first commentaire1",
      _id: "6538dee67c336559703f3abc",
    },
    name:'Apmuhammed',
    email:"Muhammed@gmail.com",
    offerId: "10008",
    phone:"+97443897865",
    commentText:"NIce",
    rate: 5,
    type: "Merchant",
    merchants:'BMW - Al Sadd Showroom',
    userId: "64ba6309809d1b90047a04b2",
    users: [],
    __v: 0,
    _id: "6538dee67c336559703f3abhe",
    organisations:'Mazaya'
  },
  {
    commentair: {
      date: "2023-10-25T09:24:50.384Z",
      text: "test APP first commentaire1",
      _id: "6538dee67c336559703f3abc",
    },
    name:'Zakaria',
    email:"Zak006@gmail.com",
    offerId: "100018",
    phone:"+97443897865",
    commentText:"Recomende to everyone",
    rate: 4,
    type: "Merchant",
    merchants:'GMC - E Industrial',
    userId: "64ba6309809d1b90047a04b2",
    users: [],
    __v: 0,
    _id: "6538dee67c336559703f3abif",
    organisations:'Dukhan Bank'
  },
];
  const [filteredFeedback, setFilteredFeedback] = useState([]);

  console.log( "appFeedbacksorg",appFeedbacksorg);
  console.log(appFeedbacks, "appFeedbacks");
  useEffect(() => {
    dispatch(fetchAppfeedbacks());

    const y = appFeedbacks.filter((e) => e.type == "APP")
    setFilteredFeedback(y)
  }, [dispatch]);

  // console.log(userArchieve)
  const handleClick = (event, cellValues) => {
    console.log(cellValues.row.id, userArchieve);
    setUserArchieve((prev) => !prev);
  };

  useMemo(() => {
    console.log('memoooo.......')
  }, [filteredFeedback]);
  function getChipProps(params){
    if (params.value === "Archieve") {
      return {
        icon: <WarningIcon style={{ fill: "#D23030" }} />,
        label: params.value,
        style: {
          borderColor: "#D23030",
          color: "#D23030",
        },
      };
    } else {
      return {
        icon: <DoneIcon style={{ fill: "#66BB6A" }} />,
        label: params.value,
        style: {
          borderColor: "#66BB6A",
          color: "#66BB6A",
        },
      };
    }
  }

  const columns = [
    {
      field: "userId",
      headerName: "ID",
      width: 100,
      backgroundColor:'red' ,
      sortable: true,
      renderCell: (params) => (
        // console.log("renderCell params: ",params)
        <div style={{ whiteSpace: 'nowrap', overflow: 'scroll', textOverflow: 'ellipsis' }}>
          {params.row.offerId ? params.row.offerId : ''}
          {params.row.merchantId  ? params.row.merchantId : ''}
          {params.row.productId  ? params.row.productId : ''}
          {!params.row.productId && !params.row.merchantId && !params.row.offerId ? params.row.userId    : ''}
        </div>
      ),
    },
    {
      field: "name",
      headerName: "Name",
      flex: 0.7,
    },
    // {
    //   field: "first_name",
    //   headerName: "Name",
    //   flex: 0.7,
    //   renderCell: (params) => (
    //     // console.log("renderCell params: ",params)
    //     <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
    //       {params.row.users.length > 0 ? params.row.users[0].first_name +' '+ params.row.users[0].last_name : ''}
    //     </div>
    //   ),
    // },
    {
      field: "email",
      headerName: "Email",
      flex: 0.7,
      // renderCell: (params) => (
      //   // console.log("renderCell params: ",params)
      //   <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
      //     {params.row.users.length > 0 ? params.row.users[0].email : ''}
      //   </div>
      // ),
    },
    {
      field: "phone",
      headerName: "Phone",
      flex: 0.9,
      // renderCell: (params) => (
      //   // console.log("renderCell params: ",params)
      //   <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
      //     {params.row.users.length > 0 ? params.row.users[0].phone : ''}
      //   </div>
      // ),
    },
    {
      field: 'commentText', // new field for displaying comment text
      headerName: 'Comments',
      width: 200,
      // renderCell: (params) => (
      //   // console.log("renderCell params: ",params)
      //   <div style={{ whiteSpace: 'nowrap', overflow:"scroll", textOverflow: 'ellipsis' }}>
      //     {params.row.commentair.length > 0 ? params.row.commentair[0].text : ''}
      //   </div>
      // ),
    },
    // {
    //   field: "userId",
    //   headerName: "User ID",
    //   flex: 0.5,
    //   renderCell: (params) => {
    //   // console.log("pppp:",params)
    //    // return params.value.replace(/^(\d{3})(\d{3})(\d{4})/, "($1)$2-$3");
    //   },
    // },
    
    {
      field: "rate",
      headerName: "Rating",
      flex: 0.5,
    },
    {
      field: "merchants",
      headerName: "Merchant",
      flex: 0.8,
    },
    {
      field: "organisations",
      headerName: "Organisation",
      flex: 0.8,
    }
    // {
    //   field: "status",
    //   headerName: "Status",
    //   // type: "boolean",
    //   type: "singleSelect",
    //   valueOptions: ["Archieve", "Available"],
    //   editable: true,
    //   flex: 0.7,
    //   renderCell: (params) => {
    //     return (
    //       <Chip variant="outlined" size="small" {...getChipProps(params)} />
    //     );
    //   },
    // },
    // {
    //   field: "actions",
    //   headerName: "Action",

    //   flex: 0.5,
    //   renderCell: (cellValues) => {
    //     return (
    //       <FlexBetween gap="0.5rem">
    //         {/* <Button
    //           variant="contained"
    //           sx={{
    //             backgroundColor: theme.palette.secondary[400],
    //             color: theme.palette.primary[600],
    //             "&:hover": {
    //               backgroundColor: theme.palette.secondary[500],
    //               color: "#000000",
    //             },
    //             height: "1.3rem",
    //             borderRadius: "10px",
    //           }}
    //           onClick={(event) => {
    //             handleClick(event, cellValues);
    //           }}
    //         >
    //           Edit
    //         </Button> */}
    //         <Tooltip title="Edit">
    //           <IconButton onClick={() => navigate('/adduser')}>
    //             <CreateOutlinedIcon
    //               sx={{ color: theme.palette.secondary[400] }}
    //             />
    //           </IconButton>
    //         </Tooltip>
    //       </FlexBetween>
    //     );
    //   },
    // },
  ];

  const columns1 = [
    {
      field: "userId",
      headerName: "ID",
      width: 100,
      backgroundColor:'red' ,
      sortable: true,
      renderCell: (params) => (
        // console.log("renderCell params: ",params)
        <div style={{ whiteSpace: 'nowrap', overflow: 'scroll', textOverflow: 'ellipsis' }}>
          {params.row.offerId ? params.row.offerId : ''}
          {params.row.merchantId  ? params.row.merchantId : ''}
          {params.row.productId  ? params.row.productId : ''}
          {!params.row.productId && !params.row.merchantId && !params.row.offerId ? params.row.userId    : ''}
        </div>
      ),
    },
    {
      field: "name",
      headerName: "Name",
      flex: 0.7,
    },
    // {
    //   field: "first_name",
    //   headerName: "Name",
    //   flex: 0.7,
    //   renderCell: (params) => (
    //     // console.log("renderCell params: ",params)
    //     <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
    //       {params.row.users.length > 0 ? params.row.users[0].first_name +' '+ params.row.users[0].last_name : ''}
    //     </div>
    //   ),
    // },
    {
      field: "email",
      headerName: "Email",
      flex: 0.7,
      // renderCell: (params) => (
      //   // console.log("renderCell params: ",params)
      //   <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
      //     {params.row.users.length > 0 ? params.row.users[0].email : ''}
      //   </div>
      // ),
    },
    {
      field: "phone",
      headerName: "Phone",
      flex: 0.9,
      // renderCell: (params) => (
      //   // console.log("renderCell params: ",params)
      //   <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
      //     {params.row.users.length > 0 ? params.row.users[0].phone : ''}
      //   </div>
      // ),
    },
    {
      field: 'commentText', // new field for displaying comment text
      headerName: 'Comments',
      width: 200,
      // renderCell: (params) => (
      //   // console.log("renderCell params: ",params)
      //   <div style={{ whiteSpace: 'nowrap', overflow:"scroll", textOverflow: 'ellipsis' }}>
      //     {params.row.commentair.length > 0 ? params.row.commentair[0].text : ''}
      //   </div>
      // ),
    },
    // {
    //   field: "userId",
    //   headerName: "User ID",
    //   flex: 0.5,
    //   renderCell: (params) => {
    //   // console.log("pppp:",params)
    //    // return params.value.replace(/^(\d{3})(\d{3})(\d{4})/, "($1)$2-$3");
    //   },
    // },
    
    {
      field: "rate",
      headerName: "Rating",
      flex: 0.5,
    },
    {
      field: "organisations",
      headerName: "Organisation",
      flex: 0.8,
    }
    // {
    //   field: "status",
    //   headerName: "Status",
    //   // type: "boolean",
    //   type: "singleSelect",
    //   valueOptions: ["Archieve", "Available"],
    //   editable: true,
    //   flex: 0.7,
    //   renderCell: (params) => {
    //     return (
    //       <Chip variant="outlined" size="small" {...getChipProps(params)} />
    //     );
    //   },
    // },
    // {
    //   field: "actions",
    //   headerName: "Action",

    //   flex: 0.5,
    //   renderCell: (cellValues) => {
    //     return (
    //       <FlexBetween gap="0.5rem">
    //         {/* <Button
    //           variant="contained"
    //           sx={{
    //             backgroundColor: theme.palette.secondary[400],
    //             color: theme.palette.primary[600],
    //             "&:hover": {
    //               backgroundColor: theme.palette.secondary[500],
    //               color: "#000000",
    //             },
    //             height: "1.3rem",
    //             borderRadius: "10px",
    //           }}
    //           onClick={(event) => {
    //             handleClick(event, cellValues);
    //           }}
    //         >
    //           Edit
    //         </Button> */}
    //         <Tooltip title="Edit">
    //           <IconButton onClick={() => navigate('/adduser')}>
    //             <CreateOutlinedIcon
    //               sx={{ color: theme.palette.secondary[400] }}
    //             />
    //           </IconButton>
    //         </Tooltip>
    //       </FlexBetween>
    //     );
    //   },
    // },
  ];

  const rows = [
    {
      id: 1,
      _id: "63e026fd24cddc4076c1721e",
      name: "Johnas Peter",
      email: "johnaspeter@gmail.com",
      country: "qatar",
      phoneNumber: "97456958645",
      lolPoints: 7464,
      status: "Available",
    },
    {
      id: 2,
      _id: "33a026fd24cddc4076c1721h",
      name: "Smith Peter",
      email: "smith@gmail.com",
      country: "UAE",
      phoneNumber: "97456958645",
      lolPoints: 5464,
      status: "Available",
    },
    {
      id: 3,
      _id: "57e026fd24cddc40786c1721e",
      name: "Fatima Khalid",
      email: "fatom@gmail.com",
      country: "Bahrain",
      phoneNumber: "97456958645",
      lolPoints: 4464,
      status: "Available",
    },
    {
      id: 4,
      _id: "57e026fd24cddc40786c1721e",
      name: "Thahsin Shaik",
      email: "fatom@gmail.com",
      country: "qatar",
      phoneNumber: "97456958645",
      lolPoints: 4464,
      status: "Available",
    },
    {
      id: 5,
      _id: "57e026fd24cddc40786c1721e",
      name: "Yazed",
      email: "fatom@gmail.com",
      country: "qatar",
      phoneNumber: "97456958645",
      lolPoints: 4464,
      status: "Available",
    },
    {
      id: 6,
      _id: "54ve026fd24cddc40786c1721e",
      name: "Aysha Rashid",
      email: "fatom@gmail.com",
      country: "qatar",
      phoneNumber: "97456958645",
      lolPoints: 4464,
      status: "Available",
    },
    {
      id: 7,
      _id: "5we026fd24cddr40786c1721e",
      name: "Sara Yousuf",
      email: "fatom@gmail.com",
      country: "qatar",
      phoneNumber: "97456958645",
      lolPoints: 4464,
      status: "Available",
    },
    {
      id: 8,
      _id: "5we026fd24cddr40786c1721e",
      name: "Reem Nasar",
      email: "fatom@gmail.com",
      country: "qatar",
      phoneNumber: "97456958645",
      lolPoints: 4464,
      status: "Archieve",
    },
    {
      id: 9,
      _id: "5we026fd24cddr40786c1721e",
      name: "Maraim Jaber",
      email: "fatom@gmail.com",
      country: "qatar",
      phoneNumber: "97456958645",
      lolPoints: 4464,
      status: "Available",
    },
    {
      id: 10,
      _id: "5we026fd24cddr40786c1721e",
      name: "For You Saloon",
      email: "fatom@gmail.com",
      country: "qatar",
      phoneNumber: "97456958645",
      lolPoints: 4464,
      status: "Available",
    },
  ];

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  const handleChangeTab = (event, newValue) => {
    console.log("handleChangeTab newValue:",newValue)
    const x = ["APP","Merchant","Product","Offer"]
    const a = appFeedbacks.filter((e) => e.type == x[newValue])
    setFilteredFeedback(a)
    console.log("handleChangeTab AAAAAA:",a)
    setValue(newValue);
  };
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  return (
    <Box m="1.5rem 2.5rem">
      <FlexBetween gap="1.5rem">
        <Header title="Feedbacks" />
      
      </FlexBetween>
      <Tabs value={value} onChange={handleChangeTab} aria-label="basic tabs example">
    <Tab label="App" {...a11yProps(0)} />
    <Tab label="Merchant" {...a11yProps(1)} />
    <Tab label="Product" {...a11yProps(2)} />
    <Tab label="Offer" {...a11yProps(3)} />
  </Tabs>

<CustomTabPanel value={value} index={value}>
<Box
        mt="10px"
        height="75vh"
        width="100%"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            // backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },

          "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
        }}
      >
        <DataGrid
          // loading={isLoading || !data}
           getRowId={(row) => row._id}
          rows={filteredFeedback && filteredFeedback}
          columns={value == 0 ? columns1 : columns}
          slots={{ toolbar: GridToolbar }} 
        />
      </Box>
</CustomTabPanel>
<CustomTabPanel value={value} index={1}>Merchant</CustomTabPanel>
<CustomTabPanel value={value} index={2}>Product</CustomTabPanel>
<CustomTabPanel value={value} index={3}>Offer</CustomTabPanel>
      {/* <Box
        mt="10px"
        height="75vh"
        width="100%"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            // backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },

          "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
        }}
      >
        <DataGrid
          // loading={isLoading || !data}
          // getRowId={(row) => row._id}
          rows={rows}
          columns={columns}
          slots={{ toolbar: GridToolbar }} 
        />
      </Box> */}
    </Box>
  );
};

export default Feedbacks;
