import { useTheme } from "@emotion/react";
import { Box, Button, useMediaQuery } from "@mui/material";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import FlexBetween from "../../components/FlexBetween";
import Header from "../../components/Header";
import MoreMenu from "../../components/MoreMenu";
import AddIcon from "@mui/icons-material/Add";
import BranchCard from "../../components/BranchCard";
import FilterBranch from "../../components/FilterBranch";
import AddBranch from "./BranchForm";
import { useDispatch, useSelector } from "react-redux";
import { fetchALLMerchants, getMerchantType ,getAllMerchants} from "../../features/merchants/merchantSlice";

const Branches = () => {
  const isNonMobile = useMediaQuery("(min-width: 1000px)");
  const dispatch = useDispatch()
  const allBranches = useSelector(getMerchantType());
  const theme = useTheme();
  const allMerchants = useSelector(getAllMerchants());
  console.log("allMerchants branches:",allMerchants.filter((_) => _.type === "branch"))


  useEffect(() => {
    dispatch(fetchALLMerchants());
  }, [dispatch]);

  console.log(allBranches, "All Branches");
  return (
    <Box m="1.5rem 2.5rem">
      <FlexBetween gap="1.5rem">
        <Header title="All Branches" />
        <Box>
          <FlexBetween gap="1.5rem" alignItems="center">
            <Link to="/addbranch" style={{ textDecoration: "none" }}>
              <Button
                variant="contained"
                // color={theme.palette.secondary[300]}
                sx={{
                  backgroundColor: theme.palette.secondary[400],
                  color: theme.palette.primary[600],
                  "&:hover": {
                    backgroundColor: theme.palette.secondary[500],
                    color: "#000000",
                  },
                }}
                endIcon={<AddIcon />}
                onClick={() => <AddBranch />}
              >
                ADD BRANCH
              </Button>
            </Link>
            <MoreMenu options={["Add Branch", "Export to Excel"]} />
          </FlexBetween>
        </Box>
      </FlexBetween>
      <FilterBranch />
      <Box
        mt="20px"
        display="grid"
        gridTemplateColumns="repeat(3, minmax(0, 1fr))"
        justifyContent="space-between"
        rowGap="20px"
        columnGap="1.33%"
        sx={{
          "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
        }}
      >
        {allBranches.map((branch) => (
          <BranchCard
            category={branch.merchCategory}
            key={branch._id}
            _id={branch._id}
            name={branch.merchName}
            merchant="Papa Johns"
            contactNum={branch.merchPvtContact[0]}
            email={branch.merchPvtEmail[0]}
            logo={branch.merchLogo}
            // period="30 June 2024"
          />
        ))}

        
      </Box>
    </Box>
  );
};

export default Branches;
