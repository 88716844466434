import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  Collapse,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import FlexBetween from "./FlexBetween";
import YellowSwitch from "./YellowSwitch";
import ajwa from "../assets/ajwa_sultanhmet.png";
import EditIcon from "@mui/icons-material/Edit";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const BranchCard = ({
  _id,
  name,
  contactNum,
  email,
  merchant,
  period,
  category,
  logo
}) => {
  const theme = useTheme();
  const [isExpanded, setIsExpanded] = useState(false);

  const [state, setState] = useState({
    show: false,
    available: false,
    archieve: false,
  });

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };
  return (
    <Card
      sx={{
        backgroundImage: "none",
        backgroundColor: theme.palette.background.alt,
        borderRadius: "0.55rem",
      }}
    >
      <CardContent sx={{ display: "flex", flexDirection: "column" }}>
        <FlexBetween>
          <Box>
            <Typography
              sx={{ fontSize: 14 }}
              color={theme.palette.secondary[700]}
              gutterBottom
            >
              {category}
            </Typography>
            <Typography variant="h5" component="div">
              {name}
            </Typography>
          </Box>

          <CardMedia
            component="img"
            sx={{ width: 60, height: 60, borderRadius: "50%" }}
            image={logo}
            alt="Live from space album cover"
          />
        </FlexBetween>
        <Box>
          {" "}
          <Chip label="Papa Johns" sx={{ marginTop: "15px" }} />
        </Box>

        {/* <Typography variant="body2">Merchant : {merchant}</Typography> */}
      </CardContent>
      <CardActions>
        <FlexBetween sx={{ width: "100%" }}>
          <Tooltip title="Edit Branch">
            <IconButton
              variant="primary"
              size="small"
              // onClick={() => setIsExpanded(!isExpanded)}
            >
              <EditIcon
                sx={{
                  "&:hover": {
                    color: theme.palette.secondary[500],
                  },
                }}
              />
            </IconButton>
          </Tooltip>
          <IconButton
            // variant="primary"
            size="small"
            onClick={() => setIsExpanded(!isExpanded)}
          >
            {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </FlexBetween>
      </CardActions>
      <Collapse
        in={isExpanded}
        timeout="auto"
        unmountOnExit
        sx={{
          color: theme.palette.neutral[300],
        }}
      >
        <CardContent>
          <FormControl component="fieldset" variant="standard">
            <FormLabel
              sx={{
                color: theme.palette.secondary[500],
              }}
              component="legend"
            >
              Actions
            </FormLabel>
            <FormGroup
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                gap: "1.3rem",
              }}
            >
              <FormControlLabel
                control={
                  <YellowSwitch
                    checked={state.show}
                    onChange={handleChange}
                    name="show"
                  />
                }
                label="Show / Hide"
              />
              <FormControlLabel
                control={
                  <YellowSwitch
                    labelplacement="bottom"
                    checked={state.archieve}
                    onChange={handleChange}
                    name="archieve"
                  />
                }
                label="Archieve"
              />
              <FormControlLabel
                control={
                  <YellowSwitch
                    labelplacement="bottom"
                    checked={state.available}
                    onChange={handleChange}
                    name="available"
                  />
                }
                label="Available"
              />
            </FormGroup>
          </FormControl>

          <Typography>Contact Number: {contactNum}</Typography>
          <Typography>Email: {email}</Typography>
          <Typography>Contract Period: {period}</Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default BranchCard;
