import {
  Avatar,
  Badge,
  Box,
  Button,
  ButtonGroup,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import React, { useState } from "react";
// import FlexBetween from "../../components/FlexBetween";
import DeleteIcon from "@mui/icons-material/Delete";
// import EditIcon from "@mui/icons-material/Edit";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";

import Header from "../../components/Header";
import SearchBar from "../../components/SearchBar";
import { useTheme } from "@emotion/react";
import ajwaSulthanhamat from "../../assets/ajwa_sultanhmet.png";

const CreateInvoice = () => {
  const theme = useTheme();
  const [qtyCount, setQtyCount] = React.useState(1);
  const [productList, setProductList] = useState([]);
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const invoiceSchema = yup.object().shape({
    first_name: yup.string().required("required"),
    last_name: yup.string().required("required"),
    phone: yup.string().required("required"),
    username: yup.string().required("required"),
    userFromDate: yup.string().required("required"),
    userToDate: yup.string().required("required"),
    userType: yup.string().required("choose Product Type"),

    merhOrgId: yup.string().required("required"),
  });

  const addProductToList = (product, quantity) => {
    const newProduct = {
      id: Math.random(), //change this id to product id from mongodb
      product: product,
      quantity: quantity,
    };
    setProductList([...productList, newProduct]);
  };

  console.log(productList, "productsList");

  const initialValues = {
    first_name: "",
    last_name: "",
    phone: "",
    username: "",
    userFromDate: "",
    userToDate: "",
    userType: null,
    merhOrgId: null,
    product: null,
    quantity: "",
    // logo: null,
  };
  const handleFormSubmit = (values) => {
    console.log(values);
  };
  return (
    <Box m="1.5rem 2.5rem">
      <Header title="Create Invoice" />
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={invoiceSchema}
        style={{ marginTop: "20px" }}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Client Name"
                onBlur={handleBlur}
                onChange={handleChange}
                // onChange={handleChange}
                // value={values.userType}
                name="first_name"
                error={!!touched.first_name && !!errors.first_name}
                helperText={touched.first_name && errors.first_name}
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="filled"
                select // tell TextField to render select
                label="Choose Offer"
                onBlur={handleBlur}
                onChange={handleChange}
                // onChange={handleChange}
                // value={values.userType}
                name="userType"
                defaultValue=""
                error={!!touched.userType && !!errors.userType}
                helperText={touched.userType && errors.userType}
                sx={{ gridColumn: "span 2" }}
              >
                <MenuItem key={1} value="Merchant">
                  Merchant
                </MenuItem>
                <MenuItem key={2} value="Organization">
                  Organization
                </MenuItem>
              </TextField>
              <TextField
                fullWidth
                variant="filled"
                type="number"
                label="Total Price"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.username}
                name="username"
                error={!!touched.username && !!errors.username}
                helperText={touched.username && errors.username}
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="filled"
                type="date"
                label="Date"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.userToDate}
                InputLabelProps={{ shrink: true }}
                name="userToDate"
                error={!!touched.userToDate && !!errors.userToDate}
                helperText={touched.userToDate && errors.userToDate}
                sx={{ gridColumn: "span 2" }}
              />
              <Header subtitle="Add products to create invoice" />
              <Box
                display="flex"
                justifyContent="center"
                flexDirection="column"
                sx={{ gridColumn: "span 4" }}
              >
                <Box display="flex">
                  <SearchBar placeholder="Search Products..." />
                </Box>
                <List>
                  <ListItem button>
                    <ListItemAvatar>
                      <Avatar src={ajwaSulthanhamat} />
                    </ListItemAvatar>
                    <ListItemText
                      primary="product 1"
                      secondary="product description"
                    />
                    <ListItemText primary="QAR 34" />

                    <ButtonGroup display="flex">
                      <Button
                        aria-label="reduce"
                        color="secondary"
                        onClick={() => {
                          setQtyCount(Math.max(qtyCount - 1, 0));
                        }}
                      >
                        <RemoveIcon fontSize="small" />
                      </Button>
                      <Badge color="secondary" badgeContent={qtyCount}></Badge>
                      <Button
                        aria-label="increase"
                        color="secondary"
                        onClick={() => {
                          setQtyCount(qtyCount + 1);
                        }}
                      >
                        <AddIcon fontSize="small" />
                      </Button>
                    </ButtonGroup>

                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        // onClick={() => handleDelete(item)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                  <ListItem button>
                    <ListItemAvatar>
                      <Avatar src={ajwaSulthanhamat} />
                    </ListItemAvatar>
                    <ListItemText
                      primary="product 1"
                      secondary="product description"
                    />
                    <ListItemText primary="QAR 68" />

                    <ButtonGroup display="flex">
                      <Button
                        aria-label="reduce"
                        color="secondary"
                        onClick={() => {
                          setQtyCount(Math.max(qtyCount - 1, 0));
                        }}
                      >
                        <RemoveIcon fontSize="small" />
                      </Button>
                      <Badge color="secondary" badgeContent={qtyCount}></Badge>
                      <Button
                        aria-label="increase"
                        color="secondary"
                        onClick={() => {
                          setQtyCount(qtyCount + 1);
                        }}
                      >
                        <AddIcon fontSize="small" />
                      </Button>
                    </ButtonGroup>

                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        // onClick={() => handleDelete(item)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                </List>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <Typography variant="h4" color={theme.palette.secondary[500]}>
                    Total : QAR 345
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box display="flex" justifyContent="end" m={7}>
              <Button type="submit" color="secondary" variant="contained">
                Create Invoice
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default CreateInvoice;
