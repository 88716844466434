import axios from "axios";
import authHeader from "../../services/auth-header";
import {api_AddProductCategory, api_UpdateProductCategory} from "../../constants/api";

import { api_AllProducts_category} from "../../constants/api";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

export const fetchALLProductCategories = createAsyncThunk(
  "productCategory/getAPI",
  async () => {
    const response = await axios.post(api_AllProducts_category,
      {
       },
       { headers: authHeader() }
    );
    console.log("fetchALLProductCategories response:",response)
    return response.data;
  }
);

export const saveNewProductCategory = createAsyncThunk(
  "productCategory/createAPI",
  async (payload) => {
    const response = await axios.post(api_AddProductCategory,
      payload,
      { headers: authHeader() }
    );
    console.log(response.data);
    return response.data;
  }
);

export const updateProductCategory = createAsyncThunk(
  "productCategory/updateAPI",
  async (payload) => {
    console.log(payload, "payloas");
    const response = await axios.patch(api_UpdateProductCategory,payload,
      { headers: authHeader() }
    );
    return response.data;
  }
);

const initialState = {
  productsCategoryData: [],
  loading: "idle",
};

const productCategorySlice = createSlice({
  name: "productCategory",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchALLProductCategories.pending, (state, action) => {
      state.loading = "pending";
    });
    builder.addCase(fetchALLProductCategories.fulfilled, (state, action) => {
      state.loading = "idle";
      state.productsCategoryData = action.payload;
    });
    // builder.addCase(fetchALLProductCategories.fulfilled, (state, action) => {
    //   state.loading = "idle";
    //   state.productsCategoryData = action.payload;
    // });
    builder.addCase(saveNewProductCategory.pending, (state, action) => {
      state.loading = "pending";
    });
    builder.addCase(saveNewProductCategory.fulfilled, (state, action) => {
      state.loading = "idle";
      state.productsCategoryData.unshift(action.payload);
    });

    builder.addCase(updateProductCategory.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(updateProductCategory.fulfilled, (state, action) => {
      state.loading = "idle";
      state.productsCategoryData = state.productsCategoryData.filter(
        (_) => _.id !== action.payload.id
      );
      state.productsCategoryData.unshift(action.payload);
    });
  },
});

export const getAllProductCategories = (state) =>
  state.productcategory.productsCategoryData;
export const getLoading = (state) => state.productcategory.loading;

export const getProductCategoryById = (id) => {
  return (state) =>
    state.productcategory.productsCategoryData.filter((_) => _._id === id)[0];
};

export default productCategorySlice.reducer;
