import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import {
  Typography,
  Avatar,
  useTheme,
  ThemeProvider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  InputAdornment,
  IconButton,

} from "@mui/material";

import { useNavigate } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useDispatch, useSelector } from "react-redux";
import Logo from "../../assets/mzayaLogo.png";
import Container from "@mui/material/Container";
import { Formik } from "formik";
import * as yup from "yup";
import Header from "../../components/Header";
import { useState } from "react";
import { MuiOtpInput } from "mui-one-time-password-input";
import { register } from "../../features/auth/authSlice";

const SignUp = () => {
  const dispatch = useDispatch();
  const [otpDialog, setOtpDialog] = useState(false);
  const [otp, setOtp] = useState("");
  const [showPassword, setShowPassword] = React.useState(false);
  const handleOTPChange = (newValue) => {
    setOtp(newValue);
  };
  const theme = useTheme();
  const navigate = useNavigate();
  const initialValues = {};

  const signUpSchema = yup.object().shape({

    username: yup.string().required("required"),
    email: yup
    .string()
    .email("This is not a valid email.")
    .required("Email is requried!"),
  password: yup
    .string()
    // .test(
    //   "len",
    //   "The password must be between 6 and 40 characters.",
    //   (val) =>
    //     val && val.toString().length >= 6 && val.toString().length <= 40
    // )
    .required("Password is required!"),
    phone: yup.string().required("required"),
  });
  const handleSubmit = (values) => {
    console.log("handleSubmit values:::::::",values);
    // event.preventDefault();
    //const data = new FormData(event.currentTarget); 
    //console.log("handleSubmit data:::::::",data);
    //console.log({
     // email: data.get("email"),
      //password: data.get("password"),
    //});

dispatch(register(values))
.unwrap()
.then((res) => {
  if(res._id){
    navigate("/signin");
  }
  // navigate("/profile");
  console.log("handleSubmit register dispacth res:",res)
  //window.location.reload();
})
.catch((err) => {
  console.log("handleSubmit register dispacth err:",err)
 // setLoading(false);
});
    setOtpDialog(true);
  };

  const handleClose = () => {
    setOtpDialog(false);
  };
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="sm">
        <Box
          sx={{
            boxShadow: 3,
            borderRadius: 2,
            px: 4,
            py: 6,
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "black" }}>
            <img src={Logo} alt="Mzaya Premium Loyalty Program" />
          </Avatar>
          {/* <Typography component="h1" variant="h5">
            Sign in
          </Typography> */}
          <Header subtitle="Sign Up" />
          <Formik
            onSubmit={handleSubmit}
            initialValues={initialValues}
            validationSchema={signUpSchema}
            style={{ marginTop: "20px" }}
          >
            {({
              errors,
              touched,
              values,
              // setFieldValue,
              handleBlur,
              handleChange,
              handleSubmit,
            }) => (
              <Box
                component="form"
                onSubmit={handleSubmit}
                noValidate
                sx={{ mt: 1 }}
              >
                <TextField
                  margin="normal"
                  fullWidth
                  id="username"
                  label="Name"
                  name="username"
                  autoComplete="username"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.username}
                  autoFocus
                  error={!!touched.username && !!errors.username}
                  helperText={touched.username && errors.username}
                />
                {/* <TextField
                  margin="normal"
                  fullWidth
                  id="orgName"
                  label="Organization Name"
                  name="orgName"
                  autoComplete="orgName"
                /> */}
                {/* <TextField
                  margin="normal"
                  fullWidth
                  id="regCode"
                  label="Registration Code"
                  name="regCode"
                  autoComplete="regCode"
                /> */}
                <TextField
                  margin="normal"
                  fullWidth
                  id="phone"
                  label="Phone Number"
                  name="phone"
                  type="number"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.phone}
                  autoFocus
                  error={!!touched.phone && !!errors.phone}
                  helperText={touched.phone && errors.phone}
                />
                <TextField
                  margin="normal"
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                  autoFocus
                  error={!!touched.email && !!errors.email}
                  helperText={touched.email && errors.email}
                />
                {/* <TextField
                  margin="normal"
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                /> */}
                <TextField
                    margin="normal"
                    fullWidth
                    name="password"
                    label="Password"
                    value={values.password}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type={showPassword ? "text" : "password"}
                    id="password"
                    autoComplete="current-password"
                    error={!!touched.password && !!errors.password}
                    helperText={touched.password && errors.password}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                <FormControlLabel
                  control={
                    <Checkbox
                      value="remember"
                      color="primary"
                      sx={{
                        "&.Mui-checked": {
                          color: theme.palette.secondary[500],
                        },
                      }}
                    />
                  }
                  label="Remember me"
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{
                    mt: 3,
                    mb: 2,
                    backgroundColor: theme.palette.secondary[400],
                    color: theme.palette.primary[600],
                    "&:hover": {
                      backgroundColor: theme.palette.secondary[500],
                      color: "#000000",
                    },
                  }}
                //  onClick={() => handleFormSubmit(values)}
                >
                  Sign Up
                </Button>
              </Box>
            )}
          </Formik>
        </Box>
      </Container>
      {/* {otpDialog && ( */}
     
      {/* )} */}
    </ThemeProvider>
  );
};

export default SignUp;
