import {
  Alert,
  Box,
  Button,
  FormControlLabel,
  MenuItem,
  Snackbar,
  TextField,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useParams ,useNavigate, Link} from "react-router-dom";
import React, { useState ,useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import * as yup from "yup";
import FlexBetween from "../../components/FlexBetween";
import Header from "../../components/Header";
import MoreMenu from "../../components/MoreMenu";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import YellowSwitch from "../../components/YellowSwitch";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { createregisterationcodes,getAllcreatedregisterationcodes } from "../../features/registrationCode/registraotioncodeSlice";
import {
  fetchALLMerchants,
  getAllMerchants,
  getAllMerchantsNameId,
 fetchALLBranches,
 fetchALLMerchantsNameId
} from "../../features/merchants/merchantSlice";
import {
  sendloyaltypoints
} from "../../features/loyalityPoints/loyalityPointSlice";
import {
  fetchALLUsers,
  getAllUsers
} from "../../features/users/userSlice";
import {
  fetchALLOrganizations,
  getAllOrganizations,
} from "../../features/organizations/organizationSlice";

const AddLoyalityPoints = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const navigate = useNavigate();
  const [regCodes, setRegCodes] = useState([]);
  const [senderType, setSenderType] = useState("Golalita");
  const [receiverType, setReceiverType] = useState("Golalita");
  const [VIP, setVIP] = useState(false);
  const [GolbalyCode, setGolbalyCode] = useState(false);
  const [snackbar, setSnackbar] = useState(false);
  const theme = useTheme(); 
  const allUsers = useSelector(getAllUsers);
  const allMerchants = useSelector(getAllMerchantsNameId());
  const allOrganizations = useSelector(getAllOrganizations);
  const createdregisterationcodes = useSelector(getAllcreatedregisterationcodes);
  console.log("allMerchants:",allMerchants)
  console.log("allUsers:",allUsers)
  const dispatch = useDispatch();



  useEffect(() => {
    dispatch(fetchALLUsers());
    dispatch(fetchALLMerchantsNameId());
     dispatch(fetchALLOrganizations());

  }, [dispatch]);

  const handleVIP = () => {
    setVIP(!VIP);
  };

  const handleSnackbar = () => {
    setSnackbar(true);
  };

  const handleGolbalyCode = () => {
    setGolbalyCode(!GolbalyCode);
  };

  const handleCopyClick = (event, cellValues) => {
    console.log(cellValues.row.registrationCode); //copying registration codes
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar(false);
  };

  const initialValues = {
    fromType: "",
    toTypes: "",
    points:100,
   // VIP: VIP,
    //GolbalyCode: GolbalyCode,
  };

  // const generateRandomCode = (length) => {
  //   let result = "";

  //   const characters =
  //     "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  //   const charactersLength = characters.length;
  //   let counter = 0;

  //   while (counter < 6) {
  //     result += characters.charAt(Math.floor(Math.random() * charactersLength));
  //     counter += 1;
  //   }

  //   console.log(result, "result");
  //   return result;
  // };

  const regCodeArray = (numberCodes) => {
    const codes = Array.from({ length: Number(numberCodes) }, () =>
      Math.random().toString(36).substr(2, 6).toLowerCase()
    );
    setRegCodes(codes);
    // return <>{JSON.stringify(codes)}</>;
  };
  // console.log(regCodes, "regCodes in array");

  const handleFormSubmit = (values) => {
    if (values.fromType == "Golalita") {
      values.fromID = "65844c69179bf8d29ec307d6"
    }
    if (values.toTypes == "Golalita") {
      values.toId = "65844c69179bf8d29ec307d6"
    }
    values.updateMistake =  false;
    //values.VIP = VIP;
   // values.GolbalyCode = GolbalyCode;
    console.log("handleFormSubmit values",values)

    dispatch(sendloyaltypoints(values))
      .unwrap()
      .then((res) => {
console.log("handleFormSubmit res:",res)

if(res.success){
  navigate("/loyalitypoints");
}
  // dispatch(createdregisterationcodes());
    //    navigate("/");
     });
  //  regCodeArray(values.numberCodes);
  };

  const regCodeSchema = yup.object().shape({
    fromType: yup.string().required("required"),
  //  numberCodes: yup.number().required("required"),
  });

  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 0.7,
      sortable: true,
    },
    {
      field: "registrationCode",
      headerName: "Registration Code",
      flex: 0.7,
    },
    {
      flex: 0.1,
      renderCell: (cellValues) => {
        return (
          <>
            <Tooltip title="copy code">
              <ContentCopyIcon
                onClick={(event) => {
                  handleCopyClick(event, cellValues);
                  handleSnackbar({
                    vertical: "top",
                    horizontal: "center",
                  });
                }}
              />
            </Tooltip>

            <Snackbar
              open={snackbar}
              autoHideDuration={6000}
              onClose={handleSnackbarClose}
            >
              <Alert
                onClose={handleSnackbarClose}
                severity="success"
                sx={{ width: "100%" }}
              >
                Copied to clipboard
              </Alert>
            </Snackbar>
          </>
        );
      },
    },
  ];
  const senderHandler = (newSelectedItems) => {
    console.log("senderHandler",newSelectedItems)
    setSenderType(newSelectedItems.value)
  };


  const receiverHandler = (newSelectedItems) => {
    console.log("receiverHandler",newSelectedItems)
    setReceiverType(newSelectedItems.value)
  };
  const rows = createdregisterationcodes.map((item, index) => {
    console.log("rowsrowsrows:",item, index)
    return {
      id: index + 1,
      registrationCode: item.Code,
    };
  });

  const getRowId = (row) => row.id;

  return (
    <Box m="1.5rem 2.5rem">
      <FlexBetween gap="1.5rem">
        <Header title="Generate Loyality Points" />
        <MoreMenu options={["Export to Excel"]} />
      </FlexBetween>
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={regCodeSchema}
        style={{ marginTop: "20px" }}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >

              <TextField
                fullWidth
                variant="filled"
                value={values.fromType}
                select
                label="Sender"
                onBlur={handleBlur}
                onChange={(e) => {
                  handleChange(e);
                  senderHandler(e.target);
                  delete values['fromID'];
                }}
                name="fromType"
                defaultValue=""
                error={!!touched.fromType && !!errors.fromType}
                helperText={touched.fromType && errors.fromType}
                sx={{ gridColumn: "span 2" }}
              >
                <MenuItem key={1} value="Golalita">
                  Golalita
                </MenuItem>
                <MenuItem key={2} value="Merchants">
                  Merchants
                </MenuItem>
                <MenuItem key={3} value="Organisation">
                  Organisation
                </MenuItem>
              </TextField>
           {  senderType == "Merchants" && <TextField
                  fullWidth
                  variant="filled"
                  select
                  type="text"
                  label="Sending Merchant"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e);
                 //   senderHandler(e.target);
                  }}
                  value={values.fromID}
                  name="fromID"
                  error={!!touched.fromID && !!errors.fromID}
                  helperText={touched.fromID && errors.fromID}
                  sx={{ gridColumn: "span 2" }}
                >
                  { allMerchants?.map((merchant) => (
                    <MenuItem key={merchant._id} value={merchant._id}>
                      {merchant.merchName}
                    </MenuItem>
                  ))}
                   
                </TextField>}

                {  senderType == "Organisation" && <TextField
                  fullWidth
                  variant="filled"
                  select
                  type="text"
                  label="Sending Organisation"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e);
                 //   senderHandler(e.target);
                  }}
                  value={values.fromID}
                  name="fromID"
                  error={!!touched.fromID && !!errors.fromID}
                  helperText={touched.fromID && errors.fromID}
                  sx={{ gridColumn: "span 2" }}
                >
                
                    {allOrganizations?.map((merchant) => (
                    <MenuItem key={merchant._id} value={merchant._id}>
                      {merchant.orgName}
                    </MenuItem>
                  ))}
                </TextField>}
              <TextField
                fullWidth
                variant="filled"
                value={values.toTypes}
                select
                label="Receiver"
                onBlur={handleBlur}
                onChange={(e) => {
                  handleChange(e);
                  receiverHandler(e.target);
                  delete values['toId'];
                }}
                name="toTypes"
                defaultValue=""
                error={!!touched.toTypes && !!errors.toTypes}
                helperText={touched.toTypes && errors.toTypes}
                sx={{ gridColumn: "span 2" }}
              >
                <MenuItem key={1} value="Golalita">
                  Golalita
                </MenuItem>
                <MenuItem key={2} value="Merchants">
                  Merchants
                </MenuItem>
                <MenuItem key={3} value="Organisation">
                  Organisation
                </MenuItem>
                <MenuItem key={4} value="User">
                  User
                </MenuItem>
              </TextField>
               {  receiverType == "Merchants" && <TextField
                  fullWidth
                  variant="filled"
                  select
                  type="text"
                  label="Receiving Merchant"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e);
                 //   senderHandler(e.target);
                  }}
                  value={values.toId}
                  name="toId"
                  error={!!touched.toId && !!errors.toId}
                  helperText={touched.toId && errors.toId}
                  sx={{ gridColumn: "span 2" }}
                >
                  { allMerchants?.map((merchant) => (
                    <MenuItem key={merchant._id} value={merchant._id}>
                      {merchant.merchName}
                    </MenuItem>
                  ))}
                   
                </TextField>}

                {  receiverType == "Organisation" && <TextField
                  fullWidth
                  variant="filled"
                  select
                  type="text"
                  label="Receiving Organisation"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e);
                 //   senderHandler(e.target);
                  }}
                  value={values.toId}
                  name="toId"
                  error={!!touched.toId && !!errors.toId}
                  helperText={touched.toId && errors.toId}
                  sx={{ gridColumn: "span 2" }}
                >
                
                    {allOrganizations?.map((merchant) => (
                    <MenuItem key={merchant._id} value={merchant._id}>
                      {merchant.orgName}
                    </MenuItem>
                  ))}
                </TextField>}
                {  receiverType == "User" && <TextField
                  fullWidth
                  variant="filled"
                  select
                  type="text"
                  label="Receiving User"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e);
                 //   senderHandler(e.target);
                  }}
                  value={values.toId}
                  name="toId"
                  error={!!touched.toId && !!errors.toId}
                  helperText={touched.toId && errors.toId}
                  sx={{ gridColumn: "span 2" }}
                >
                
                    {allUsers ?.map((merchant) => (
                    <MenuItem key={merchant._id} value={merchant._id}>
                      {merchant.username}
                    </MenuItem>
                  ))}
                </TextField>}
              {/* <Box
                display="flex"
                flexDirection="column"
                sx={{ gridColumn: "span 1" }}
                pl={5}
              >
                <FormControlLabel
                  control={
                    <YellowSwitch
                      checked={VIP}
                      onChange={(e) => {
                        handleVIP();
                      }}
                      name="VIP"
                    />
                  }
                  label="Update Mistake"
                />
              </Box> */}

           
              <TextField
                  fullWidth
                  variant="filled"
                  type="number"
                  label="Ponits"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.points}
                  name="points"
                  error={!!touched.points && !!errors.points}
                  helperText={touched.points && errors.points}
                  sx={{ gridColumn: "span 1" }}
                />
 <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Description"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.note}
                multiline
                rows={5}
                name="note"
                error={!!touched.note && !!errors.note}
                helperText={touched.note && errors.note}
                sx={{ gridColumn: "span 2" }}
              />
            </Box>

            <Box display="flex" justifyContent="end" m={2}>
              <Button type="submit" color="secondary" variant="contained">
                Send Ponits
              </Button>
            </Box>
          </form>
        )}
      </Formik>
      {createdregisterationcodes.length !== 0 && (
        <Box
          mt="30px"
          height="75vh"
          width="100%"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: theme.palette.primary.light,
            },
            "& .MuiDataGrid-footerContainer": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderTop: "none",
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${theme.palette.secondary[200]} !important`,
            },

            "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
          }}
        >
          <DataGrid
            rows={rows}
            columns={columns}
            getRowId={getRowId}
            slots={{ toolbar: GridToolbar }}
          />
        </Box>
      )}
    </Box>
  );
};

export default AddLoyalityPoints;
