import axios from "axios";

import authHeader from "../../services/auth-header";
import {api_send_loyalty_point, api_get_loyalty_sent} from "../../constants/api";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");



export const sendloyaltypoints = createAsyncThunk(
  "loyalityPoints/sendAPI",
  async (payload) => {
    try {
    console.log("sendloyaltypoints slice payload:",payload)
    const response = await axios.post(api_send_loyalty_point,
      payload,
      { headers: authHeader() }
    );
    console.log("sendloyaltypoints slice res:",response)
    return response.data;} catch (error) {
      // Handle errors here
    console.log("sendloyaltypoints slice error:",error)
      throw error;
    }
  }
);

export const get_loyalty_sent = createAsyncThunk(
  "get_loyalty_sent/sendAPI",
  async (payload) => {
    try {
    console.log("get_loyalty_sent slice payload:",payload)
    const response = await axios.post(api_get_loyalty_sent,
      payload,
      { headers: authHeader() }
    );
    console.log("get_loyalty_sent slice res:",response)
    return response.data;} catch (error) {
      // Handle errors here
    console.log("get_loyalty_sent slice error:",error)
      throw error;
    }
  }
);


const initialState = {
  loyalityPointsData: [],
  sentloyalityPointsData: [],
  loading: "idle",
};

const loyalityPointSlice = createSlice({
  name: "loyalityPoints",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    

    builder.addCase(sendloyaltypoints.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(sendloyaltypoints.fulfilled, (state, action) => {
      state.loading = "idle";
      state.registeraloyalityPointsDatationData = action.payload;
    });
  
    builder.addCase(get_loyalty_sent.pending, (state, action) => {
      state.loading = "pending";
    });
    builder.addCase(get_loyalty_sent.fulfilled, (state, action) => {
      state.loading = "idle";
      state.sentloyalityPointsData = action.payload;
    });

  },
});

export const getAllcreatedregisterationcodes = (state) => state.loyalityPoint.loyalityPointsData;
export const getSentLoyalityPointsData = () => {
  return (state) =>
  //console.log("getSentLoyalityPointsData:",state.loyalityPoint.sentloyalityPointsData)
  state.loyalityPoint.sentloyalityPointsData     
};
export const getLoading = (state) => state.loyalityPoints.loading;

export default loyalityPointSlice.reducer;
