import React, { useEffect, useState } from "react";
import { Box, Button, useTheme, useMediaQuery } from "@mui/material";
import MerchantForm from "./merchantForm";
import MerchantCard from "../../components/MerchantCard";
import PaginationComponent from "../../components/PaginationComponent";
// import { alpha, styled } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import Header from "../../components/Header";
import FlexBetween from "../../components/FlexBetween";
import { Link } from "react-router-dom";
import Filters from "../../components/FilterMerchant";
// import IosShareIcon from "@mui/icons-material/IosShare";
import MoreMenu from "../../components/MoreMenu";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchALLMerchants,
  getAllMerchants,
} from "../../features/merchants/merchantSlice";
import { setIsEditingMerchant } from "../../features/merchants/editSlice";

const Products = () => {
  const isNonMobile = useMediaQuery("(min-width: 1000px)");
  const theme = useTheme();
  const dispatch = useDispatch();
  const allMerchants = useSelector(getAllMerchants());

  useEffect(() => {
    dispatch(fetchALLMerchants(1));
  }, [dispatch]);

  console.log(allMerchants, "All Merchnats");



  return (
    <Box m="1.5rem 2.5rem">
      <FlexBetween gap="1.5rem">
        <Header title="Our Merchants" />
        <Box>
          <FlexBetween gap="1.5rem" alignItems="center">
            <Link to="/addmerchant" style={{ textDecoration: "none" }}>
              <Button
                variant="contained"
                // color={theme.palette.secondary[300]}
                sx={{
                  backgroundColor: theme.palette.secondary[400],
                  color: theme.palette.primary[600],
                  "&:hover": {
                    backgroundColor: theme.palette.secondary[500],
                    color: "#000000",
                  },
                }}
                endIcon={<AddIcon />}
                onClick={() =>  dispatch(setIsEditingMerchant(false))}
              >
                ADD MERCHANT
              </Button>
            </Link>
            <MoreMenu
              options={[
                "Add Merchant",
                "Merchant Category",
                "Add Merchant Category",
                "Export to Excel",
              ]}
            />
          </FlexBetween>
        </Box>
      </FlexBetween>
      {/* <Filters /> */}
   

<PaginationComponent/>
        {/* {allMerchants && allMerchants.map((merchant) => (
          <MerchantCard
            key={merchant._id}
            _id={merchant._id}
            category={merchant.merchCategory}
            name={merchant.merchName}
            contactNum={merchant.merchPvtContact[0]}
            email={merchant.merchPvtEmail[0]}
            logo={merchant.merchLogo}
            // period={merchant.to.substr(0, 10)}
          />
        ))} */}
      
    </Box>
  );
};

export default Products;
