import { configureStore } from "@reduxjs/toolkit";
import productSlice from "./products/productSlice";
import merchantSlice from "./merchants/merchantSlice";
import offerSlice from "./offers/offerSlice";
import organizationSlice from "./organizations/organizationSlice";
import registraotioncodeSlice from "./registrationCode/registraotioncodeSlice";
import loyalityPointSlice from "./loyalityPoints/loyalityPointSlice";
import themeSlice from "./themeSlice";
import productCategorySlice from "./products/productCategorySlice";
import userSlice from "./users/userSlice";
import feedbackSlice from "./feedbacks/feedbackSlice";
import notificationSlice from "./notifications/notificationSlice";
import merchantCategorySlice from "./merchants/merchantCategorySlice";
import authSlice from "./auth/authSlice";
import messageSlice from "./auth/messageSlice";
import editSlice from "./products/editSlice";
import editProdCategorySlice from "./products/editCategorySlice";
import editSliceMerchant from "./merchants/editSlice";
import editSliceOrganization from "./organizations/editSlice";
import editSliceOffer from "./offers/editSlice";
import editMerchCategorySlice from "./merchants/editMerchantCategorySlice"


export const store = configureStore({
  reducer: {
    product: productSlice,
    productcategory: productCategorySlice,
    isEditing: editSlice,
    isEditingMerchant: editSliceMerchant,
    isEditingOrg: editSliceOrganization,
    isEditingOffer:editSliceOffer,
    isEditingProdCategory: editProdCategorySlice,
    isEditingMerchCategory: editMerchCategorySlice,
    merchant: merchantSlice,
    offer: offerSlice,
    organization: organizationSlice,
    registrationCode: registraotioncodeSlice,
    loyalityPoint: loyalityPointSlice,
    merchantcategory: merchantCategorySlice,
    user: userSlice,
    feedback: feedbackSlice,
    notification: notificationSlice,
    theme: themeSlice,
    auth: authSlice,
    message: messageSlice,
  },
  devTools: true,
});
