import React, { useState } from "react";
import ajwaSulthanhamat from "../assets/ajwa_sultanhmet.png";
import {
  TextField,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  ButtonGroup,
  Button,
  Badge,
  ListItemSecondaryAction,
  IconButton,
  FormControlLabel,
  MenuItem,
  ToggleButtonGroup,
  ToggleButton,
  Box,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import YellowSwitch from "../components/YellowSwitch";

const SearchOraganisation = ({ suggestions, onDiscountItemsChange }) => {
  const [buyList, setBuyList] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [selectedItem, setSelectedItem] = useState([]);

  const [switchState, setSwitchState] = useState({
    interval: true,
  });
  const handleSearchChange = (event) => {
    //console.log("event::::::::",event)
    const value = event.target.value.toLowerCase();
    //console.log("value::::::::",value)
    const filteredSuggestions = suggestions.filter((suggestion) =>
   {
    // console.log("suggestion::::::::",suggestion.orgName.toLowerCase().includes(value))
     return suggestion.orgName.toLowerCase().includes(value);
      //console.log("suggestionsssssss::::::::",suggestions)
    //  console.log("filteredSuggestions:::::::1111:",filteredSuggestions)
    }
    ); 
   // console.log("filteredSuggestions::::::2222::",filteredSuggestions)
    setSearchValue(event.target.value);
    setFilteredSuggestions(filteredSuggestions);
  };


  const handleSwitchChange = (event) => {
    setSwitchState({
      ...switchState,
      [event.target.name]: event.target.checked,
    });
  };

  const handleSuggestionClick = (suggestion) => {
    console.log(suggestion,"handleSuggestionClick suggestion")
    const newItem = {
      product_id: suggestion._id,
      name: suggestion.orgName,
    //  price: suggestion.price,
    //  discount: Number(suggestion.discount),
      option: "discount",
    };
    const customItem = {
      product_id: suggestion.product_id,
      discount: Number(suggestion.discount),
      option: "discount",
    };
    // setSelectedItem([...selectedItem, newItem]);
    setBuyList([...buyList, newItem]);
    setSearchValue("");
    setFilteredSuggestions([]);
    // buyList.map(eachItem => {
    //   onDiscountItemsChange
    // })
    // onDiscountItemsChange([...buyList]);
  
  };

  const handleDeleteItem = (id) => {
    const updatedItems = buyList.filter((item) => item.product_id !== id);
    // const updatedItems = selectedItem.filter((item) => item.product_id !== id)
    setBuyList(updatedItems);
    // setSelectedItem(updatedItems)
  //  onDiscountItemsChange(updatedItems);
  };

  const handleDiscountChangeMin = (event, index) => {
    console.log("buyListbuyListbuyListbuyListbuyList:",buyList)
    console.log("handleDiscountChangehandleDiscountChange:",event.target.value, index)
    const newSelectedItems = [...buyList];
    console.log("newSelectedItemsnewSelectedItems:",newSelectedItems)
    // const newSelectedItems = [...selectedItem];
    newSelectedItems[index].minDiscount = event.target.value;
    setBuyList(newSelectedItems);
    // setSelectedItem(newSelectedItems)
    onDiscountItemsChange(newSelectedItems);
  };
  const handleDiscountChangeMax = (event, index) => {
    console.log("buyListbuyListbuyListbuyListbuyList:",buyList)
    console.log("handleDiscountChangehandleDiscountChange:",event.target.value, index)
    const newSelectedItems = [...buyList];
    // const newSelectedItems = [...selectedItem];
    newSelectedItems[index].maxDiscount = event.target.value;
    setBuyList(newSelectedItems);
    // setSelectedItem(newSelectedItems)
    onDiscountItemsChange(newSelectedItems);
  };
  const handleOptionChange = (event, index, newOption) => {
    // const newSelectedItems = [...selectedItem];
    const newSelectedItems = [...buyList];
    newSelectedItems[index].option = newOption;
    setBuyList(newSelectedItems);
    // setSelectedItem(newSelectedItems)
    onDiscountItemsChange(newSelectedItems);
  };

  // console.log(newItem, "newItem");
  return (
    <div>
      <TextField
        label="Search Organisation..."
        value={searchValue}
        onChange={handleSearchChange}
        fullWidth
        variant="outlined"
      />
      {filteredSuggestions.length > 0 && (
        <Paper sx={{ mt: 1, maxHeight: 200, overflow: "auto" }}>
          <List>
            {filteredSuggestions.map((suggestion) => (
              <ListItem
                button
                key={suggestion.orgName}
                onClick={(event) => handleSuggestionClick(suggestion)}
              >
                <ListItemText
                  primary={suggestion.orgName}
                //  secondary={`Price: $${suggestion.price}`}
                />
              </ListItem>
            ))}
          </List>
        </Paper>
      )}
      {buyList.length > 0 && (
        <List>
          {buyList.map((item, index) => (
          <>{  console.log("item, index:::::",item, index)}
            <ListItem button key={`${item.name}`
            //-${item.price  }
          }>
              <ListItemAvatar>
                <Avatar src={ajwaSulthanhamat} />
              </ListItemAvatar>
              <ListItemText primary={item.name} //secondary={`$${item.price}`}
               />
              
              <FormControlLabel
                        control={
                          <YellowSwitch
                            checked={switchState.interval}
                            onChange={handleSwitchChange}
                            name="interval"
                          />
                        }
                        label="interval"
                      />
            {switchState.interval ? <> <TextField
                type="number"
                value={Number(item.minDiscount)}
                onChange={(event) => handleDiscountChangeMin(event, index)}
                label={ "min" }
                
                style={{
                  marginRight: "10px",
                }}
              />
              <TextField
                type="number"
                value={Number(item.maxDiscount)}
                onChange={(event) => handleDiscountChangeMax(event, index)}
                label={ "max" }
              />
              </> :  <TextField
                type="number"
                value={Number(item.discount)}
                onChange={(event) => handleDiscountChangeMax(event, index)}
                label={ "offer" }
              />}
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => handleDeleteItem(item.product_id)}
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
            </>
          ))}
        </List>
      )}
    </div>
  );
};

export default SearchOraganisation;
