import {
  Box,
  Button,
  Chip,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import FlexBetween from "../../components/FlexBetween";
import Header from "../../components/Header";
import AddIcon from "@mui/icons-material/Add";
import PublicIcon from "@mui/icons-material/Public";
import PublicOffIcon from "@mui/icons-material/PublicOff";
import DoneIcon from "@mui/icons-material/Done";
import WarningIcon from "@mui/icons-material/Warning";
import FilterOrganization from "../../components/FilterOrganization";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { DataGrid,GridToolbar } from "@mui/x-data-grid";
import { useTheme } from "@emotion/react";

const registrationCodes = () => {
  // const theme = useTheme();
  // const isNonMobile = useMediaQuery("(min-width:600px)");

  function getChipProps(params) {
    if (params.value === "Pending") {
      return {
        icon: <WarningIcon style={{ fill: "#D23030" }} />,
        label: params.value,
        style: {
          borderColor: "#D23030",
          color: "#D23030",
        },
      };
    } else {
      return {
        icon: <DoneIcon style={{ fill: "#66BB6A" }} />,
        label: params.value,
        style: {
          borderColor: "#66BB6A",
          color: "#66BB6A",
        },
      };
    }
  }

  function getModeProps(params) {
    if (params.value === "Online") {
      return {
        icon: <PublicIcon style={{ fill: "black" }} />,
        label: params.value,

        style: {
          backgroundColor: "#ffda85",
          color: "black",
        },
      };
    } else {
      return {
        icon: <PublicOffIcon style={{ fill: "black" }} />,
        label: params.value,
        style: {
          borderColor: "#2D68EC",
          color: "black",
        },
      };
    }
  }

  const handleClick = (event, cellValues) => {
    console.log(cellValues.row._id); //copying registration codes
  };

  const columns = [
    {
      field: "_id",
      headerName: "Registration Code",
      flex: 0.7,
      sortable: true,
    },
    {
      field: "date",
      headerName: "Date",
      flex: 0.5,
    },

    {
      field: "clientName",
      headerName: "Organization",
      flex: 0.5,
    },
    {
      field: "username",
      headerName: "User Name",
      flex: 0.5,
    },

    {
      field: "status",
      headerName: "Status",
      valueOptions: ["Verified", "Pending"],
      flex: 0.5,
      renderCell: (params) => {
        return (
          <Chip variant="outlined" size="small" {...getChipProps(params)} />
        );
      },
    },
    {
      flex: 0.1,
      renderCell: (cellValues) => {
        return (
          <Tooltip title="copy code">
            <ContentCopyIcon
              onClick={(event) => {
                handleClick(event, cellValues);
              }}
            />
          </Tooltip>
        );
      },
    },
  ];

  //   rows
  const rows = [
    {
      id: 1,
      _id: "63e026fd24cddc4076c1721e",
      clientName: "Johnas Peter",
      mode: "Online",
      status: "Verified",
      date: "12 Jan 2023 16:00",
      total: "QR 1200",
    },
    {
      id: 2,
      _id: "33a026fd24cddc4076c1721h",
      clientName: "Smith Peter",
      mode: "Online",
      status: "Verified",
      date: "12 Feb 2023 16:00",
      total: "QR 1200",
    },
    {
      id: 3,
      _id: "57e026fd24cddc40786c1721e",
      clientName: "Fatima Khalid",
      mode: "Online",
      status: "Pending",
      date: "22 Mar 2023 16:00",
      total: "QR 1200",
    },
    {
      id: 4,
      _id: "57e026fd24cddc40786c1721e",
      clientName: "Thahsin Shaik",
      mode: "Online",
      status: "Verified",
      date: "11 Mar 2023 16:00",
      total: "QR 1300",
    },
    {
      id: 5,
      _id: "57e026fd24cddc40786c1721e",
      clientName: "Yazed",
      mode: "Online",
      status: "Verified",
      date: "12 Mar 2023 16:00",
      total: "QR 1200",
    },
    {
      id: 6,
      _id: "54ve026fd24cddc40786c1721e",
      clientName: "Aysha Rashid",
      mode: "Online",
      status: "Pending",
      date: "20 Jun 2023 16:00",
      total: "QR 1200",
    },
    {
      id: 7,
      _id: "5we026fd24cddr40786c1721e",
      clientName: "Sara Yousuf",
      mode: "Online",
      status: "Pending",
      date: "12 Sep 2023 16:00",
      total: "QR 1200",
    },
    {
      id: 8,
      _id: "5we026fd24cddr40786c1721e",
      clientName: "Reem Nasar",
      mode: "Offline",
      date: "12 Oct 2023 16:00",
      status: "Verified",
      total: "QR 1200",
    },
    {
      id: 9,
      _id: "5we026fd24cddr40786c1721e",
      clientName: "Maraim Jaber",
      mode: "Offline",
      date: "12 Oct 2023 16:00",
      status: "Verified",
      total: "QR 1200",
    },
    {
      id: 10,
      _id: "5we026fd24cddr40786c1721e",
      clientName: "For You Saloon",
      mode: "Online",
      date: "03 Oct 2023 16:00",
      status: "Pending",
      total: "QR 1200",
    },
  ];

  return (
    <Box m="1.5rem 2.5rem">
      <FlexBetween gap="1.5rem">
        <Header title="Registration Codes" />
        <FlexBetween gap="1.5rem" alignItems="center">
          <Link to="/addregistrationcode" style={{ textDecoration: "none" }}>
            <Button
              variant="contained"
              // sx={{
              //   backgroundColor: theme.palette.secondary[400],
              //   color: theme.palette.primary[600],
              //   "&:hover": {
              //     backgroundColor: theme.palette.secondary[500],
              //     color: "#000000",
              //   },
              // }}
              endIcon={<AddIcon />}
            >
              Add Registrstion code
            </Button>
          </Link>
          
        </FlexBetween>
      </FlexBetween>

      <FilterOrganization />
      <Box
        mt="30px"
        height="75vh"
        width="100%"
        // sx={{
        //   "& .MuiDataGrid-root": {
        //     border: "none",
        //   },
        //   "& .MuiDataGrid-cell": {
        //     borderBottom: "none",
        //   },
        //   "& .MuiDataGrid-columnHeaders": {
        //     backgroundColor: theme.palette.background.alt,
        //     color: theme.palette.secondary[100],
        //     borderBottom: "none",
        //   },
        //   "& .MuiDataGrid-virtualScroller": {
        //     backgroundColor: theme.palette.primary.light,
        //   },
        //   "& .MuiDataGrid-footerContainer": {
        //     backgroundColor: theme.palette.background.alt,
        //     color: theme.palette.secondary[100],
        //     borderTop: "none",
        //   },
        //   "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
        //     color: `${theme.palette.secondary[200]} !important`,
        //   },

        //   "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
        // }}
      >
        {/* <DataGrid
          // loading={isLoading || !data}
          // getRowId={(row) => row._id}
          rows={rows}
          columns={columns}
          slots={{ toolbar: GridToolbar }} 
        /> */}
      </Box>
    </Box>
  );
};

export default registrationCodes;
