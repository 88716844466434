import React from "react";
import {
  Box,
  Card,
  CardActions,
  CardMedia,
  CardContent,
  Button,
  Typography,
  useTheme,
//   useMediaQuery,
  Chip,
} from "@mui/material";
import { setIsEditingOffer } from "../features/offers/editSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const OfferCard = ({ title, image, description, chip,validity,_id }) => {
  //console.log("title, image, description, chip,validity,_id :",title, image, description, chip,validity,_id )
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
//   const isNonMobile = useMediaQuery("(min-width: 1000px)");

const inputDate = new Date(validity);
const options = { 
  year: 'numeric', 
  month: 'long', 
  day: 'numeric' 
};
const handleClick = () => {
  dispatch(setIsEditingOffer(true));
};
const formattedDate = inputDate.toLocaleDateString('en-US', options);
  function getChipProps(chip) {
    if (chip === "Buy 1 Get 1") {
      return {
        label: chip,
        style: {
          borderColor: "#D23030",
          color: "#D23030",
        },
      };
    }
    if (chip === "Buy 2 Get 2") {
      return {
        label: chip,
        style: {
          borderColor: "#FBAD13",
          color: "#FBAD13",
        },
      };
    } else {
      return {
        label: chip,
        style: {
          borderColor: "#66BB6A",
          color: "#66BB6A",
        },
      };
    }
  }

  return (
    <Card sx={{ maxWidth: 345 }}>
           

      <CardMedia
        sx={{
          height: 220,
          backgroundImage: "none",
          backgroundColor: theme.palette.background.alt,
          borderRadius: "0.55rem",
         // objectFit:'contain'
        }}
        image={image}
        title="green iguana"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {title}
        </Typography>
        <Box m="10px 0px">
          <Chip variant="outlined" size="small" {...getChipProps(chip)} />
        </Box>

        <Typography variant="body2" color="text.secondary">
          {description}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Offer Valid Until: {formattedDate}
        </Typography>
      </CardContent>
      <CardActions>
        <Button
          variant="primary"
          size="small"
          sx={{
            backgroundColor: theme.palette.secondary[400],
            color: theme.palette.primary[600],
            "&:hover": {
              backgroundColor: theme.palette.secondary[500],
              color: "#000000",
            },
          }}
          onClick={() => {
            handleClick();
            navigate(`/addoffer/${_id}`);
          }}
        >
          Update Offer
        </Button>
      </CardActions>
    </Card>
  );
};

export default OfferCard;
