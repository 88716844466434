import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import {
  Box,
  useTheme,
  useMediaQuery,
  Button,
  TextField,
  MenuItem,
  Typography,
  Autocomplete,
  FormGroup,
  FormControl,
  FormLabel,
  FormControlLabel,
  styled,
  // InputAdornment,
} from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import FlexBetween from "../../components/FlexBetween";
import InputAdornment from "@mui/material/InputAdornment";
import { Formik } from "formik";
import * as yup from "yup";
import YellowSwitch from "../../components/YellowSwitch";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getLoading,
  saveNewMerchant,
  fetchALLMerchants,
  getAllMerchants,
} from "../../features/merchants/merchantSlice";
import ImgPreview from "../../components/ImgPreview";
import { LabelImportantTwoTone } from "@mui/icons-material";
import { fetchALLMerchantCategories, getAllMerchantCategories } from "../../features/merchants/merchantCategorySlice";

// import CountrySelect from "../../components/CountrySelect";

const AddMerchant = () => {
  const theme = useTheme();
  const allMerchants = useSelector(getAllMerchants());
  const allMerchantCategories = useSelector(getAllMerchantCategories);
  const isNonMobile = useMediaQuery("(min-width: 1000px)");
  const [countryValue, setCountryValue] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedLogo, setSelectedLogo] = useState([]);
  const [merchPubEmail, setmerchPubEmail] = useState([]);
  const [merchPvtEmail, setmerchPvtEmail] = useState([]);
  const [merchPubContact, setmerchPubContact] = useState([]);
  const [merchPvtContact, setmerchPvtContact] = useState([]);
  const [open, setOpen] = useState(false);
  const [switchState, setSwitchState] = useState({
    show: true,
    available: true,
    archive: true,
    bookingAvailable: true,
    loyaltyPoints: false,
  });
  const [merchSocialMedia, setMerchSocialMedia] = useState({
    merchFacebook: "",
    merchInstagram: "",
    merchOtherLinks: "",
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const apiStatus = useSelector(getLoading);

  useEffect(() => {
    dispatch(fetchALLMerchants());
    dispatch(fetchALLMerchantCategories());
  }, [dispatch]);

  const initialValues = {
    merchName: "Ajwa Sulthanmat",
    merchNameAr: "اجواء سلطانثنمات",
    countryValue: null,
    merchCategory: "",
    // merchPubEmail: "",
    // merchPvtEmail: "",
    merchWebsite: "",
    menu: null,
    merchBookingEmail: "",
    enterpriseRegistration: "",
    registerCommercial: "",
    merchGeoLat: "",
    merchGeoLng: "",
    merchWhatsappNum: "",
    merchWhatsappTitle: "",
    merchWhatsappPrefilllMsg: "",
    merchDescrption: "",
    merchDescrptionAr: "",
    merchLogo: null,
    merchBanner: null,
    merchBannerVideo: "",
    merchContract: "",
    minDiscount: 0,
    maxDiscount: 0,
  };

  const handleSwitchChange = (event) => {
    setSwitchState({
      ...switchState,
      [event.target.name]: event.target.checked,
    });
  };

  const handleSocialMedia = (event) => {
    setMerchSocialMedia({
      ...merchSocialMedia,
      [event.target.name]: event.target.value,
    });
  };

  function deleteImageHandler(image) {
    setSelectedFiles(selectedFiles.filter((e) => e !== image));
    URL.revokeObjectURL(image);
  }

  function deleteLogoHandler(image) {
    setSelectedLogo(selectedLogo.filter((e) => e !== image));
    URL.revokeObjectURL(image);
  }

  // console.log(merchSocialMedia);
  // console.log(switchState);
  // console.log(countryValue, 'country value');
  // console.log(merchPubEmail, "merchPubEmail");
  // console.log(merchPvtEmail, "merchPvtEmail");
  // console.log(merchPubContact, "merchPubContact");
  // console.log(merchPvtContact, "merchPvtContact");
  const createNewMerchant = (data) => {
    dispatch(saveNewMerchant(data))
      .unwrap()
      .then(() => {
        // navigate("/");
      });
  };

  function handlemerchPubEmail(e) {
    if ((e.charCode || e.keyCode) === 13) {
      e.preventDefault();
    }
    if (e.key !== "Enter") return;
    const value = e.target.value;
    if (!value.trim()) return;
    setmerchPubEmail([...merchPubEmail, value]);
    e.target.value = "";
    return { merchPubEmail, merchPvtEmail };
  }
  function removePubEmail(index) {
    setmerchPubEmail(merchPubEmail.filter((el, i) => i !== index));
    return merchPubEmail;
  }

  function handlemerchPvtEmail(e) {
    if ((e.charCode || e.keyCode) === 13) {
      e.preventDefault();
    }
    if (e.key !== "Enter") return;
    const value = e.target.value;
    if (!value.trim()) return;
    setmerchPvtEmail([...merchPvtEmail, value]);
    e.target.value = "";
    return merchPvtEmail;
  }
  function removePvtEmail(index) {
    setmerchPvtEmail(merchPvtEmail.filter((el, i) => i !== index));
    return merchPvtEmail;
  }

  function handlemerchPvtContact(e) {
    if ((e.charCode || e.keyCode) === 13) {
      e.preventDefault();
    }
    if (e.key !== "Enter") return;
    const value = e.target.value;
    if (!value.trim()) return;
    setmerchPvtContact([...merchPvtContact, value]);
    e.target.value = "";
    return merchPvtContact;
  }
  function removePvtContact(index) {
    setmerchPvtContact(merchPvtContact.filter((el, i) => i !== index));
    return merchPvtContact;
  }

  function handlemerchPubContact(e) {
    if ((e.charCode || e.keyCode) === 13) {
      e.preventDefault();
    }
    if (e.key !== "Enter") return;
    const value = e.target.value;
    if (!value.trim()) return;
    setmerchPubContact([...merchPubContact, value]);
    e.target.value = "";
    return merchPubContact;
  }
  function removePubContact(index) {
    setmerchPubContact(merchPubContact.filter((el, i) => i !== index));
    return merchPubContact;
  }

  const handleFormSubmit = (values) => {
    // console.log(switchState);
    console.log("Merchant Form", values);
    values.countryValue = countryValue;
    values.available = switchState.available;
    values.show = switchState.show;
    values.bookingAvailable = switchState.bookingAvailable;
    values.loyaltyPoints = switchState.loyaltyPoints;
    values.archive = switchState.archive;
    values.merchSocialMedia = merchSocialMedia;

    var formData = new FormData();
    formData.append("type", "branch");
    for (const key of Object.keys(values.merchBanner)) {
      // console.log("Bannerarry : " + values.merchBanner[key]);
      formData.append("merchBanner", values.merchBanner[key]);
    }
    for (const key of Object.keys(values.merchLogo)) {
      formData.append("merchLogo", values.merchLogo[key]);
    }

    merchPubEmail.map((pubEmail) => formData.append("merchPubEmail", pubEmail));
    merchPvtEmail.map((pvtEmail) => formData.append("merchPvtEmail", pvtEmail));
    merchPubContact.map((pubContact) =>
      formData.append("merchPubContact", pubContact)
    );
    merchPvtContact.map((pvtContact) =>
      formData.append("merchPvtContact", pvtContact)
    );
    formData.append("menu", values.menu);
    formData.append("merchBannerVideo", values.merchBannerVideo);
    formData.append("countryValue", values.countryValue);
    formData.append("merchName", values.merchName);
    formData.append("merchNameAr", values.merchNameAr);
    formData.append("merchCategory", values.merchCategory);
    formData.append("merchBookingEmail", values.merchBookingEmail);
    formData.append("merchCity", values.merchCity);
    formData.append("merchStreet", values.merchStreet);
    formData.append("merchState", values.merchState);
    formData.append("merchWebsite", values.merchWebsite);
    formData.append("merchGeoLat", values.merchGeoLat);
    formData.append("maxDiscount", values.maxDiscount);
    formData.append("minDiscount", values.minDiscount);
    formData.append("merchGeoLng", values.merchGeoLng);
    formData.append("merchWhatsappNum", values.merchWhatsappNum);
    formData.append("merchWhatsappTitle", values.merchWhatsappTitle);
    formData.append(
      "merchWhatsappPrefilllMsg",
      values.merchWhatsappPrefilllMsg
    );
    formData.append("enterpriseRegistration", values.enterpriseRegistration);
    formData.append("registerCommercial", values.registerCommercial);

    formData.append("merchContract", values.merchContract);
    formData.append("merchDescrption", values.merchDescrption);
    formData.append("merchDescrptionAr", values.merchDescrptionAr);
    formData.append("archive", switchState.archive);
    formData.append("show", switchState.show);
    formData.append("loyaltyPoints", switchState.loyaltyPoints);
    formData.append("available", switchState.available);
    formData.append("bookingAvailable", switchState.bookingAvailable);
    // formData.append("merchSocialMedia", merchSocialMedia);

    createNewMerchant(formData);
    console.log("xalues from form data", formData);
  };

  const phoneRegExp =
    /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

  const emailRegExp =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  // const latRegExp =
  //   /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$/;

  // const lngRegExp =
  //   /^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,6})?))$/;
  const validFileExtensions = {
    file: ["jpg", "gif", "png", "jpeg", "svg", "webp"],
  };

  const validPdfFileExtensions = {
    file: ["pdf"],
  };

  const validVideoFileExtensions = {
    file: [".WEBM", ".OGG", ".MP3", ".MP4"],
  };

  function isValidFileType(fileName, fileType) {
    return (
      fileName &&
      validFileExtensions[fileType].indexOf(fileName.split(".").pop()) > -1
    );
  }

  function isValidPdf(fileName, fileType) {
    return (
      fileName &&
      validPdfFileExtensions[fileType].indexOf(fileName.split(".").pop()) > -1
    );
  }

  function isValidVideo(fileName, fileType) {
    return (
      fileName &&
      validVideoFileExtensions[fileType].indexOf(fileName.split(".").pop()) > -1
    );
  }
  const addMerchantSchema = yup.object().shape({
    merchName: yup.string().required("required"),
    merchNameAr: yup.string().required("required"),
    merchAddress: yup.string().required("required"),
    countryValue: yup.string().required("country required"),
    merchCategory: yup.string().required("required"),
    merchGeoLat: yup
      .number()
      // .matches(latRegExp, "Include decimals")
      .required("required"),
    merchGeoLng: yup
      .number()
      // .matches(lngRegExp, "Include decimals")
      .required("required"),
    // merchLogo1: yup
    //   .mixed()
    //   .required()
    //   .test("is-valid-type", "Not a valid image type", (value) =>
    //     isValidFileType(value && value.name.toLowerCase(), "file")
    //   ),

    // merchBanner2: yup
    //   .mixed()
    //   .required()
    //   .test("is-valid-type", "Not a valid image type", (value) =>
    //     isValidFileType(value && value.name.toLowerCase(), "file")
    //   ),
    // merchContract: yup
    //   .mixed()
    //   .required("Merchant Contract is Requried")
    //   .test("is-valid-type", "Not a valid file type", (value) =>
    //     isValidPdf(value && value.name.toLowerCase(), "file")
    //   ),
    // enterpriseRegistration: yup
    //   .mixed()
    //   .required("Merchant Contract is Requried")
    //   .test("is-valid-type", "Not a valid file type", (value) =>
    //     isValidPdf(value && value.name.toLowerCase(), "file")
    //   ),
    // registerCommercial: yup
    //   .mixed()
    //   .required("Merchant Contract is Requried")
    //   .test("is-valid-type", "Not a valid file type", (value) =>
    //     isValidPdf(value && value.name.toLowerCase(), "file")
    //   ),
    // merchBannerVideo: yup
    //   .mixed()
    //   .test("is-valid-type", "Not a valid video type", (value) =>
    //     isValidVideo(value && value.name.toLowerCase(), "file")
    //   ),
    // merchWhatsappNum: yup
    //   .string()
    //   .matches(phoneRegExp, "WhatsApp number is not valid")
    //   .required("required"),

    merchWhatsappTitle: yup.string().required("required"),
    merchWhatsappPrefilllMsg: yup.string().required("required"),
    merchDescrption: yup.string().required("required"),
    merchDescrptionAr: yup.string().required("required"),

    merchFacebook: yup.string().required("required"),
    merchInstagram: yup.string().required("required"),
  });

  return (
    <Box m="1.5rem 2.5rem">
      <FlexBetween gap="1.5rem">
        <Header title="Join Us" subtitle="and increase your sales" />
      </FlexBetween>

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={addMerchantSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                value={values.parentMerchant}
                select // tell TextField to render select
                label="Select Merchant"
                onBlur={handleBlur}
                onChange={handleChange}
                name="parentMerchant"
                defaultValue=""
                error={!!touched.parentMerchant && !!errors.parentMerchant}
                helperText={touched.parentMerchant && errors.parentMerchant}
                sx={{ gridColumn: "span 4" }}
              >
                {allMerchants.map((merchant) => (
                  <MenuItem key={merchant._id} value={merchant._id}>
                    {merchant.merchName}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Branch Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.merchName}
                name="merchName"
                error={!!touched.merchName && !!errors.merchName}
                helperText={touched.merchName && errors.merchName}
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Branch Name (in arabic)"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.merchNameAr}
                name="merchNameAr"
                error={!!touched.merchNameAr && !!errors.merchNameAr}
                helperText={touched.merchNameAr && errors.merchNameAr}
                sx={{ gridColumn: "span 2" }}
              />

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gridColumn: "span 4",
                }}
              >
                <TagsContainer>
                  {merchPubEmail.map((merchPubEmail, index) => (
                    <TagItem key={index}>
                      <TagText>{merchPubEmail}</TagText>
                      <TagClose
                        className="close"
                        onClick={() => removePubEmail(index)}
                      >
                        &times;
                      </TagClose>
                    </TagItem>
                  ))}
                  <TagInput
                    onKeyDown={handlemerchPubEmail}
                    type="text"
                    className="tags-input"
                    placeholder="Enter Public Emails"
                    key="pubEmails"
                  />
                  {/* <button onClick={test()}> okaay </button> */}
                </TagsContainer>
                <TagsContainer>
                  {merchPvtEmail.map((merchPvtEmail, index) => (
                    <TagItem key={index}>
                      <TagText>{merchPvtEmail}</TagText>
                      <TagClose
                        className="close"
                        onClick={() => removePvtEmail(index)}
                      >
                        &times;
                      </TagClose>
                    </TagItem>
                  ))}
                  <TagInput
                    onKeyDown={handlemerchPvtEmail}
                    type="text"
                    className="tags-input"
                    placeholder="Enter Private Emails"
                    key="pvtEmails"
                  />
                  {/* <button onClick={test()}> okaay </button> */}
                </TagsContainer>
              </Box>

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Email for Booking"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.merchBookingEmail}
                name="merchBookingEmail"
                error={
                  !!touched.merchBookingEmail && !!errors.merchBookingEmail
                }
                helperText={
                  touched.merchBookingEmail && errors.merchBookingEmail
                }
                sx={{ gridColumn: "span 4" }}
              />

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gridColumn: "span 4",
                }}
              >
                <TagsContainer>
                  {merchPvtContact.map((merchPvtContact, index) => (
                    <TagItem key={index}>
                      <TagText>{merchPvtContact}</TagText>
                      <TagClose
                        className="close"
                        onClick={() => removePvtContact(index)}
                      >
                        &times;
                      </TagClose>
                    </TagItem>
                  ))}
                  <TagInput
                    onKeyDown={handlemerchPvtContact}
                    type="text"
                    className="tags-input"
                    placeholder="Contact Number (Sales & Marketing)"
                  />
                  {/* <button onClick={test()}> okaay </button> */}
                </TagsContainer>
                <TagsContainer>
                  {merchPubContact.map((merchPubContact, index) => (
                    <TagItem key={index}>
                      <TagText>{merchPubContact}</TagText>
                      <TagClose
                        className="close"
                        onClick={() => removePubContact(index)}
                      >
                        &times;
                      </TagClose>
                    </TagItem>
                  ))}
                  <TagInput
                    onKeyDown={handlemerchPubContact}
                    type="text"
                    className="tags-input"
                    placeholder="Contact Number (Customer Service)"
                  />
                  {/* <button onClick={test()}> okaay </button> */}
                </TagsContainer>
              </Box>

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Geo Latititude"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.merchGeoLat}
                name="merchGeoLat"
                error={!!touched.merchGeoLat && !!errors.merchGeoLat}
                helperText={touched.merchGeoLat && errors.merchGeoLat}
                sx={{ gridColumn: "span 1" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="number"
                label="Geo Longitude"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.merchGeoLng}
                name="merchGeoLng"
                error={!!touched.merchGeoLng && !!errors.merchGeoLng}
                helperText={touched.merchGeoLng && errors.merchGeoLng}
                sx={{ gridColumn: "span 1" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="City"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.merchCity}
                name="merchCity"
                error={!!touched.merchCity && !!errors.merchCity}
                helperText={touched.merchCity && errors.merchCity}
                sx={{ gridColumn: "span 1" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Street"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.merchStreet}
                name="merchStreet"
                error={!!touched.merchStreet && !!errors.merchStreet}
                helperText={touched.merchStreet && errors.merchStreet}
                sx={{ gridColumn: "span 1" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="State"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.merchState}
                name="merchState"
                error={!!touched.merchState && !!errors.merchState}
                helperText={touched.merchState && errors.merchState}
                sx={{ gridColumn: "span 2" }}
              />

              <Autocomplete
                id="country-select"
                value={countryValue}
                onChange={(event, newValue) => {
                  setCountryValue(newValue);
                }}
                sx={{ gridColumn: "span 2" }}
                options={countries}
                autoHighlight
                defaultValue={{
                  code: "QA",
                  label: "Qatar",
                  phone: "+974",
                  suggested: true,
                }}
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                getOptionLabel={(option) => option.label}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    //   sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    display="grid"
                    gap="30px"
                    gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                    sx={{
                      "& > img": { mr: 2, flexShrink: 0 },
                      "& > div": {
                        gridColumn: isNonMobile ? undefined : "span 4",
                      },
                    }}
                    {...props}
                  >
                    <img
                      loading="lazy"
                      width="20"
                      src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                      srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                      alt=""
                    />
                    {option.label} ({option.code}) +{option.phone}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="filled"
                    label="Choose a country"
                    error={!!touched.countryValue && !!errors.countryValue}
                    helperText={touched.countryValue && errors.countryValue}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: values.countryValue ? (
                        <InputAdornment
                          position="start"
                          onClick={() => {
                            setOpen(true);
                          }}
                          style={{
                            marginTop: "0px",
                          }}
                        >
                          <img
                            loading="lazy"
                            width="20"
                            margin="-1rem"
                            src={`https://flagcdn.com/w20/${values.countryValue.code.toLowerCase()}.png`}
                            srcSet={`https://flagcdn.com/w40/${values.countryValue.code.toLowerCase()}.png 2x`}
                            alt=""
                          />
                        </InputAdornment>
                      ) : null,
                    }}
                  />
                )}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="WhatsApp Number"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.merchWhatsappNum}
                name="merchWhatsappNum"
                error={!!touched.merchWhatsappNum && !!errors.merchWhatsappNum}
                helperText={touched.merchWhatsappNum && errors.merchWhatsappNum}
                sx={{ gridColumn: "span 1" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="WhatsApp Title"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.merchWhatsappTitle}
                name="merchWhatsappTitle"
                error={
                  !!touched.merchWhatsappTitle && !!errors.merchWhatsappTitle
                }
                helperText={
                  touched.merchWhatsappTitle && errors.merchWhatsappTitle
                }
                sx={{ gridColumn: "span 1" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="WhatsApp PreFill Message"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.merchWhatsappPrefilllMsg}
                name="merchWhatsappPrefilllMsg"
                error={
                  !!touched.merchWhatsappPrefilllMsg &&
                  !!errors.merchWhatsappPrefilllMsg
                }
                helperText={
                  touched.merchWhatsappPrefilllMsg &&
                  errors.merchWhatsappPrefilllMsg
                }
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="filled"
                type="number"
                label="Enter the Minimum Discount"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.minDiscount}
                name="minDiscount"
                error={!!touched.minDiscount && !!errors.minDiscount}
                helperText="If you provide discount else keep this feild empty"
                sx={{ gridColumn: "span 1" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="number"
                label="Enter the Max Discount"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.maxDiscount}
                name="maxDiscount"
                error={!!touched.maxDiscount && !!errors.maxDiscount}
                helperText="If you provide discount else keep this feild empty"
                sx={{ gridColumn: "span 1" }}
              />

              <TextField
                fullWidth
                variant="filled"
                select
                type="text"
                label="Category"
                onBlur={handleBlur}
                onChange={(e) => {
                  handleChange(e);
                }}
                value={values.merchCategory}
                name="merchCategory"
                error={!!touched.merchCategory && !!errors.merchCategory}
                helperText={touched.merchCategory && errors.merchCategory}
                sx={{ gridColumn: "span 2" }}
              >
                {allMerchantCategories?.map((merchantCat) => (
                  <MenuItem key={merchantCat._id} value={merchantCat._id}>
                    {merchantCat.categoryName}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Describe your Brand"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.merchDescrption}
                multiline
                rows={5}
                name="merchDescrption"
                error={!!touched.merchDescrption && !!errors.merchDescrption}
                helperText={touched.merchDescrption && errors.merchDescrption}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Describe your Brand (arabic)"
                onBlur={handleBlur}
                onChange={handleChange}
                multiline
                rows={5}
                value={values.productDescriptionAr}
                name="merchDescrptionAr"
                error={
                  !!touched.merchDescrptionAr && !!errors.merchDescrptionAr
                }
                helperText={
                  touched.merchDescrptionAr && errors.merchDescrptionAr
                }
                sx={{ gridColumn: "span 2" }}
              />
              <Box sx={{ gridColumn: "span 4" }}>
                <Typography variant="h5">Social Media Links</Typography>
              </Box>
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Instagram"
                onBlur={handleBlur}
                onChange={(e) => {
                  // handleChange();
                  handleSocialMedia(e);
                }}
                // value={values.merchInstagram}
                name="merchInstagram"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <InstagramIcon />
                    </InputAdornment>
                  ),
                }}
                error={!!touched.merchInstagram && !!errors.merchInstagram}
                helperText={touched.merchInstagram && errors.merchInstagram}
                sx={{ gridColumn: "span 1" }}
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Facebook"
                onBlur={handleBlur}
                onChange={(e) => {
                  // handleChange();
                  handleSocialMedia(e);
                }}
                // value={values.merchFacebook}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FacebookIcon />
                    </InputAdornment>
                  ),
                }}
                name="merchFacebook"
                error={!!touched.merchFacebook && !!errors.merchFacebook}
                helperText={touched.merchFacebook && errors.merchFacebook}
                sx={{ gridColumn: "span 1" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Website"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.merchWebsite}
                name="merchWebsite"
                error={!!touched.merchWebsite && !!errors.merchWebsite}
                helperText={touched.merchWebsite && errors.merchWebsite}
                sx={{ gridColumn: "span 1" }}
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Other Social Media Links"
                onBlur={handleBlur}
                onChange={(e) => {
                  // handleChange();
                  handleSocialMedia(e);
                }}
                name="merchOtherLinks"
                error={!!touched.merchOtherLinks && !!errors.merchOtherLinks}
                helperText={touched.merchOtherLinks && errors.merchOtherLinks}
                sx={{ gridColumn: "span 1" }}
              />
              <Box sx={{ gridColumn: "span 4" }}>
                <Typography variant="h5">Upload Brand Logos</Typography>
              </Box>

              <Box
                sx={{
                  gridColumn: "span 1",
                  borderBottom: "1px solid white",
                  marginTop: "-20px",
                  paddingBottom: "-10px",
                }}
              >
                {" "}
                <input
                  type="file"
                  multiple
                  onChange={(event) => {
                    setFieldValue("merchLogo", event.currentTarget.files);
                    if (event.target.files) {
                      const filesArray = Array.from(event.target.files).map(
                        (file) => URL.createObjectURL(file)
                      );

                      setSelectedLogo((prevImages) =>
                        prevImages.concat(filesArray)
                      );
                      Array.from(event.target.files).map(
                        (file) => URL.revokeObjectURL(file) // avoid memory leak
                      );
                    }
                  }}
                />
                {selectedLogo != [] && (
                  <ImgPreview
                    source={selectedLogo}
                    deleteImageHandler={deleteLogoHandler}
                  />
                )}
              </Box>

              <Box sx={{ gridColumn: "span 4" }}>
                <Typography variant="h5">Upload Brand Banners X 2</Typography>
              </Box>
              <Box
                sx={{
                  gridColumn: "span 1",
                  borderBottom: "1px solid white",
                  marginTop: "-20px",
                  paddingBottom: "-10px",
                }}
              >
                {" "}
                <input
                  type="file"
                  multiple
                  onChange={(event) => {
                    setFieldValue("merchBanner", event.currentTarget.files);
                    if (event.target.files) {
                      const filesArray = Array.from(event.target.files).map(
                        (file) => URL.createObjectURL(file)
                      );

                      setSelectedFiles((prevImages) =>
                        prevImages.concat(filesArray)
                      );
                      Array.from(event.target.files).map(
                        (file) => URL.revokeObjectURL(file) // avoid memory leak
                      );
                    }
                  }}
                />
                {selectedFiles != [] && (
                  <ImgPreview
                    source={selectedFiles}
                    deleteImageHandler={deleteImageHandler}
                  />
                )}
              </Box>

              <Box sx={{ gridColumn: "span 4" }}>
                <Typography variant="h5">Upload Brand Banner Video</Typography>
              </Box>
              <TextField
                fullWidth
                variant="standard"
                type="file"
                onBlur={handleBlur}
                name="merchBannerVideo"
                // write a function for handle change when uplaoding a picture for the product with e.currentTarget.files[0] use state
                onChange={(event) => {
                  setFieldValue(
                    "merchBannerVideo",
                    event.currentTarget.files[0]
                  );
                }}
                error={!!touched.merchBannerVideo && !!errors.merchBannerVideo}
                helperText={touched.merchBannerVideo && errors.merchBannerVideo}
                sx={{
                  textAlign: "center",
                  gridColumn: "span 4",
                }}
              />
              <Box sx={{ gridColumn: "span 4" }}>
                <Typography variant="h5">Upload Menu (Restauarnt)</Typography>
              </Box>
              <TextField
                fullWidth
                variant="standard"
                type="file"
                onBlur={handleBlur}
                name="menu"
                // write a function for handle change when uplaoding a picture for the product with e.currentTarget.files[0] use state
                onChange={(event) => {
                  setFieldValue("menu", event.currentTarget.files[0]);
                }}
                error={!!touched.menu && !!errors.menu}
                helperText="pdf"
                sx={{
                  textAlign: "center",
                  gridColumn: "span 4",
                }}
              />
              <Box sx={{ gridColumn: "span 4" }}>
                <Typography variant="h5">Upload Merchant Docs</Typography>
              </Box>
              <TextField
                fullWidth
                variant="standard"
                type="file"
                onBlur={handleBlur}
                onChange={(event) => {
                  setFieldValue(
                    "enterpriseRegistration",
                    event.currentTarget.files[0]
                  );
                }}
                error={
                  !!touched.enterpriseRegistration &&
                  !!errors.enterpriseRegistration
                }
                helperText="Upload Commercial Registration Contract (pdf)"
                // helperText={touched.merchLogo && errors.merchLogo}
                sx={{
                  textAlign: "center",
                  gridColumn: "span 2",
                }}
              />
              <TextField
                fullWidth
                variant="standard"
                type="file"
                onBlur={handleBlur}
                name="merchContract"
                onChange={(event) => {
                  setFieldValue("merchContract", event.currentTarget.files[0]);
                }}
                error={!!touched.merchContract && !!errors.merchContract}
                helperText="Upload Merchandise Contract (pdf)"
                sx={{
                  textAlign: "center",
                  gridColumn: "span 2",
                  zIndex: 2,
                }}
              />
              <TextField
                fullWidth
                variant="standard"
                type="file"
                onBlur={handleBlur}
                // write a function for handle change when uplaoding a picture for the product with e.currentTarget.files[0] use state
                onChange={(event) => {
                  setFieldValue(
                    "registerCommercial",
                    event.currentTarget.files[0]
                  );
                }}
                error={
                  !!touched.registerCommercial && !!errors.registerCommercial
                }
                helperText="Upload Enterprise Licience (pdf)"
                sx={{
                  textAlign: "center",
                  gridColumn: "span 2",
                }}
              />

              <Box
                sx={{
                  textAlign: "center",
                  gridColumn: "span 4",
                }}
              >
                <FormControl component="fieldset" variant="standard">
                  <FormLabel
                    sx={{
                      color: theme.palette.secondary[500],
                    }}
                    component="legend"
                  >
                    Actions
                  </FormLabel>
                  <FormGroup
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      gap: "1.3rem",
                    }}
                  >
                    <FormControlLabel
                      control={
                        <YellowSwitch
                          checked={switchState.show}
                          onChange={handleSwitchChange}
                          name="show"
                        />
                      }
                      label="Show / Hide"
                    />
                    <FormControlLabel
                      control={
                        <YellowSwitch
                          checked={switchState.archive}
                          onChange={handleSwitchChange}
                          name="archive"
                        />
                      }
                      label="archive"
                    />
                    <FormControlLabel
                      control={
                        <YellowSwitch
                          checked={switchState.available}
                          onChange={handleSwitchChange}
                          name="available"
                        />
                      }
                      label="Available"
                    />
                    <FormControlLabel
                      control={
                        <YellowSwitch
                          checked={switchState.bookingAvailable}
                          onChange={handleSwitchChange}
                          name="bookingAvailable"
                        />
                      }
                      label="Booking Available"
                    />
                    <FormControlLabel
                      control={
                        <YellowSwitch
                          checked={switchState.loyaltyPoints}
                          onChange={handleSwitchChange}
                          name="loyaltyPoints"
                        />
                      }
                      label="Loyalty Points"
                    />
                  </FormGroup>
                </FormControl>
              </Box>
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                type="submit"
                color="secondary"
                variant="contained"
                onClick={() => handleFormSubmit(values)}
              >
                Add New Branch
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default AddMerchant;

const countries = [
  { code: "AD", label: "Andorra" },
  {
    code: "AE",
    label: "United Arab Emirates",
    phone: "971",
  },
  { code: "AF", label: "Afghanistan", phone: "93" },
  {
    code: "AG",
    label: "Antigua and Barbuda",
    phone: "1-268",
  },
  { code: "AI", label: "Anguilla", phone: "1-264" },
  { code: "AL", label: "Albania", phone: "355" },
  { code: "AM", label: "Armenia", phone: "374" },
  { code: "AO", label: "Angola", phone: "244" },
  { code: "AQ", label: "Antarctica", phone: "672" },
  { code: "AR", label: "Argentina", phone: "54" },
  { code: "AS", label: "American Samoa", phone: "1-684" },
  { code: "AT", label: "Austria", phone: "43" },
  {
    code: "AU",
    label: "Australia",
    phone: "61",
    suggested: true,
  },
  { code: "AW", label: "Aruba", phone: "297" },
  { code: "AX", label: "Alland Islands", phone: "358" },
  { code: "AZ", label: "Azerbaijan", phone: "994" },
  {
    code: "BA",
    label: "Bosnia and Herzegovina",
    phone: "387",
  },
  { code: "BB", label: "Barbados", phone: "1-246" },
  { code: "BD", label: "Bangladesh", phone: "880" },
  { code: "BE", label: "Belgium", phone: "32" },
  { code: "BF", label: "Burkina Faso", phone: "226" },
  { code: "BG", label: "Bulgaria", phone: "359" },
  { code: "BH", label: "Bahrain", phone: "973" },
  { code: "BI", label: "Burundi", phone: "257" },
  { code: "BJ", label: "Benin", phone: "229" },
  { code: "BL", label: "Saint Barthelemy", phone: "590" },
  { code: "BM", label: "Bermuda", phone: "1-441" },
  { code: "BN", label: "Brunei Darussalam", phone: "673" },
  { code: "BO", label: "Bolivia", phone: "591" },
  { code: "BR", label: "Brazil", phone: "55" },
  { code: "BS", label: "Bahamas", phone: "1-242" },
  { code: "BT", label: "Bhutan", phone: "975" },
  { code: "BV", label: "Bouvet Island", phone: "47" },
  { code: "BW", label: "Botswana", phone: "267" },
  { code: "BY", label: "Belarus", phone: "375" },
  { code: "BZ", label: "Belize", phone: "501" },
  {
    code: "CA",
    label: "Canada",
    phone: "1",
    suggested: true,
  },
  {
    code: "CC",
    label: "Cocos (Keeling) Islands",
    phone: "61",
  },
  {
    code: "CD",
    label: "Congo, Democratic Republic of the",
    phone: "243",
  },
  {
    code: "CF",
    label: "Central African Republic",
    phone: "236",
  },
  {
    code: "CG",
    label: "Congo, Republic of the",
    phone: "242",
  },
  { code: "CH", label: "Switzerland", phone: "41" },
  { code: "CI", label: "Cote d'Ivoire", phone: "225" },
  { code: "CK", label: "Cook Islands", phone: "682" },
  { code: "CL", label: "Chile", phone: "56" },
  { code: "CM", label: "Cameroon", phone: "237" },
  { code: "CN", label: "China", phone: "86" },
  { code: "CO", label: "Colombia", phone: "57" },
  { code: "CR", label: "Costa Rica", phone: "506" },
  { code: "CU", label: "Cuba", phone: "53" },
  { code: "CV", label: "Cape Verde", phone: "238" },
  { code: "CW", label: "Curacao", phone: "599" },
  { code: "CX", label: "Christmas Island", phone: "61" },
  { code: "CY", label: "Cyprus", phone: "357" },
  { code: "CZ", label: "Czech Republic", phone: "420" },
  {
    code: "DE",
    label: "Germany",
    phone: "49",
    suggested: true,
  },
  { code: "DJ", label: "Djibouti", phone: "253" },
  { code: "DK", label: "Denmark", phone: "45" },
  { code: "DM", label: "Dominica", phone: "1-767" },
  {
    code: "DO",
    label: "Dominican Republic",
    phone: "1-809",
  },
  { code: "DZ", label: "Algeria", phone: "213" },
  { code: "EC", label: "Ecuador", phone: "593" },
  { code: "EE", label: "Estonia", phone: "372" },
  { code: "EG", label: "Egypt", phone: "20" },
  { code: "EH", label: "Western Sahara", phone: "212" },
  { code: "ER", label: "Eritrea", phone: "291" },
  { code: "ES", label: "Spain", phone: "34" },
  { code: "ET", label: "Ethiopia", phone: "251" },
  { code: "FI", label: "Finland", phone: "358" },
  { code: "FJ", label: "Fiji", phone: "679" },
  {
    code: "FK",
    label: "Falkland Islands (Malvinas)",
    phone: "500",
  },
  {
    code: "FM",
    label: "Micronesia, Federated States of",
    phone: "691",
  },
  { code: "FO", label: "Faroe Islands", phone: "298" },
  {
    code: "FR",
    label: "France",
    phone: "33",
    suggested: true,
  },
  { code: "GA", label: "Gabon", phone: "241" },
  { code: "GB", label: "United Kingdom", phone: "44" },
  { code: "GD", label: "Grenada", phone: "1-473" },
  { code: "GE", label: "Georgia", phone: "995" },
  { code: "GF", label: "French Guiana", phone: "594" },
  { code: "GG", label: "Guernsey", phone: "44" },
  { code: "GH", label: "Ghana", phone: "233" },
  { code: "GI", label: "Gibraltar", phone: "350" },
  { code: "GL", label: "Greenland", phone: "299" },
  { code: "GM", label: "Gambia", phone: "220" },
  { code: "GN", label: "Guinea", phone: "224" },
  { code: "GP", label: "Guadeloupe", phone: "590" },
  { code: "GQ", label: "Equatorial Guinea", phone: "240" },
  { code: "GR", label: "Greece", phone: "30" },
  {
    code: "GS",
    label: "South Georgia and the South Sandwich Islands",
    phone: "500",
  },
  { code: "GT", label: "Guatemala", phone: "502" },
  { code: "GU", label: "Guam", phone: "1-671" },
  { code: "GW", label: "Guinea-Bissau", phone: "245" },
  { code: "GY", label: "Guyana", phone: "592" },
  { code: "HK", label: "Hong Kong", phone: "852" },
  {
    code: "HM",
    label: "Heard Island and McDonald Islands",
    phone: "672",
  },
  { code: "HN", label: "Honduras", phone: "504" },
  { code: "HR", label: "Croatia", phone: "385" },
  { code: "HT", label: "Haiti", phone: "509" },
  { code: "HU", label: "Hungary", phone: "36" },
  { code: "ID", label: "Indonesia", phone: "62" },
  { code: "IE", label: "Ireland", phone: "353" },
  { code: "IL", label: "Israel", phone: "972" },
  { code: "IM", label: "Isle of Man", phone: "44" },
  { code: "IN", label: "India", phone: "91" },
  {
    code: "IO",
    label: "British Indian Ocean Territory",
    phone: "246",
  },
  { code: "IQ", label: "Iraq", phone: "964" },
  {
    code: "IR",
    label: "Iran, Islamic Republic of",
    phone: "98",
  },
  { code: "IS", label: "Iceland", phone: "354" },
  { code: "IT", label: "Italy", phone: "39" },
  { code: "JE", label: "Jersey", phone: "44" },
  { code: "JM", label: "Jamaica", phone: "1-876" },
  { code: "JO", label: "Jordan", phone: "962" },
  {
    code: "JP",
    label: "Japan",
    phone: "81",
    suggested: true,
  },
  { code: "KE", label: "Kenya", phone: "254" },
  { code: "KG", label: "Kyrgyzstan", phone: "996" },
  { code: "KH", label: "Cambodia", phone: "855" },
  { code: "KI", label: "Kiribati", phone: "686" },
  { code: "KM", label: "Comoros", phone: "269" },
  {
    code: "KN",
    label: "Saint Kitts and Nevis",
    phone: "1-869",
  },
  {
    code: "KP",
    label: "Korea, Democratic People's Republic of",
    phone: "850",
  },
  { code: "KR", label: "Korea, Republic of", phone: "82" },
  { code: "KW", label: "Kuwait", phone: "965" },
  { code: "KY", label: "Cayman Islands", phone: "1-345" },
  { code: "KZ", label: "Kazakhstan", phone: "7" },
  {
    code: "LA",
    label: "Lao People's Democratic Republic",
    phone: "856",
  },
  { code: "LB", label: "Lebanon", phone: "961" },
  { code: "LC", label: "Saint Lucia", phone: "1-758" },
  { code: "LI", label: "Liechtenstein", phone: "423" },
  { code: "LK", label: "Sri Lanka", phone: "94" },
  { code: "LR", label: "Liberia", phone: "231" },
  { code: "LS", label: "Lesotho", phone: "266" },
  { code: "LT", label: "Lithuania", phone: "370" },
  { code: "LU", label: "Luxembourg", phone: "352" },
  { code: "LV", label: "Latvia", phone: "371" },
  { code: "LY", label: "Libya", phone: "218" },
  { code: "MA", label: "Morocco", phone: "212" },
  { code: "MC", label: "Monaco", phone: "377" },
  {
    code: "MD",
    label: "Moldova, Republic of",
    phone: "373",
  },
  { code: "ME", label: "Montenegro", phone: "382" },
  {
    code: "MF",
    label: "Saint Martin (French part)",
    phone: "590",
  },
  { code: "MG", label: "Madagascar", phone: "261" },
  { code: "MH", label: "Marshall Islands", phone: "692" },
  {
    code: "MK",
    label: "Macedonia, the Former Yugoslav Republic of",
    phone: "389",
  },
  { code: "ML", label: "Mali", phone: "223" },
  { code: "MM", label: "Myanmar", phone: "95" },
  { code: "MN", label: "Mongolia", phone: "976" },
  { code: "MO", label: "Macao", phone: "853" },
  {
    code: "MP",
    label: "Northern Mariana Islands",
    phone: "1-670",
  },
  { code: "MQ", label: "Martinique", phone: "596" },
  { code: "MR", label: "Mauritania", phone: "222" },
  { code: "MS", label: "Montserrat", phone: "1-664" },
  { code: "MT", label: "Malta", phone: "356" },
  { code: "MU", label: "Mauritius", phone: "230" },
  { code: "MV", label: "Maldives", phone: "960" },
  { code: "MW", label: "Malawi", phone: "265" },
  { code: "MX", label: "Mexico", phone: "52" },
  { code: "MY", label: "Malaysia", phone: "60" },
  { code: "MZ", label: "Mozambique", phone: "258" },
  { code: "NA", label: "Namibia", phone: "264" },
  { code: "NC", label: "New Caledonia", phone: "687" },
  { code: "NE", label: "Niger", phone: "227" },
  { code: "NF", label: "Norfolk Island", phone: "672" },
  { code: "NG", label: "Nigeria", phone: "234" },
  { code: "NI", label: "Nicaragua", phone: "505" },
  { code: "NL", label: "Netherlands", phone: "31" },
  { code: "NO", label: "Norway", phone: "47" },
  { code: "NP", label: "Nepal", phone: "977" },
  { code: "NR", label: "Nauru", phone: "674" },
  { code: "NU", label: "Niue", phone: "683" },
  { code: "NZ", label: "New Zealand", phone: "64" },
  { code: "OM", label: "Oman", phone: "968" },
  { code: "PA", label: "Panama", phone: "507" },
  { code: "PE", label: "Peru", phone: "51" },
  { code: "PF", label: "French Polynesia", phone: "689" },
  { code: "PG", label: "Papua New Guinea", phone: "675" },
  { code: "PH", label: "Philippines", phone: "63" },
  { code: "PK", label: "Pakistan", phone: "92" },
  { code: "PL", label: "Poland", phone: "48" },
  {
    code: "PM",
    label: "Saint Pierre and Miquelon",
    phone: "508",
  },
  { code: "PN", label: "Pitcairn", phone: "870" },
  { code: "PR", label: "Puerto Rico", phone: "1" },
  {
    code: "PS",
    label: "Palestine, State of",
    phone: "970",
  },
  { code: "PT", label: "Portugal", phone: "351" },
  { code: "PW", label: "Palau", phone: "680" },
  { code: "PY", label: "Paraguay", phone: "595" },
  { code: "QA", label: "Qatar", phone: "974" },
  { code: "RE", label: "Reunion", phone: "262" },
  { code: "RO", label: "Romania", phone: "40" },
  { code: "RS", label: "Serbia", phone: "381" },
  { code: "RU", label: "Russian Federation", phone: "7" },
  { code: "RW", label: "Rwanda", phone: "250" },
  { code: "SA", label: "Saudi Arabia", phone: "966" },
  { code: "SB", label: "Solomon Islands", phone: "677" },
  { code: "SC", label: "Seychelles", phone: "248" },
  { code: "SD", label: "Sudan", phone: "249" },
  { code: "SE", label: "Sweden", phone: "46" },
  { code: "SG", label: "Singapore", phone: "65" },
  { code: "SH", label: "Saint Helena", phone: "290" },
  { code: "SI", label: "Slovenia", phone: "386" },
  {
    code: "SJ",
    label: "Svalbard and Jan Mayen",
    phone: "47",
  },
  { code: "SK", label: "Slovakia", phone: "421" },
  { code: "SL", label: "Sierra Leone", phone: "232" },
  { code: "SM", label: "San Marino", phone: "378" },
  { code: "SN", label: "Senegal", phone: "221" },
  { code: "SO", label: "Somalia", phone: "252" },
  { code: "SR", label: "Suriname", phone: "597" },
  { code: "SS", label: "South Sudan", phone: "211" },
  {
    code: "ST",
    label: "Sao Tome and Principe",
    phone: "239",
  },
  { code: "SV", label: "El Salvador", phone: "503" },
  {
    code: "SX",
    label: "Sint Maarten (Dutch part)",
    phone: "1-721",
  },
  {
    code: "SY",
    label: "Syrian Arab Republic",
    phone: "963",
  },
  { code: "SZ", label: "Swaziland", phone: "268" },
  {
    code: "TC",
    label: "Turks and Caicos Islands",
    phone: "1-649",
  },
  { code: "TD", label: "Chad", phone: "235" },
  {
    code: "TF",
    label: "French Southern Territories",
    phone: "262",
  },
  { code: "TG", label: "Togo", phone: "228" },
  { code: "TH", label: "Thailand", phone: "66" },
  { code: "TJ", label: "Tajikistan", phone: "992" },
  { code: "TK", label: "Tokelau", phone: "690" },
  { code: "TL", label: "Timor-Leste", phone: "670" },
  { code: "TM", label: "Turkmenistan", phone: "993" },
  { code: "TN", label: "Tunisia", phone: "216" },
  { code: "TO", label: "Tonga", phone: "676" },
  { code: "TR", label: "Turkey", phone: "90" },
  {
    code: "TT",
    label: "Trinidad and Tobago",
    phone: "1-868",
  },
  { code: "TV", label: "Tuvalu", phone: "688" },
  {
    code: "TW",
    label: "Taiwan, Province of China",
    phone: "886",
  },
  {
    code: "TZ",
    label: "United Republic of Tanzania",
    phone: "255",
  },
  { code: "UA", label: "Ukraine", phone: "380" },
  { code: "UG", label: "Uganda", phone: "256" },
  {
    code: "US",
    label: "United States",
    phone: "1",
    suggested: true,
  },
  { code: "UY", label: "Uruguay", phone: "598" },
  { code: "UZ", label: "Uzbekistan", phone: "998" },
  {
    code: "VA",
    label: "Holy See (Vatican City State)",
    phone: "379",
  },
  {
    code: "VC",
    label: "Saint Vincent and the Grenadines",
    phone: "1-784",
  },
  { code: "VE", label: "Venezuela", phone: "58" },
  {
    code: "VG",
    label: "British Virgin Islands",
    phone: "1-284",
  },
  {
    code: "VI",
    label: "US Virgin Islands",
    phone: "1-340",
  },
  { code: "VN", label: "Vietnam", phone: "84" },
  { code: "VU", label: "Vanuatu", phone: "678" },
  { code: "WF", label: "Wallis and Futuna", phone: "681" },
  { code: "WS", label: "Samoa", phone: "685" },
  { code: "XK", label: "Kosovo", phone: "383" },
  { code: "YE", label: "Yemen", phone: "967" },
  { code: "YT", label: "Mayotte", phone: "262" },
  { code: "ZA", label: "South Africa", phone: "27" },
  { code: "ZM", label: "Zambia", phone: "260" },
  { code: "ZW", label: "Zimbabwe", phone: "263" },
];

const TagsContainer = styled("div")({
  padding: ".7em",
  marginRight: "1rem",
  borderRadius: "3px",
  width: "min(80vw, 600px)",
  // marginTop: "0.2em",
  display: "flex",
  alignItems: "center",
  flexWrap: "wrap",
  gap: ".5em",
  backgroundColor: "#37393B",
  borderBottom: "1px solid white",
});

const TagItem = styled("div")({
  backgroundColor: "rgb(218, 216, 216)",
  display: "inline-block",
  padding: ".5em .75em",
  borderRadius: "20px",
  color: "black",
});

const TagClose = styled("span")({
  height: "20px",
  width: "20px",
  backgroundColor: "rgb(48, 48, 48)",
  color: "#fff",
  borderRadius: "50%",
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center",
  marginLeft: ".5em",
  fontSize: "18px",
  cursor: "pointer",
});

const TagText = styled("span")({
  textColor: "white",
});

const TagInput = styled("input")({
  flexGrow: "1",
  padding: ".5em 0",
  border: "none",
  outline: "none",
  backgroundColor: "#37393B",
  color: "rgba(255, 255, 255, 0.9)",
});
