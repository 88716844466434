/* eslint-disable */
import React, { useEffect } from "react";
import { useTheme } from "@emotion/react";
import { Box, Button, useMediaQuery } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import FlexBetween from "../../components/FlexBetween";
import Header from "../../components/Header";
import MoreMenu from "../../components/MoreMenu";
import AddMerchantCategory from "./AddMerchantCategory";
import AddIcon from "@mui/icons-material/Add";
import MerchantCategoryCard from "../../components/MerchantCategoryCard";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchALLMerchantCategories,
  getAllMerchantCategories,
} from "../../features/merchants/merchantCategorySlice";
import { setIsEditingMerchCategory } from "../../features/merchants/editMerchantCategorySlice";

const merchantCategory = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery("(min-width: 600px)");
  const allMerchantCategories = useSelector(getAllMerchantCategories);

  useEffect(() => {
    dispatch(fetchALLMerchantCategories());
  }, [dispatch]);

  const editClick = (id) => {
    console.log("from Merchant category Page",id);
    dispatch(setIsEditingMerchCategory(true));
     navigate(`/addmerchantcategory/${id}`);
    console.log("from Merchant category Page");
  };

  return (
    <Box m="1.5rem 2.5rem">
      <FlexBetween gap="1.5rem">
        <Header
          title="MERCHANT CATEGORIES"
          subtitle="Manage merchant categories."
        />
        <Box>
          <FlexBetween gap="1.5rem" alignItems="center">
            <Link to="/addmerchantcategory" style={{ textDecoration: "none" }}>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: theme.palette.secondary[400],
                  color: theme.palette.primary[600],
                  "&:hover": {
                    backgroundColor: theme.palette.secondary[500],
                    color: "#000000",
                  },
                }}
                endIcon={<AddIcon />}
                onClick={() => <AddMerchantCategory />}
              >
                ADD MERCHANT CATEGORY
              </Button>
            </Link>
            <MoreMenu
              options={[
                "Merchants",
                "Merchant Category",
                "Add Merchant Category",
                "Export to Excel",
              ]}
            />
          </FlexBetween>
        </Box>
      </FlexBetween>
      <Box
        mt="20px"
        display="grid"
        gridTemplateColumns="repeat(4, minmax(0, 1fr))"
        justifyContent="space-between"
        rowGap="20px"
        columnGap="1.33%"
        sx={{
          "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
        }}
      >
        {console.log(allMerchantCategories)}
        {allMerchantCategories.map((merchantCategory) => (
          <MerchantCategoryCard
            key={merchantCategory._id}
            categoryName={merchantCategory.categoryName}
            categoryNameAr={merchantCategory.categoryNameAr}
            parentCategory={merchantCategory.parentCategory}
            categoryImg={merchantCategory.categoryImg}
            editClick={()=> editClick(merchantCategory._id)}
          />
        ))}
      </Box>
    </Box>
  );
};

export default merchantCategory;
