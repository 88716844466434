import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Collapse,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  IconButton,
  Tooltip,
  Typography,
  useTheme,useMediaQuery
} from "@mui/material";

import React, { useEffect, useState ,useMemo,useRef} from "react";
import FlexBetween from "./FlexBetween";
import YellowSwitch from "./YellowSwitch";
import ajwa from "../assets/ajwa_sultanhmet.png";
import MerchantCard from "../components/MerchantCard";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditIcon from "@mui/icons-material/Edit";
import {setIsEditingMerchant} from "../features/merchants/editSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import {
  fetchALLMerchants,
  getAllMerchants,getTotalNoMerchants
} from "../features/merchants/merchantSlice";
import { Padding } from "@mui/icons-material";

const PaginationComponent = ({
  _id,
  name,
  contactNum,
  email,
  // discount,
  period,
  category,
  logo,
  data
}) => {
  const theme = useTheme();

  const isNonMobile = useMediaQuery("(min-width: 1000px)");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const containerRef = useRef(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedNumber, setSelectedNumber] = useState(1);
  const allMerchants = useSelector(getAllMerchants());
  const totalNoMerchants = useSelector(getTotalNoMerchants());  
  console.log("DDDDDDD:",totalNoMerchants)

  const [state, setState] = useState({
    show: false,
    available: false,
    archieve: false,
  });
  const scrollToSelectedNumber = () => {
    if (containerRef.current && selectedNumber) {
      const selectedNumberElement = containerRef.current.children[selectedNumber - 1];
      if (selectedNumberElement) {
        selectedNumberElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  useEffect(() => {
    dispatch(fetchALLMerchants(1));
  }, [dispatch]);

  useMemo(() => {
    scrollToSelectedNumber();
  }, [selectedNumber]);

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };

// console.log(logo, "logo");
// const handleClick = () => {
//   dispatch(setIsEditingMerchant(true));
// };

// Example items, to simulate fetching from another resources.
const items = allMerchants && allMerchants;

function Items({ currentItems }) {
  return (
    <>
      {currentItems &&
        currentItems.map((item,index) => (

    <div style={{marginBottom:6}}>
          <MerchantCard
         key={item._id}
         _id={item._id}
         category={item.merchCategory}
         name={item.merchName}
         contactNum={item.merchPvtContact[0]}
         email={item.merchPvtEmail[0]}
         logo={item.merchLogo}
         // period={merchant.to.substr(0, 10)}
       />
       </div>
        ))}
    </>
  );
}

function PaginatedItems({ itemsPerPage }) {
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)
  const endOffset = itemOffset + itemsPerPage;
  //console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  const currentItems = items && items.length != 0 && items.slice(itemOffset, endOffset);
  const pageCount =  Math.ceil(totalNoMerchants && totalNoMerchants / itemsPerPage);
//  totalNoMerchants && totalNoMerchants;
  const numbers = Array.from({ length: pageCount - 1 }, (_, index) => index + 1);
  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    console.log(
      `User requested page number ${event}`, JSON.stringify(event)
    );
   // const newOffset = (event.selected * itemsPerPage) % items.length;
    // console.log(
    //   `User requested page number ${event.selected}, which is offset ${newOffset}`
    // );
  //  setItemOffset(newOffset);
  };

  const handleOnClick = (event) => {
    console.log(
      `handleOnClick number ${event}`, JSON.stringify(event)
    );
   // const newOffset = (event.selected * itemsPerPage) % items.length;
    // console.log(
    //   `User requested page number ${event.selected}, which is offset ${newOffset}`
    // );
  //  setItemOffset(newOffset);
  };

  const handleNumberClick = (event) => {
    console.log(
      `handleNumberClick ${event}`//, JSON.stringify(event)
    );
    setSelectedNumber(event)
    dispatch(fetchALLMerchants(event));
   // const newOffset = (event.selected * itemsPerPage) % items.length;
    // console.log(
    //   `User requested page number ${event.selected}, which is offset ${newOffset}`
    // );
  //  setItemOffset(newOffset);
  };

  return (
    <>
     
     <Box
    mt="20px"
    display="grid"
    gridTemplateColumns="repeat(2, minmax(0, 1fr))"
    justifyContent="space-between"
    rowGap="20px"
    columnGap="1.33%"
    sx={{
      "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
    }}
  >
      <Items currentItems={currentItems} />
      </Box>
      {/* <ReactPaginate
        breakLabel="..."
        nextLabel=""
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        // marginPagesDisplayed={100}
        // onClick={handleOnClick}
        //  onPageActive={handleonPageActive}
        pageCount={pageCount}
        previousLabel="< previous"
        renderOnZeroPageCount={null}
        selectedPageRel="current"
      /> */}
       <Box
       sx={{ width: "100%",
        //overflowX: "scroll",
        display:'flex',justifyContent:"center",
       alignItems:"center" }}
    
  >
          <FlexBetween sx={{ width: "750px",overflowX: "scroll",padding:'16px' }}>
        <IconButton
          variant="primary"
          size="small"
          onClick={() => handleNumberClick(selectedNumber - 1)}
        >
          {"< Previous"}
        </IconButton>
        <div
        ref={containerRef}
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          //  width: "100%",
            overflowX: "scroll",
          }}
        >
          {totalNoMerchants && totalNoMerchants != 0 &&
            numbers.map((i, index) => (

              <IconButton
                variant="primary"
                size="small"
                onClick={() => handleNumberClick(index + 1)}
                style={selectedNumber == index +1 ?{ padding: 11,backgroundColor:"#cca753" } :{ padding: 11 }}
                key={index}
              >
                {index + 1}
              </IconButton>
            ))}
        </div>
        <IconButton
          // variant="primary"
          size="small"
          onClick={() => handleNumberClick(selectedNumber + 1)}
        >
          {"Next >"}
        </IconButton>
      </FlexBetween>
      </Box>

    </>
  );
}

  return (
  
   <PaginatedItems itemsPerPage={10} />
  );
};

export default PaginationComponent;
