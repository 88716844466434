import { useTheme } from "@emotion/react";
import { Search } from "@mui/icons-material";
import { IconButton, InputBase } from "@mui/material";
import React from "react";
import FlexBetween from "./FlexBetween";

const SearchBar = ({placeholder}) => {
    const theme = useTheme();
  return (
    <FlexBetween
      backgroundColor={theme.palette.background.alt}
      borderRadius="9px"
      gap="3rem"
      p="0.1rem 1.5rem"
      // m="0rem 1rem"
    >
      <InputBase placeholder={placeholder} />
      <IconButton>
        <Search />
      </IconButton>
    </FlexBetween>
  );
};

export default SearchBar;
