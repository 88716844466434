import axios from "axios";
import authHeader from "../../services/auth-header";
import {api_AddOrganisation,api_GetAllOrganisation,api_UpdateOrganisation ,token} from "../../constants/api";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

export const fetchALLOrganizations = createAsyncThunk(
  "organizations/getAPI",
  async (payload) => {
    const response = await axios.post(
      api_GetAllOrganisation,
      payload,
      { headers: authHeader() }
      // {
      //   headers: {
      //     "x-access-token": token
      //   },
      // }
    );
    return response.data;
  }
);

export const saveNewOrganization = createAsyncThunk(
  "organizations/createAPI",
  async (payload) => {
    console.log("add new organisation:", payload)
    const response = await axios.post(
      api_AddOrganisation,
      payload,
      { headers: authHeader() }
      // {
      //   headers: {
      //     "x-access-token": token
      //   },
      // }
    );
    return response.data;
  }
);

export const updateOrganization = createAsyncThunk(
  "organizations/updateAPI",
  async (payload) => {
    console.log("updateOrganization1:")
    console.log("updateOrganization:",payload)
    const response = await axios.patch(
      api_UpdateOrganisation,
      payload,
      { headers: authHeader() }
      // {
      //   headers: {
      //     "x-access-token": token
      //   },
      // }
    );
    return response.data;
  }
);

const initialState = {
  organizationsData: [],
  loading: "idle",
};

const organizationslice = createSlice({
  name: "organizations",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchALLOrganizations.pending, (state, action) => {
      state.loading = "pending";
    });
    builder.addCase(fetchALLOrganizations.fulfilled, (state, action) => {
      state.loading = "idle";
      state.organizationsData = action.payload;
    });
    builder.addCase(saveNewOrganization.pending, (state, action) => {
      state.loading = "pending";
    });
    builder.addCase(saveNewOrganization.fulfilled, (state, action) => {
      state.loading = "idle";
      state.organizationsData.unshift(action.payload);
    });

    builder.addCase(updateOrganization.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(updateOrganization.fulfilled, (state, action) => {
      state.loading = "idle";
      state.organizationsData = state.organizationsData.filter(
        (_) => _.id !== action.payload.id
      );
      state.organizationsData.unshift(action.payload);
    });
  },
});

export const getAllOrganizations = (state) => state.organization.organizationsData;
export const getLoading = (state) => state.organization.loading;

export const getOrganizationById = (id) => {
  return (state) => state.organization.organizationsData.filter((_) => _._id === id)[0];
};

export default organizationslice.reducer;
