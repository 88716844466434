import {
  Box,
  Button,
  MenuItem,
  styled,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import Multiselect from "multiselect-react-dropdown";
import ImgPreview from "../../components/ImgPreview";
import {
  fetchALLOrganizations,
  getAllOrganizations,
} from "../../features/organizations/organizationSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchALLProducts,
  getAllProducts,
  fetchALLProductsByMerchants,
  getAllProductsByMerchants,
  getAllProductsByMerchantsForAddNotification
} from "../../features/products/productSlice";

import {
  saveNewnotification
} from "../../features/notifications/notificationSlice";

import { useNavigate,Link } from "react-router-dom";
import { fetchALLMerchants, getAllMerchants } from "../../features/merchants/merchantSlice";

const AddNotification = () => {
  const theme = useTheme();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [orgId, setOrgId] = useState([]);
  const navigate = useNavigate();
  const [selectAllBtn, setSelectAllBtn] = useState(false);
  const [selectedImg, setSelectedImg] = useState([]);
  const [options, setOptions] = useState([
    "Supreme Judicary Council",
    "Doha University",
    "Gulf Exchange",
    "Masraf Al Rayyan",
  ]);
  const dispatch = useDispatch();
  const allOrganizations = useSelector(getAllOrganizations);
  const allProducts = useSelector(getAllProducts);
  const allMerchants = useSelector(getAllMerchants());
  const allProductsByMerchants = useSelector(getAllProductsByMerchantsForAddNotification());
  
  console.log("allProductsByMerchants:",allProductsByMerchants)
console.log("allOrganizations:",allOrganizations)
  useEffect(() => {
    dispatch(fetchALLOrganizations());
    dispatch(fetchALLProducts());
    dispatch(fetchALLMerchants());
  }, [dispatch]);

  const initialValues = {
    notificationTitle: "",
    notificationMessage: "",
    notificationType: "",
    notificationImage: null,
    orgId: orgId,
    userType: "",
    merchantId: "",
    productId: "",
    allOrg:false
  };
  const validFileExtensions = {
    file: ["jpg", "gif", "png", "jpeg", "svg", "webp"],
  };

 
  const handleSelectAll = () => {
    setSelectAllBtn(true);
    setOrgId([...allOrganizations]);
  };

  
  function isValidFileType(fileName, fileType) {
    return (
      fileName &&
      validFileExtensions[fileType].indexOf(fileName.split(".").pop()) > -1
    );
  }

  function deleteImgHandler(image) {
    setSelectedImg(selectedImg.filter((e) => e !== image));
    URL.revokeObjectURL(image);
  }

  const productHandler = (newSelectedItems) => {
    console.log("productHandler",newSelectedItems)
    dispatch(fetchALLProductsByMerchants(newSelectedItems.value));
  };
  const notificationSchema = yup.object().shape({
    notificationTitle: yup.string().required("required"),

    notificationMessage: yup.string().required("required"),

    // notificationImage: yup
    //   .mixed()
    //   .required()
    //   .test("is-valid-type", "Not a valid image type", (value) =>
    //     isValidFileType(value && value.name.toLowerCase(), "file")
    //   ),
  });
  const createNewNotification = (data) => {
    console.log("createNewNotification", data);
    dispatch(saveNewnotification(data))
      .unwrap()
      .then((res) => {
        console.log("createNewNotification res:",res)
        if(res._id){
          navigate("/notifications");
        }
      });
  };
  const handleFormSubmit = (values) => {
    values.orgId = orgId;
    values.allOrg = selectAllBtn;
    // values.buyItems = buyItems;
     
     console.log(" NOTIFICATION values", values);
     var formData = new FormData();
     formData.append("allOrg", values.allOrg);
    //  for (const key of Object.keys(values.allOrg)) {
    //    formData.append("allOrg", values.allOrg[key]);
    //  }
   //  formData.append("notificationImage", values.notificationImage);
    // formData.append("notificationImageSmall", values.notificationImage);
     formData.append("mrchOrgId", values.merchantId);
     for (const key of Object.keys(values.notificationImage)) {
       formData.append("notificationImageSmall", values.notificationImage[key]);
     }
     for (const key of Object.keys(values.notificationImage)) {
       formData.append("notificationImage", values.notificationImage[key]);
     }
     formData.append("notificationMessage", values.notificationMessage);
     formData.append("notificationTitle",values.notificationTitle);
     formData.append("notificationType",values.notificationType);
     formData.append("productOfferId",values.productId);
     formData.append("userType",values.userType);
    // formData.append("orgId",values.orgId);
     for (const key of Object.keys(values.orgId)) {
          formData.append("orgId", values.orgId[key]);
        }
     console.log("formDatafinal:", formData);



    createNewNotification(formData);  
  };

  // console.log(orgId, "setOrgId(event)");

  return (
    <Box m="1rem" pb="0.5rem">
      <Header
        title="CREATE NOTIFICATION"
        subtitle="Fill in the details to create a new notification"
      />
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={notificationSchema}
        style={{ marginTop: "20px" }}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              {!selectAllBtn && (
                <Box sx={{ gridColumn: "span 2" }}>
                  <Multiselect
                    onKeyPressFn={function noRefCheck() {}}
                    onRemove={(event) => {
                      setOrgId(event);
                      console.log(event);
                    }}
                    displayValue="orgName"
                    onSelect={(event) => {
                      setOrgId(event);
                      console.log(event);
                    }}
                    // onRemove={function noRefCheck() {}}
                    onSearch={function noRefCheck() {}}
                    // onSelect={function noRefCheck() {}}
                    options={allOrganizations}
                    style={{
                      chips: {
                        backgroundColor: "gray",
                        color: "white",
                      },
                      multiselectContainer: {
                        // background:'gray',
                        color: theme.palette.primary,
                        // width:"40rem"
                      },
                      searchBox: {},
                      optionContainer: {
                        // To change css for option container
                        backgroundColor: "gray",
                        color: "white",
                      },
                    }}
                  />
                </Box>
              )}

              <Button
                sx={{
                  backgroundColor: theme.palette.secondary[400],
                  color: theme.palette.primary[600],
                  gridColumn: "span 2",
                  "&:hover": {
                    backgroundColor: theme.palette.secondary[500],
                    color: "#000000",
                  },
                }}
                onClick={handleSelectAll}
              >
                Select All Organizations
              </Button>
              <TextField
                fullWidth
                variant="filled"
                value={values.userType}
                select // tell TextField to render select
                label="User Type"
                onBlur={handleBlur}
                onChange={handleChange}
                name="userType"
                defaultValue=""
                error={!!touched.userType && !!errors.userType}
                helperText={touched.userType && errors.userType}
                sx={{ gridColumn: "span 2" }}
              >
                <MenuItem key={1} value="VIP">
                  VIP
                </MenuItem>
                <MenuItem key={2} value="non-VIP">
                  Non - VIP
                </MenuItem>
              </TextField>
              <TextField
                fullWidth
                variant="filled"
                value={values.notificationType}
                select // tell TextField to render select
                label="Notification Type"
                onBlur={handleBlur}
                onChange={handleChange}
                name="notificationType"
                defaultValue=""
                error={!!touched.notificationType && !!errors.notificationType}
                helperText={touched.notificationType && errors.notificationType}
                sx={{ gridColumn: "span 2" }}
              >
                <MenuItem key={1} value="web">
                  Web
                </MenuItem>
                <MenuItem key={2} value="mobile">
                  Mobile
                </MenuItem>
              </TextField>
              <TextField
                fullWidth
                variant="filled"
                select
                type="text"
                label="Select Merchant"
                onBlur={handleBlur}
                onChange={(e)=>{handleChange(e);
                  productHandler(e.target)}}
                value={values.merchantId}
                name="merchantId"
                error={!!touched.merchantId && !!errors.merchantId}
                helperText={touched.merchantId && errors.merchantId}
                sx={{ gridColumn: "span 2" }}
              >
                {allMerchants && allMerchants.map((merchant) => (
                  <MenuItem key={merchant._id} value={merchant._id}>
                    {merchant.merchName}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                fullWidth
                variant="filled"
                type="text"
                select
                label="Select Product"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.productId}
                name="productId"
                error={!!touched.productId && !!errors.productId}
                helperText={touched.productId && errors.productId}
                sx={{ gridColumn: "span 2" }}
              >
                {allProductsByMerchants.length != 0 && allProductsByMerchants.map((product) => (
                  <MenuItem key={product._id} value={product._id}>
                    {product.productName}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Notification Subject"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.notificationTitle}
                name="notificationTitle"
                error={
                  !!touched.notificationTitle && !!errors.notificationTitle
                }
                helperText={
                  touched.notificationTitle && errors.notificationTitle
                }
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Notification Message"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.notificationMessage}
                name="notificationMessage"
                multiline
                rows={5}
                error={
                  !!touched.notificationMessage && !!errors.notificationMessage
                }
                helperText={
                  touched.notificationMessage && errors.notificationMessage
                }
                sx={{ gridColumn: "span 2" }}
              />

              <Box
                sx={{
                  gridColumn: "span 4",
                  borderBottom: "1px solid white",
                  marginTop: "-20px",
                  paddingBottom: "-10px",
                }}
              >
                {" "}
                <input
                  type="file"
                  multiple
                  onChange={(event) => {
                    setFieldValue("notificationImage", event.currentTarget.files);
                    if (event.target.files) {
                      const filesArray = Array.from(event.target.files).map(
                        (file) => URL.createObjectURL(file)
                      );

                      setSelectedImg((prevImages) =>
                        prevImages.concat(filesArray)
                      );
                      Array.from(event.target.files).map(
                        (file) => URL.revokeObjectURL(file) // avoid memory leak
                      );
                    }
                  }}
                />
                {selectedImg !=  [] && (
                  <ImgPreview
                    source={selectedImg}
                    deleteImageHandler={deleteImgHandler}
                  />
                )}
              </Box>
              {/* <TextField
                fullWidth
                variant="standard"
                type="file"
                onBlur={handleBlur}
                placeholder="Upload Notification"
                // value={values.productImg}
                // name="productImg"
                // write a function for handle change when uplaoding a picture for the product with e.currentTarget.files[0] use state
                onChange={(event) => {
                  setFieldValue(
                    "notificationImage",
                    event.currentTarget.files[0]
                  );
                }}
                error={!!touched.notificationImage && !!errors.notificationImage}
                helperText={touched.notificationImage && errors.notificationImage}
                sx={{
                  textAlign: "center",
                  gridColumn: "span 2",
                  marginTop: "20px",
                }}
              /> */}
            </Box>
            <Box display="flex" justifyContent="end" m={2}>
              <Button type="submit" color="secondary" variant="contained">
                Create Notification
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default AddNotification;
