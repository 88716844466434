import React, { useEffect } from "react";
import { Box, Button, useTheme, useMediaQuery } from "@mui/material";
import { Link } from "react-router-dom";
import FlexBetween from "../../components/FlexBetween";
import Header from "../../components/Header";
import AddIcon from "@mui/icons-material/Add";
import OrganizationForm from "./OrganizationForm";
import OrganizationCard from "../../components/OrganizationCard";
// import qnb from "../../assets/qnb.png";
// import SJC from "../../assets/SJC.png";
// import qatarPost from "../../assets/qatarPost.png";
// import qatarInsurence from "../../assets/qatarInsurence.png";
import MoreMenu from "../../components/MoreMenu";
import {
  fetchALLOrganizations,
  getAllOrganizations,
} from "../../features/organizations/organizationSlice";
import { useDispatch, useSelector } from "react-redux";

const Orgnaizations = () => {
  const theme = useTheme();
  const isNonMobile = useMediaQuery("(min-width: 1000px)");
  const dispatch = useDispatch();
  const allOrganizations = useSelector(getAllOrganizations);

  useEffect(() => {
    dispatch(fetchALLOrganizations());
  }, [dispatch]);

  console.log(allOrganizations, "All Orgs");

  return (
    <Box m="1.5rem 2.5rem">
      <FlexBetween gap="1.5rem">
        <Header title="Our Organizations" />
        <FlexBetween gap="1.5rem">
          <Link to="/addorganization" style={{ textDecoration: "none" }}>
            <Button
              variant="contained"
              // color={theme.palette.secondary[300]}
              sx={{
                backgroundColor: theme.palette.secondary[400],
                color: theme.palette.primary[600],
                "&:hover": {
                  backgroundColor: theme.palette.secondary[500],
                  color: "#000000",
                },
              }}
              endIcon={<AddIcon />}
              onClick={() => <OrganizationForm />}
            >
              ADD Organization
            </Button>
          </Link>
          <MoreMenu options={["Add Organization", "Export to Excel"]} />
        </FlexBetween>
      </FlexBetween>
      <Box
        mt="20px"
        display="grid"
        gridTemplateColumns="repeat(3, minmax(0, 1fr))"
        justifyContent="space-between"
        rowGap="20px"
        columnGap="1.33%"
        sx={{
          "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
        }}
      >
        {allOrganizations.map((organization) => (
          <OrganizationCard
            key={organization._id}
            title={organization.orgName}
            logo={organization.orgLogo}
            _id={organization._id}
          />
        ))}

        {/* <OrganizationCard title="Supreme Judiciary Council" logo={SJC} />
        <OrganizationCard
          title="Qatar Insurance Brokers"
          logo={qatarInsurence}
        />
        <OrganizationCard title="QATAR POST" logo={qatarPost} /> */}
      </Box>
    </Box>
  );
};

export default Orgnaizations;
