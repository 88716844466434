import axios from "axios";
import authHeader from "../../services/auth-header";

import {api_GetNotifications,api_AddNotifications,api_PushNotifications} from "../../constants/api";


const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

export const fetchNotifications = createAsyncThunk(
  "notifications/getAPI",
  async () => {
    const response = await axios.post(api_GetNotifications,
      {},
      { headers: authHeader() })
    console.log("fetchNotifications res:",response)
    return response.data;
  }
);

export const saveNewnotification = createAsyncThunk(
  "notifications/createAPI",
  async (payload) => {
    const response = await axios.post(api_AddNotifications,
      payload,
      { headers: authHeader() }
    );
    console.log("saveNewnotification response:", response)
    return response.data;
  }
);
export const pushnotification = createAsyncThunk(
  "pushnotification/createAPI",
  async (payload) => {
    const response = await axios.post(api_PushNotifications,
      payload,
      { headers: authHeader() }
    );
    console.log("pushnotification response:", response)
    return response;
  }
);

export const updatenotification = createAsyncThunk(
  "notifications/updateAPI",
  async (payload) => {
    const response = await axios.put(
      `http://161.97.91.128:9000/notifications/${payload.id}`,
      payload
    );
    return response.data;
  }
);

const initialState = {
  notificationsData: [],
  loading: "idle",
};

const notificationslice = createSlice({
  name: "notifications",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchNotifications.pending, (state, action) => {
      state.loading = "pending";
    });
    builder.addCase(fetchNotifications.fulfilled, (state, action) => {
      state.loading = "idle";
      state.notificationsData = action.payload;
    });
    builder.addCase(saveNewnotification.pending, (state, action) => {
      state.loading = "pending";
    });
    builder.addCase(saveNewnotification.fulfilled, (state, action) => {
      state.loading = "idle";
      state.notificationsData.unshift(action.payload);
    });

    builder.addCase(updatenotification.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(updatenotification.fulfilled, (state, action) => {
      state.loading = "idle";
      state.notificationsData = state.notificationsData.filter(
        (_) => _.id !== action.payload.id
      );
      state.notificationsData.unshift(action.payload);
    });
  },
});

export const getNotifications = () => {
  return (state) =>
  state.notification.notificationsData     
};
export const getLoading = (state) => state.notification.loading;

export const getnotificationById = (id) => {
  return (state) => state.notification.notificationsData.filter((_) => _.id === id)[0];
};

export default notificationslice.reducer;
