import React from "react";
import {
  Box,
  TextField,
  Autocomplete,
  useMediaQuery,
  // useTheme,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import SearchBar from "./SearchBar";

const FilterBranch = () => {
  // const theme = useTheme();
  const isNonMobile = useMediaQuery("(min-width: 1000px)");

  const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
    gridColumn: "span 1",
    "& .MuiOutlinedInput-root": {
      color: theme.palette.secondary[500],
      borderRadius: "2rem",
    },
    "& .MuiInputLabel-root": {
      color: theme.palette.secondary[400],
    },
    "& + .MuiAutocomplete-popper .MuiAutocomplete-option": {
      backgroundColor: theme.palette.primary[500],
    },
    "& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected='true']":
      {
        color: theme.palette.secondary[500],
      },
    "& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected ='true'].Mui-focused":
      {
        color: theme.palette.secondary[500],
      },
  }));

  return (
    <Box
      display="grid"
      gap="30px"
      gridTemplateColumns="repeat(5, minmax(0, 1fr))"
      sx={{
        "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
      }}
    >
      <StyledAutocomplete
        disablePortal
        id="combo-box-demo"
        options={categories}
        renderInput={(params) => <TextField {...params} label="Categories" />}
      />

      <StyledAutocomplete
        disablePortal
        id="combo-box-demo"
        options={merchantOptions}
        renderInput={(params) => <TextField {...params} label="Merchant" />}
      />
      
      <StyledAutocomplete
        disablePortal
        id="combo-box-demo"
        options={adminActions}
        renderInput={(params) => (
          <TextField {...params} label="Admin Actions" />
        )}
      />
      <SearchBar placeholder="Search..."/>
    </Box>
  );
};

export default FilterBranch;

const categories = [
  { label: "Local Hotels" },
  { label: "International Hotels" },
  { label: "Laundry" },
  { label: "Restaurants & Cafes" },
  { label: "Sports & Fitness" },
  { label: "Retail Market" },
  { label: "Nursery" },
  { label: "Automotive" },
  { label: "Watches & Jewellary" },
  { label: "Nursery" },
  { label: "Nursery" },
  { label: "Nursery" },
  { label: "Cargo Services" },
  { label: "Entertainment" },
  { label: "Clinics" },
  { label: "Opticians" },
  { label: "Fashion" },
];

const merchantOptions = [
  { label: "Ameliorate" },
  { label: "Aquazzura" },
  { label: "Beox" },
  { label: "Bottega Veneta" },
  { label: "Ceriotti" },
  { label: "Christophe Robin" },
  { label: "Cinq Mondes" },
  { label: "Cirepil" },
  { label: "Dolce & Gabbana" },
  { label: "Emilio Pucci" },
  { label: "Eyeko" },
  { label: "FarmaVita" },
  { label: "Fresh Line" },
  { label: "Gianvito Rossi" },
  { label: "Giuseppe Zanotti" },
  { label: "Grow Gorgeous" },
  { label: "Illamasqua" },
  { label: "Jimmy Choo" },
  { label: "Le Silla" },
  { label: "Make Up Atelier" },
  { label: "Malone Souliers" },
];



const adminActions = [
  { label: "Available" },
  { label: "Archieve" },
  { label: "Contract Expired" },
];

