import React, { useEffect, useState } from "react";
import { Box, Button, useTheme, useMediaQuery } from "@mui/material";
import ProductForm from "./ProductForm";
import { alpha, styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import AddIcon from "@mui/icons-material/Add";
import boucheron from "../../assets/boucheron.jpeg";
import Header from "../../components/Header";
import FlexBetween from "../../components/FlexBetween";
import { Link } from "react-router-dom";
import Filters from "../../components/FilterProduct";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchALLProducts,
  getAllProducts,
  getLoading,
  toggleActive,
} from "../../features/products/productSlice";
import MoreMenu from "../../components/MoreMenu";
import ProductCard from "../../components/ProductCard";
import SearchBar from "../../components/SearchBar";
import { setIsEditing } from "../../features/products/editSlice";

const Products = () => {
  const isNonMobile = useMediaQuery("(min-width: 1000px)");
  const theme = useTheme();
  const dispatch = useDispatch();
  const allProducts = useSelector(getAllProducts);
  const apiStatus = useSelector(getLoading);
  // const editStatus = useSelector(toggleActive);
  console.log("all products",allProducts.data)
  useEffect(() => {
   // console.log("useEffectuseEffect")
    dispatch(fetchALLProducts());
  }, [dispatch]);
  // console.log(allProducts,"all products")
  // console.log(editStatus, "editStatus")
  return (
    <Box m="1.5rem 2.5rem">
      <FlexBetween gap="1.5rem">
        <Header title="PRODUCTS" subtitle="See your list of products." />
        <Box>
          <FlexBetween gap="1.5rem" alignItems="center">
            <Link to="/addproduct" style={{ textDecoration: "none" }}>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: theme.palette.secondary[400],
                  color: theme.palette.primary[600],
                  "&:hover": {
                    backgroundColor: theme.palette.secondary[500],
                    color: "#000000",
                  },
                }}
                endIcon={<AddIcon />}
                onClick={() => {
                  dispatch(setIsEditing(false));
                }}
              >
                ADD PRODUCT
              </Button>
            </Link>
            <MoreMenu
              options={[
                "Add Product",
                "Product Category",
                "Add Product Category",
                "Export to Excel",
              ]}
            />
          </FlexBetween>
        </Box>
      </FlexBetween>
      <Filters />

      <Box
        mt="20px"
        display="grid"
        gridTemplateColumns="repeat(3, minmax(0, 1fr))"
        justifyContent="space-between"
        rowGap="20px"
        columnGap="1.33%"
        sx={{
          "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
        }}
      >
        { allProducts && allProducts.data && allProducts.data.map((product) => (
         <>
         {/* {console.log("product:::item",product)} */}
          <ProductCard
            key={product._id}
            // category={product.productCategory}
             title={product.productName}
          //   price={product.productPrice[0].price}
            // rating={3}
             description={product.productDescription}
             _id={product._id}
             img={product.productImg}
             branch={product.availableBranch}
            // delivery={product.delivery}
          />
         </>
        ))}
      </Box>
    </Box>
  );
};

export default Products;
