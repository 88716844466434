import { useTheme } from "@emotion/react";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import FlexBetween from "./FlexBetween";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setIsEditingProdCategory } from "../features/products/editCategorySlice";

const CategoryCard = ({
  id,
  categoryName,
  categoryNameAr,
  parentCategory,
  categoryImg,
 
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClick = () => {
    dispatch(setIsEditingProdCategory(true));
    navigate(`/addproductcategory/${id}`);
  };

  return (
    <Card
      sx={{
        backgroundImage: "none",
        backgroundColor: theme.palette.background.alt,
        borderRadius: "0.55rem",
      }}
    >
      <CardContent sx={{ display: "flex", flexDirection: "column" }}>
        {/* <FlexBetween> */}
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <CardMedia
            component="img"
            sx={{
              width: 60,
              height: 60,
              borderRadius: "50%",
              marginBottom: 2,
              objectFit: "cover",
              backgroundPosition: "center",
            }}
            image={categoryImg}
            alt="Category Image"
          />
          <FlexBetween gap="3rem">
            <Box>
              {" "}
              <Typography variant="h5" component="div">
                {categoryName}
              </Typography>
              <Typography variant="h5" component="div">
                {categoryNameAr}
              </Typography>
              <Typography variant="body2">
                Parent Category : {parentCategory}
              </Typography>
            </Box>
            <Box>
              <Tooltip title="Edit Category">
                <EditIcon
                  sx={{
                    "&:hover": {
                      color: theme.palette.secondary[500],
                    },
                  }}
                  onClick={handleClick}
                />
              </Tooltip>
            </Box>
          </FlexBetween>
        </Box>

        {/* </FlexBetween> */}
      </CardContent>
    </Card>
  );
};

export default CategoryCard;
