import { ImageList, ImageListItem, Box, IconButton } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import React from "react";

const ImgPreview = ({ source, deleteImageHandler }) => {
  return (
    <ImageList
      sx={{
        width: 768,
        maxHeight: 500,
        "&.MuiImageList-root": {
          gridTemplateColumns:
            "repeat(auto-fill, minmax(150px, 2fr))!important",
        },
      }}
      cols={3}
      rowHeight={164}
    >
      {source != undefined && source.map((eachImg) => (
        <ImageListItem key={eachImg} cols={1} rows={1} sx={{ margin: "10px" }}>
          <img
            sx={{ width: "164px", height: "164px", margin: "10px" }}
            src={eachImg}
            alt=""
            loading="lazy"
          />
          <Box display="flex" justifyContent="center" alignItems="center">
            {" "}
            <IconButton onClick={() => deleteImageHandler(eachImg)} >
              <DeleteOutlineIcon/>
            </IconButton>
          </Box>
        </ImageListItem>
      ))}
    </ImageList>
  );
};

export default ImgPreview;
