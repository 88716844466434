
import {
  Box,
  Card,
  CardContent,
  Typography,
  // useMediaQuery,
} from "@mui/material";
import React from "react";

const InvoiceCard = ({ title, color, invoicePrice }) => {
  // const isNonMobile = useMediaQuery("(min-width:600px)");
  // const theme = useTheme();
  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Card variant="outlined" sx={{ width: "18rem" }}>
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            {title}
          </Typography>

          <Typography variant="h4" color={color}>
            QAR{" "}{invoicePrice}
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
};

export default InvoiceCard;
