import axios from "axios";

import authHeader from "../../services/auth-header";
import {api_CreateRegisterationCodes} from "../../constants/api";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");



export const createregisterationcodes = createAsyncThunk(
  "registerationCodes/createAPI",
  async (payload) => {
    try {
    console.log("createregisterationcodes slice payload:",payload)
    const response = await axios.post(api_CreateRegisterationCodes,
      payload,
      { headers: authHeader() }
    );
    console.log("createregisterationcodes slice res:",response)
    return response.data;} catch (error) {
      // Handle errors here
    console.log("createregisterationcodes slice error:",error)
      throw error;
    }
  }
);


const initialState = {
  registerationData: [],
  loading: "idle",
};

const registerationslice = createSlice({
  name: "registerationcodes",
  initialState,
  reducers: {},
  extraReducers: (builder) => {

    builder.addCase(createregisterationcodes.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(createregisterationcodes.fulfilled, (state, action) => {
      state.loading = "idle";
      state.registerationData = action.payload;
    });
  },
});

export const getAllcreatedregisterationcodes = (state) => state.registrationCode.registerationData;
export const getLoading = (state) => state.registerationcodes.loading;

export default registerationslice.reducer;
