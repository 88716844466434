import { createSlice } from "@reduxjs/toolkit";

const editSlice = createSlice({
  name: "edit",
  initialState: { isEditing: false },
  reducers: {
    setIsEditingProdCategory: (state, action) => {
      state.isEditing = action.payload;
    },
  },
});

export const { setIsEditingProdCategory } = editSlice.actions;
export default editSlice.reducer;
