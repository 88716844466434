import React, { useState, useEffect } from "react";
import { Box, Avatar, Typography, useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import SettingsIcon from "@mui/icons-material/Settings";
import { Home } from "@mui/icons-material";
import backgroundImage from "../../assets/ajwa_banner.png";
import LogoImage from "../../assets/ajwa_sultanhmet.png";
import breakpoints from "../../components/BreakPoints";

const Profile = () => {
  const theme = useTheme();
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);

  const StyledTab = styled(Tab)(({ theme }) => ({
    color: "rgba(255, 255, 255, 0.7)",
    "&.Mui-selected": {
      color: theme.palette.secondary[300],
    },
    "&.Mui-focusVisible": {
      backgroundColor: "rgba(100, 95, 228, 0.72)",
    },
    "&:hover": {
      color:theme.palette.secondary[400],
      opacity: 1,
    },
  }));

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);
  return (
    <Box position="relative" mb={5}>
      <Box
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="18.75rem"
        borderRadius="xl"
        sx={{
          // src={profileImage}
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "50%",
          overflow: "hidden",
        }}
      >
        {/* <Card
          sx={{
            position: "relative",
            mt: 20,
            mx: 3,
            py: 2,
            px: 2,
            width: "100%",
          }}
        >
          <Grid container spacing={3} alignItems="center">
            <Grid item>
              <Avatar
                src={LogoImage}
                alt="profile-image"
                size="xl"
                shadow="sm"
              />
            </Grid>
            <Grid item>
              <Box height="100%" mt={0.5} lineHeight={1}>
                <Typography variant="h5" fontWeight="medium">
                  Ajwa Sulthanhmet
                </Typography>
                <Typography variant="button" color="text" fontWeight="regular">
                  Admin
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4} lg={3} sx={{ ml: "auto" }}>
              <AppBar position="static">
                <Tabs
                  orientation={tabsOrientation}
                  value={tabValue}
                  onChange={handleSetTabValue}
                  sx={{
                    "& .MuiTabs-indicator": {
                      display: "flex",
                      justifyContent: "center",
                      backgroundColor: theme.palette.secondary[300],
                    },
                    "& .MuiTabs-indicatorSpan": {
                      backgroundColor: theme.palette.secondary[300],
                    },
                  }}
                >
                  <StyledTab
                    sx={{
                      color: "rgba(255, 255, 255, 0.7)",
                      "&.Mui-selected": {
                        color: theme.palette.secondary[300],
                      },
                      "&.Mui-focusVisible": {
                        backgroundColor: "rgba(100, 95, 228, 0.72)",
                      },
                      "&:hover": {
                        color:theme.palette.secondary[400],
                        opacity: 1,
                      },
                    }}
                    iconPosition="start"
                    label="App"
                    icon={<Home />}
                  />
                  <StyledTab
                    sx={{
                      color: "rgba(255, 255, 255, 0.7)",
                      "&.Mui-selected": {
                        color: theme.palette.secondary[300],
                      },
                      "&.Mui-focusVisible": {
                        backgroundColor: "rgba(100, 95, 228, 0.72)",
                      },
                      "&:hover": {
                        color:theme.palette.secondary[400],
                        opacity: 1,
                      },
                    }}
                    iconPosition="start"
                    label="Notifications"
                    icon={<MailOutlineIcon />}
                  />
                  <StyledTab
                    sx={{
                      color: "rgba(255, 255, 255, 0.7)",
                      "&.Mui-selected": {
                        color: theme.palette.secondary[300],
                      },
                      "&.Mui-focusVisible": {
                        backgroundColor: "rgba(100, 95, 228, 0.72)",
                      },
                      "&:hover": {
                       
                        color:theme.palette.secondary[400],
                        opacity: 1,
                      },
                    }}
                    iconPosition="start"
                    label="Settings"
                    icon={<SettingsIcon />}
                  />
                </Tabs>
              </AppBar>
            </Grid>
          </Grid>
        </Card> */}
      </Box>
    </Box>
  );
};

export default Profile;
