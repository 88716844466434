import React, { useEffect, useState } from "react";
import { Box, Button, useTheme, useMediaQuery } from "@mui/material";
import FlexBetween from "../../components/FlexBetween";
import Header from "../../components/Header";
import Filters from "../../components/FilterProduct";
import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";
import OffersForm from "./OffersForm";
import OfferCard from "../../components/OfferCard";
import offerImage from "../../assets/offerImage.jpeg";
import torchDoha from "../../assets/torchDoha.jpeg";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchALLMerchants,
  getAllMerchants,
} from "../../features/merchants/merchantSlice";

import {
  fetchALLOffers,
  getAllOffers
} from "../../features/offers/offerSlice";

const Offers = () => {
  const isNonMobile = useMediaQuery("(min-width: 1000px)");
  const theme = useTheme();
  const dispatch = useDispatch();

  const allOffers = useSelector(getAllOffers());
  console.log("allOffersallOffersallOffersallOffers::::",allOffers)

  useEffect(() => {
    dispatch(fetchALLOffers());
  }, [dispatch]);


  return (
    <Box m="1.5rem 2.5rem">
      <FlexBetween gap="1.5rem">
        <Header title="OFFERS" />
        <Link to="/addoffer" style={{ textDecoration: "none" }}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: theme.palette.secondary[400],
              color: theme.palette.primary[600],
              "&:hover": {
                backgroundColor: theme.palette.secondary[500],
                color: "#000000",
              },
            }}
            endIcon={<AddIcon />}
            onClick={() => <OffersForm />}
          >
            CREATE OFFER
          </Button>
        </Link>
      </FlexBetween>
      {/* <Filters /> */}
      <Box
        mt="20px"
        display="grid"
        gridTemplateColumns="repeat(4, minmax(0, 1fr))"
        justifyContent="space-between"
        rowGap="20px"
        columnGap="1.33%"
        sx={{
          "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
        }}
      >
         {allOffers.length != 0 && allOffers.data.map((offer) => (
        <OfferCard
        title={offer.offerName}
        image={offer.offerImage[0]}
        description={offer.offerDescription}
        chip={offer.offerType}
        validity={offer.offerToDate}
        _id={offer._id}
      />
        ))}


        {/* <OfferCard
          title="Double Single Use Deluxe."
          image={torchDoha}
          description="The Torch Doha"
          chip="Buy 2 Get 2"
        />
        <OfferCard
          title="Travel to Makkah 4 Nights/5 Days."
          image={offerImage}
          description="For more information contact +994 51 201 82 84 or Email sales@goldenglobeint.com"
          chip="Buy 1 Get 1"
        />
        <OfferCard
          title="Double Single Use Deluxe."
          image={torchDoha}
          description="The Torch Doha"
          chip="Free Delivery"
        />
        <OfferCard
          title="Travel to Azerbaijan 4 Nights/5 Days."
          image={offerImage}
          description="For more information contact +994 51 201 82 84 or Email sales@goldenglobeint.com"
          chip="50% off"
        /> */}
      </Box>
    </Box>
  );
};

export default Offers;
