import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { themeSettings } from "./theme";
import Layout from "./scenes/layout";
import Dashboard from "./scenes/dashboard";
import Products from "./scenes/products";
import ProductForm from "./scenes/products/ProductForm";
import AddMerchant from "./scenes/merchants/merchantForm";
import Merchants from "./scenes/merchants";
import SignInPage from "./scenes/signin/SigninPage";
import SignUpPage from "./scenes/signUp/SignupPage";
import ForgotPassword from "./scenes/forgotPassword/ForgotPassword.jsx";
import Users from "./scenes/users/Users.jsx";
import Feedbacks from "./scenes/feedbacks/Feedbacks.jsx";
import Profile from "./scenes/profile/Profile";
import Offers from "./scenes/offers/Offers";
import OffersForm from "./scenes/offers/OffersForm";
import Organizations from "./scenes/organizations/Organizations";
import AddUser from "./scenes/users/UserForm";
import AddProductCategory from "./scenes/products/AddProdCategory";
import Notifications from "./scenes/notifications/Notifications";
import AddNotification from "./scenes/notifications/AddNotification";
import AddMerchantCategory from "./scenes/merchants/AddMerchantCategory";
import AddOrganizations from "./scenes/organizations/OrganizationForm";
import Invoices from "./scenes/invoices/index";
import CreateInvoice from "./scenes/invoices/CreateInvoice";
import Orders from "./scenes/orders/index";
import ProductCategory from "./scenes/products/ProductCategory";
import MerchantCategory from "./scenes/merchants/MerchantCategory";
import Branches from "./scenes/branches";
import AddBranch from "./scenes/branches/BranchForm";
import OrderHistory from "./scenes/orders/OrderHistory";
import RegistrationCodes from "./scenes/registrationCodes";
// import SampleFile from "./components/SampleFile";
import AddRegistrationCodes from "./scenes/registrationCodes/AddRegistrationCode";
import LoyalityPoints from "./scenes/loyalityPoints";
import AddLoyalityPoints from "./scenes/loyalityPoints/AddLoyalityPoints.jsx";

function App() {
  const mode = useSelector((state) => state.theme.mode);
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

  return (
    <div className="app">
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes>
            <Route element={<Layout />}>
              <Route path="/" element={<Navigate to="/signin" replace />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/offers" element={<Offers />} />
              <Route path="/addoffer" element={<OffersForm />} />
              <Route path="adminaddoffer" element={<OffersForm />} />
              <Route
                path="/addoffer/:id"
                element={<OffersForm />}
              />
              <Route path="/products" element={<Products />} />
              <Route path="/addproduct" element={<ProductForm />} />
              <Route path="/addproduct/:id" element={<ProductForm />} />
              <Route path="/productcategory" element={<ProductCategory />} />
              <Route
                path="/addproductcategory"
                element={<AddProductCategory />}
              />
              <Route
                path="/addproductcategory/:id"
                element={<AddProductCategory />}
              />
              <Route path="/merchants" element={<Merchants />} />
              <Route path="/addmerchant" element={<AddMerchant />} />
              <Route path="/addmerchant/:id" element={<AddMerchant />} />
              <Route path="/merchantcategory" element={<MerchantCategory />} />
              <Route
                path="/addmerchantcategory"
                element={<AddMerchantCategory />}
              />
              <Route
                path="/addmerchantcategory/:id"
                element={<AddMerchantCategory />}
              />
              <Route path="/branches" element={<Branches />} />
              <Route path="/addbranch" element={<AddBranch />} />
              <Route path="/users" element={<Users />} />
              <Route path="/adduser" element={<AddUser />} />
              <Route path="/feedbacks" element={<Feedbacks />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/organizations" element={<Organizations />} />
              <Route path="/addorganization" element={<AddOrganizations />} />
              <Route
                path="/addorganization/:id"
                element={<AddOrganizations />}
              />
              <Route path="/notifications" element={<Notifications />} />
              <Route path="/addnotification" element={<AddNotification />} />
              <Route path="/invoices" element={<Invoices />} />
              <Route path="/createInvoice" element={<CreateInvoice />} />
              <Route path="/orders" element={<Orders />} />
              <Route path="/orderhistory" element={<OrderHistory />} />
              <Route
                path="/registrationCodes"
                element={<RegistrationCodes />}
              />
              <Route
                path="/addregistrationcode"
                element={<AddRegistrationCodes />}
              />

              <Route
                path="/loyalityPoints"
                element={<LoyalityPoints />}
              />
              <Route
                path="/addLoyalityPoints"
                element={<AddLoyalityPoints />}
              />
            </Route>
            <Route path="/signin" element={<SignInPage />} />
            <Route path="/signup" element={<SignUpPage />} />
            <Route path="/forgotpassword" element={<ForgotPassword />} />
          </Routes>
          {/* <Routes path="/" element={<ProductsPage />}>
            <Route path="/products" element={<Products />} />
            <Route path="addproduct" element={<ProductForm />} />
            <Route path="productcategory" element={<ProductCategory />} />
            <Route path="addproductcategory" element={<AddProductCategory />} />
          </Routes> */}
        </ThemeProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
