import React from "react";
import {
  Box,
  TextField,
  Autocomplete,
  useMediaQuery,
  // useTheme,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import SearchBar from "./SearchBar";

const FilterProduct = () => {
  // const theme = useTheme();
  const isNonMobile = useMediaQuery("(min-width: 1000px)");

  const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
    gridColumn: "span 1",
    "& .MuiOutlinedInput-root": {
      color: theme.palette.secondary[500],
      borderRadius: "2rem",
    },
    "& .MuiInputLabel-root": {
      color: theme.palette.secondary[400],
    },
    "& + .MuiAutocomplete-popper .MuiAutocomplete-option": {
      backgroundColor: theme.palette.primary[500],
    },
    "& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected='true']":
      {
        color: theme.palette.secondary[500],
      },
    "& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected ='true'].Mui-focused":
      {
        color: theme.palette.secondary[500],
      },
  }));

  return (
    <Box
      display="grid"
      gap="30px"
      gridTemplateColumns="repeat(5, minmax(0, 1fr))"
      sx={{
        "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
      }}
    >
      <StyledAutocomplete
        disablePortal
        id="combo-box-demo"
        options={branch}
        renderInput={(params) => <TextField {...params} label="Branch" />}
      />

      <StyledAutocomplete
        disablePortal
        id="combo-box-demo"
        options={offerType}
        renderInput={(params) => <TextField {...params} label="Offer Type" />}
      />
      {/* <StyledAutocomplete
        disablePortal
        id="combo-box-demo"
        options={merchTypeOptions}
        renderInput={(params) => (
          <TextField {...params} label="Merchant Type" />
        )}
      /> */}
      <StyledAutocomplete
        disablePortal
        id="combo-box-demo"
        options={adminActions}
        renderInput={(params) => (
          <TextField {...params} label="Admin Actions" />
        )}
      />
      {/* <StyledAutocomplete
        disablePortal
        id="combo-box-demo"
        options={locationOptions}
        renderInput={(params) => <TextField {...params} label="Location" />}
      /> */}
      <SearchBar placeholder="Search..."/>
    </Box>
  );
};

export default FilterProduct;

const branch = [
  { label: "Restaurants" },
  { label: "Jewellary & Accessories" },
  { label: "Hotel" },
  { label: "Clothing & Shoes" },
  { label: "Health & Beauty" },
  { label: "Grocery" },
  { label: "Opticians" },
  { label: "Nursery" },
  { label: "Sports" },
  { label: "Appliances" },
  { label: "Travel" },
  { label: "Electronics" },
  { label: "Furniture" },
  { label: "Services" },
  { label: "Automative" },
  { label: "Leisure & Pleasure" },
  { label: "QBIS" },
  { label: "Other" },
];

const offerType = [
  { label: "Discount" },
  { label: "Buy 1 Get 1" },
  { label: "Buy 2 Get 1" },
  { label: "Buy 2 Get 2" },
  { label: "Free Delivery" },
  { label: "Combo Package" },
  
];

const adminActions = [
  { label: "Available" },
  { label: "Archieve" },
  { label: "Contract Expired" },
];

