import {
  Box,
  Button,
  MenuItem,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Formik } from "formik";
import { ThemeProvider } from "@mui/material/styles";
import * as yup from "yup";
import React, { useState ,useEffect} from "react";
import Header from "../../components/Header";
import { useDispatch, useSelector } from "react-redux";
import {
  getProductCategoryById,
  saveNewProductCategory,
  updateProductCategory,
} from "../../features/products/productCategorySlice";
import ImgPreview from "../../components/ImgPreview";
import MoreMenu from "../../components/MoreMenu";
import FlexBetween from "../../components/FlexBetween";
import { useNavigate, useParams } from "react-router-dom";
import { setIsEditingProdCategory } from "../../features/products/editCategorySlice";


import {
  fetchALLMerchantCategories,
  getAllMerchantCategories,
} from "../../features/merchants/merchantCategorySlice";

const AddProductCategory = () => {
  const theme = useTheme();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [update, setUpdate] = useState(false);
  const dispatch = useDispatch();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const navigate = useNavigate();
  const params = useParams();
  const itemToEdit = useSelector(getProductCategoryById(params.id));
  const isEditingProdCategory = useSelector(setIsEditingProdCategory);

  const allMerchantCategories = useSelector(getAllMerchantCategories);
  console.log("allMerchantCategories allMerchantCategories:",allMerchantCategories)
  const filteredArray = allMerchantCategories.filter(obj => obj.parentCategory === "FALSE")
  console.log("filteredArray allMerchantCategories:",filteredArray)
  console.log("isEditingProdCategory payload:",isEditingProdCategory)
  console.log("itemToEdit payload:",itemToEdit)
  const initialValues = {
    categoryName: "",
    categoryNameAr: "",
    parentCategory: "",
    categoryImg: [],
  };
  // const validFileExtensions = {
  //   file: ["jpg", "gif", "png", "jpeg", "svg", "webp"],
  // };

  // function isValidFileType(fileName, fileType) {
  //   return (
  //     fileName &&
  //     validFileExtensions[fileType].indexOf(fileName.split(".").pop()) > -1
  //   );
  // }

  useEffect(() => {
   // dispatch(fetchALLProducts());
   dispatch(fetchALLMerchantCategories());
   // dispatch(fetchALLMerchants());
 }, [dispatch]);
  const categorySchema = yup.object().shape({
    categoryName: yup.string().required("required"),
    categoryNameAr: yup.string().required("required"),
    parentCategory: yup.string().required("required"),
    // category: yup.string().required("choose Product Type"),

    // categoryImg: yup
    //   .mixed()
    //   .required()
    //   .test("is-valid-type", "Not a valid image type", (value) =>
    //     isValidFileType(value && value.name.toLowerCase(), "file")
    //   ),
  });

  const savedValues = {
    categoryName: itemToEdit?.categoryName,
    categoryNameAr: itemToEdit?.categoryNameAr,
    parentCategory: itemToEdit?.parentCategory,
    categoryImg: itemToEdit?.categoryImg,
    _id: params.id,
  };

  function deleteImageHandler(image) {
    setSelectedFiles(selectedFiles.filter((e) => e !== image));
    URL.revokeObjectURL(image);
  }

  const createNewProductCategory = (data) => {
    console.log("{console.log(createNewProductCategory)}");
    dispatch(saveNewProductCategory(data))
      .unwrap()
      .then((res) => {
        console.log("saveNewProductCategory res:", res);

        if (res._id) {
          navigate("/productcategory");
        }
      });
  };

  const updateNewProductCategory = (data) => {
    console.log("{console.log(updateNewProductCategory)}")
    dispatch(updateProductCategory(data))
      .unwrap()
      .then((res) => {
        console.log("updateNewProductCategory res:",res)
        navigate("/productcategory");
      });
  };

  const handleFormSubmit = (values,type,i) => {
    console.log("update123",update);
    console.log("handleFormSubmit Form", values);
    // var formData = new FormData();
    // for (const key of Object.keys(values.categoryImg)) {
    //   console.log("key : " ,key,"values.categoryImg:",values.categoryImg,"Object.keys(values.categoryImg):",Object.keys(values.categoryImg));
    //  console.log("arry : " + values.categoryImg[key]);
    //   formData.append("image1", values.categoryImg[key]);
    // }
    // formData.append("categoryName", values.categoryName);
    // formData.append("categoryNameAr", values.categoryNameAr);
    // formData.append("parentCategory", values.parentCategory);

    // if(values._id){
    //   console.log("{console.log(update)}")
    //   formData.append("productsCategorId", itemToEdit?._id);
    //   updateNewProductCategory(formData);
    // }
    // else if( itemToEdit === undefined){
    //   console.log("{console.log(newone)}")
    //   createNewProductCategory(formData);
    // }

    // console.log(update,"{console.log(update)}")
    // update && updateNewProductCategory(formData);
  };
  return (
    <ThemeProvider theme={theme}>
      <Box m="1rem" pb="0.5rem">
        <FlexBetween>
          <Header
            title="ADD PRODUCT CATEGORY"
            subtitle="Fill in the details to add a new product category"
          />
          <MoreMenu options={["Product Category"]} />
        </FlexBetween>
        {/* {console.log(isEditingProdCategory, "isEditingProdCategory")} */}
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={
            isEditingProdCategory.payload.isEditingProdCategory.isEditing &&
            itemToEdit
              ? savedValues
              : initialValues
          }
          validationSchema={categorySchema}
          style={{ marginTop: "20px" }}
        >
          {({
            values,
            errors,
            touched,
            setFieldValue,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                }}
              >
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Category Name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.categoryName}
                  name="categoryName"
                  error={!!touched.categoryName && !!errors.categoryName}
                  helperText={touched.categoryName && errors.categoryName}
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Category Name (arabic)"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.categoryNameAr}
                  name="categoryNameAr"
                  error={!!touched.categoryNameAr && !!errors.categoryNameAr}
                  helperText={touched.categoryNameAr && errors.categoryNameAr}
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  value={values.parentCategory}
                  select // tell TextField to render select
                  label="Parent Category"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  name="parentCategory"
                  defaultValue=""
                  error={!!touched.parentCategory && !!errors.parentCategory}
                  helperText={touched.parentCategory && errors.parentCategory}
                  sx={{ gridColumn: "span 2" }}
                >
                  {filteredArray.map(item =>  <MenuItem key={item._id} value={item.categoryName}>
                  {item.categoryName}
                </MenuItem>
                  )}
                  {/* <MenuItem key={1} value="Fashion">
                    Fashion
                  </MenuItem>
                  <MenuItem key={2} value="Automotors">
                    Automotors
                  </MenuItem>
                  <MenuItem key={3} value="Medical">
                    Medical
                  </MenuItem> */}
                </TextField>

                <Box
                  sx={{
                    gridColumn: "span 2",
                    borderBottom: "1px solid white",
                    // marginTop: "-5px",
                  }}
                >
                  {/* <label>Category Image</label> */}
                  <input
                    type="file"
                    multiple
                    label="category Image"
                    onChange={(event) => {
                      setFieldValue("categoryImg", event.currentTarget.files);
                      if (event.target.files) {
                        const filesArray = Array.from(event.target.files).map(
                          (file) => URL.createObjectURL(file)
                        );

                        setSelectedFiles((prevImages) =>
                          prevImages.concat(filesArray)
                        );
                        Array.from(event.target.files).map(
                          (file) => URL.revokeObjectURL(file) // avoid memory leak
                        );
                      }
                    }}
                  />
                </Box>
                {values.categoryImg && (
                  <ImgPreview
                    source={selectedFiles}
                    deleteImageHandler={deleteImageHandler}
                  />
                )}
              </Box>
              <Box display="flex" justifyContent="end" m={2}>
               
                {isEditingProdCategory.payload.isEditingProdCategory
                  .isEditing && itemToEdit ? (
                  <Button
                    type="submit"
                    color="secondary"
                    variant="contained"
//                    onClick={() => setUpdate(true)}

onClick={() => handleFormSubmit(values,"update")}
                  >
                    Update Product Category
                  </Button>
                ) : (
                  <Button
                    type="submit"
                    color="secondary"
                    variant="contained"
                    onClick={() => setUpdate(false)}
                //    onClick={() => handleFormSubmit(values,"new")}
                  >
                    Add Product Category
                  </Button>
                )}
              </Box> 
            </form>
          )}
        </Formik>
      </Box>
    </ThemeProvider>
  );
};

export default AddProductCategory;
