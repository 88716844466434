
import React,{useState} from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import {
  Typography,
  Avatar,
  useTheme,
  ThemeProvider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  InputAdornment,
  IconButton,

} from "@mui/material";

import { useNavigate } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useDispatch, useSelector } from "react-redux";
import Logo from "../../assets/mzayaLogo.png";
import Container from "@mui/material/Container";
import { Formik } from "formik";
import * as yup from "yup";
import Header from "../../components/Header";
import { MuiOtpInput } from "mui-one-time-password-input";
import { forgotPassword, otpVerfication,updatePassword } from "../../features/auth/authSlice";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const [otpDialog, setOtpDialog] = useState(false);
  const [otp, setOtp] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showUpdatePassword, setShowUpdatePassword] = useState(false);
  const [email, setEmail] = useState("");

  const handleOTPChange = (newValue) => {
    console.log("handleOTPChange:",newValue)
    setOtp(newValue);
  };

   
  const handleComplete = (newValue) => {
    console.log("handleComplete:",newValue)
    setOtp(newValue);
   let x = {"email":email,"otp":newValue}
    dispatch(otpVerfication(x))
.unwrap()
.then((res) => {
  // navigate("/profile");
 // alert(res.resultat)
  console.log("handleSubmit forgotPassword dispacth res:",res)
  if (res.success){
    setOtpDialog(false);
    setShowUpdatePassword(true);
  }
  else alert(res.resultat)
  //window.location.reload();
})
.catch((err) => {
  console.log("handleSubmit forgotPassword dispacth err:",err)
 // setLoading(false);
});
  };


  const theme = useTheme();
  const navigate = useNavigate();
  const initialValues = {};
  const newPassinitialValues = {};

  const signUpSchema = yup.object().shape({
    email: yup
    .string()
    .email("This is not a valid email."),
    //.required("Email is requried!"),
   // phone: yup.string().required("required"),
  });

  const newPasswordSchema = yup.object().shape({
  password: yup
    .string()
    // .test(
    //   "len",
    //   "The password must be between 6 and 40 characters.",
    //   (val) =>
    //     val && val.toString().length >= 6 && val.toString().length <= 40
    // )
    .required("New Password is required!"),
    confirmpassword: yup
      .string()
      // .test(
      //   "len",
      //   "The password must be between 6 and 40 characters.",
      //   (val) =>
      //     val && val.toString().length >= 6 && val.toString().length <= 40
      // )
      .required("Confirm New Password is required!"),
  });
  const handleSubmit = (values) => {
    if (showUpdatePassword){

      console.log("handleSubmit updatepassword values:::::::",values.password);
      if(values.password != "" //|| (values.phone != "" && values.phone.length >= 10)
      ){
        
   let x = {"email":email,"password":values.password}
  dispatch(updatePassword(x))
  .unwrap()
  .then((res) => {
    if(res.message == "Password updated successfully"){
      navigate("/signin");
    }
    // navigate("/profile");
   // alert(res.resultat)
    console.log("handleSubmit updatepassword dispacth res:",res)
    //window.location.reload();
  })
  .catch((err) => {
    console.log("handleSubmit updatepassword dispacth err:",err)
   // setLoading(false);
  });
      }
    }
    else{

    console.log("handleSubmit values:::::::",values.email);
    setEmail(values.email)
    let x = values.email;
    if(values.email != "" //|| (values.phone != "" && values.phone.length >= 10)
    ){
      console.log("handleSubmit if passed",x);
      
dispatch(forgotPassword(x))
.unwrap()
.then((res) => {
  if(res._id){
   // navigate("/signin");
  }
  // navigate("/profile");
 // alert(res.resultat)
  console.log("handleSubmit forgotPassword dispacth res:",res)
  //window.location.reload();
})
.catch((err) => {
  console.log("handleSubmit forgotPassword dispacth err:",err)
 // setLoading(false);
});
    }
    // event.preventDefault();
    //const data = new FormData(event.currentTarget); 
    //console.log("handleSubmit data:::::::",data);
    //console.log({
     // email: data.get("email"),
      //password: data.get("password"),
    //});


    setOtpDialog(true);
    }
  };

  const handleClose = () => {
    setOtpDialog(false);
  };
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <ThemeProvider theme={theme}>
     { !showUpdatePassword ? 
     <Container component="main" maxWidth="sm">
     <Box
       sx={{
         boxShadow: 3,
         borderRadius: 2,
         px: 4,
         py: 6,
         marginTop: 8,
         display: "flex",
         flexDirection: "column",
         alignItems: "center",
       }}
     >
       <Avatar sx={{ m: 1, bgcolor: "black" }}>
         <img src={Logo} alt="Mzaya Premium Loyalty Program" />
       </Avatar>
       {/* <Typography component="h1" variant="h5">
         Sign in
       </Typography> */}
       <Header subtitle="Forgot Password" />
       <Formik
         onSubmit={handleSubmit}
         initialValues={initialValues}
         validationSchema={signUpSchema}
         style={{ marginTop: "20px" }}
       >
         {({
           errors,
           touched,
           values,
           // setFieldValue,
           handleBlur,
           handleChange,
           handleSubmit,
         }) => (
           <Box
             component="form"
             onSubmit={handleSubmit}
             noValidate
             sx={{ mt: 1 }}
           >
         
             <TextField
               margin="normal"
               fullWidth
               id="email"
               label="Email Address"
               name="email"
               autoComplete="email"
               onBlur={handleBlur}
               onChange={handleChange}
               value={values.email}
               autoFocus
               error={!!touched.email && !!errors.email}
               helperText={touched.email && errors.email}
             />
            {/* 
           <TextField
               margin="normal"
               fullWidth
               id="phone"
               label="Phone Number"
               name="phone"
               type="tel"
               onBlur={handleBlur}
               onChange={handleChange}
               value={values.phone}
               autoFocus
               error={!!touched.phone && !!errors.phone}
               helperText={touched.phone && errors.phone}
             /> */}
             <Button
               type="submit"
               fullWidth
               variant="contained"
               sx={{
                 mt: 3,
                 mb: 2,
                 backgroundColor: theme.palette.secondary[400],
                 color: theme.palette.primary[600],
                 "&:hover": {
                   backgroundColor: theme.palette.secondary[500],
                   color: "#000000",
                 },
               }}
             //  onClick={() => handleFormSubmit(values)}
             >
               Next
             </Button>
           </Box>
         )}
       </Formik>
    
     </Box>
   </Container>
     :
     <Container component="main" maxWidth="sm">
        <Box
          sx={{
            boxShadow: 3,
            borderRadius: 2,
            px: 4,
            py: 6,
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "black" }}>
            <img src={Logo} alt="Mzaya Premium Loyalty Program" />
          </Avatar>
          {/* <Typography component="h1" variant="h5">
            Sign in
          </Typography> */}
          <Header subtitle="Update Password" />
          <Formik
            onSubmit={handleSubmit}
            initialValues={newPassinitialValues}
            validationSchema={newPasswordSchema}
            style={{ marginTop: "20px" }}
          >
            {({
              errors,
              touched,
              values,
              // setFieldValue,
              handleBlur,
              handleChange,
              handleSubmit,
            }) => (
              <Box
                component="form"
                onSubmit={handleSubmit}
                noValidate
                sx={{ mt: 1 }}
              >
             <TextField
                    margin="normal"
                    fullWidth
                    name="dummy"
                    label="Email"
                    value={values.email}
                    //onBlur={handleBlur}
                    //onChange={handleChange}
                    autoFocus
                    autoComplete="dummy"
                    id="dummy"
                   
                  />
            <TextField
                    margin="normal"
                    fullWidth
                    name="password"
                    label="New Password"
                    value={values.password}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    autoFocus
                    autoComplete="password"
                    type={showPassword ? "text" : "password"}
                    id="password"
                    error={!!touched.password && !!errors.password}
                    helperText={touched.password && errors.password}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                   <TextField
                    margin="normal"
                    fullWidth
                    name="confirmpassword"
                    label="Confirm New Password"
                    value={values.confirmpassword}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    autoComplete="confirmpassword"
                    type={showPassword ? "text" : "password"}
                    id="confirmpassword"
                    error={!!touched.confirmpassword && !!errors.confirmpassword}
                    helperText={touched.confirmpassword && errors.confirmpassword}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
               {/* 
              <TextField
                  margin="normal"
                  fullWidth
                  id="phone"
                  label="Phone Number"
                  name="phone"
                  type="tel"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.phone}
                  autoFocus
                  error={!!touched.phone && !!errors.phone}
                  helperText={touched.phone && errors.phone}
                /> */}
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{
                    mt: 3,
                    mb: 2,
                    backgroundColor: theme.palette.secondary[400],
                    color: theme.palette.primary[600],
                    "&:hover": {
                      backgroundColor: theme.palette.secondary[500],
                      color: "#000000",
                    },
                  }}
                //  onClick={() => handleFormSubmit(values)}
                >
                  Next
                </Button>
              </Box>
            )}
          </Formik>
       
        </Box>
      </Container>}
      {/* {otpDialog && ( */}
      <Dialog
        open={otpDialog}
        maxWidth="sm"
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Please Enter your OTP"}</DialogTitle>
        <DialogContent>
          <MuiOtpInput value={otp} onChange={handleOTPChange} 
          onComplete={handleComplete} sx={{width:"16rem"}}/>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{
              backgroundColor: theme.palette.secondary[400],
              color: theme.palette.primary[600],
              "&:hover": {
                backgroundColor: theme.palette.secondary[500],
                color: "#000000",
              },
            }}
            onClick={handleClose}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {/* )} */}
    </ThemeProvider>
  );
};

export default ForgotPassword;
