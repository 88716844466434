import { Box } from "@mui/system";
import { Formik } from "formik";
import * as yup from "yup";
import React, { useState } from "react";
import Header from "../../components/Header";
import {
  Button,
  MenuItem,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { saveNewMerchantCategory ,getMerchantCategoryById} from "../../features/merchants/merchantCategorySlice";
import ImgPreview from "../../components/ImgPreview";
import FlexBetween from "../../components/FlexBetween";
// import MoreMenu from "../../components/MoreMenu";
import { useParams ,Link, useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const AddMerchantCategory = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const params = useParams();
  console.log("PPPPPP:",params.id)
  const itemToEdit = useSelector(getMerchantCategoryById(params.id));
  console.log("PPPPPP: itemToEdit:",itemToEdit)
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const dispatch = useDispatch();
  const [selectedFiles, setSelectedFiles] = useState([]);

  const createNewMerchantCategory = (data) => {
    dispatch(saveNewMerchantCategory(data))
      .unwrap()
      .then(() => {
        navigate("/merchantcategory");
      });

    // console.log("product Created in mongobd");
  };

  function deleteImageHandler(image) {
    setSelectedFiles(selectedFiles.filter((e) => e !== image));
    URL.revokeObjectURL(image);
  }

  const initialValues = {
    categoryName: "",
    categoryNameAr: "",
    parentCategory: "",
    categoryImg: [],
  };
  const validFileExtensions = {
    file: ["jpg", "gif", "png", "jpeg", "svg", "webp"],
  };

  // function isValidFileType(fileName, fileType) {
  //   return (
  //     fileName &&
  //     validFileExtensions[fileType].indexOf(fileName.split(".").pop()) > -1
  //   );
  // }
  const categorySchema = yup.object().shape({
    categoryName: yup.string().required("required"),
    categoryNameAr: yup.string().required("required"),
    parentCategory: yup.string().required("required"),
    // categoryImg: yup
    //   .mixed()
    //   .required()
    //   .test("is-valid-type", "Not a valid image type", (value) =>
    //     isValidFileType(value && value.name.toLowerCase(), "file")
    //   ),
  });

  const handleFormSubmit = (values) => {
    console.log(values);
    var formData = new FormData();
    for (const key of Object.keys(values.categoryImg)) {
      console.log("arry : " + values.categoryImg[key]);
      formData.append("categoryImg", values.categoryImg[key]);
    }
    formData.append("categoryName", values.categoryName);
    formData.append("categoryNameAr", values.categoryNameAr);
    formData.append("parentCategory", values.parentCategory);

    createNewMerchantCategory(formData);
  };
  return (
    <Box m="1rem" pb="0.5rem">
      <FlexBetween>
        <Header
          title="ADD MERCHANT CATEGORY"
          subtitle="Fill in the details to add a new merchant category"
        />
        <Link to="/merchantcategory" style={{ textDecoration: "none" }}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: theme.palette.secondary[400],
              color: theme.palette.primary[600],
              "&:hover": {
                backgroundColor: theme.palette.secondary[500],
                color: "#000000",
              },
            }}
            endIcon={<ArrowBackIosIcon fontSize="small" />}
            // onClick={() => <MerchantCategory />}
          >
            MERCHANT CATEGORY
          </Button>
        </Link>
        {/* <MoreMenu options={["Merchant Category"]} /> */}
      </FlexBetween>

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={categorySchema}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Category Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.categoryName}
                name="categoryName"
                error={!!touched.categoryName && !!errors.categoryName}
                helperText={touched.categoryName && errors.categoryName}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Category Name (arabic)"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.categoryNameAr}
                name="categoryNameAr"
                error={!!touched.categoryNameAr && !!errors.categoryNameAr}
                helperText={touched.categoryNameAr && errors.categoryNameAr}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                value={values.parentCategory}
                select // tell TextField to render select
                label="Parent Category"
                onBlur={handleBlur}
                onChange={handleChange}
                name="parentCategory"
                defaultValue=""
                error={!!touched.parentCategory && !!errors.parentCategory}
                helperText={touched.parentCategory && errors.parentCategory}
                sx={{ gridColumn: "span 2" }}
              >
                <MenuItem key={1} value="Fashion">
                  Fashion
                </MenuItem>
                <MenuItem key={2} value="Automotors">
                  Automotors
                </MenuItem>
                <MenuItem key={3} value="Medical">
                  Medical
                </MenuItem>
              </TextField>
              <Box
                sx={{
                  gridColumn: "span 2",
                  borderBottom: "1px solid white",
                  // marginTop: "-5px",
                }}
              >
                <input
                  type="file"
                  multiple
                  label="category Image"
                  onChange={(event) => {
                    setFieldValue("categoryImg", event.currentTarget.files);
                    if (event.target.files) {
                      const filesArray = Array.from(event.target.files).map(
                        (file) => URL.createObjectURL(file)
                      );

                      setSelectedFiles((prevImages) =>
                        prevImages.concat(filesArray)
                      );
                      Array.from(event.target.files).map(
                        (file) => URL.revokeObjectURL(file) // avoid memory leak
                      );
                    }
                  }}
                />
              </Box>
              {values.categoryImg && (
                <ImgPreview
                  source={selectedFiles}
                  deleteImageHandler={deleteImageHandler}
                />
              )}
            </Box>
            <Box display="flex" justifyContent="end" m={2}>
              <Button type="submit" color="secondary" variant="contained">
                Add Category
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default AddMerchantCategory;
