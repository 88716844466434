import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Grid, Divider, useTheme, Typography } from "@mui/material";
import ProfileHeader from "./ProfileHeader";
import PlatformSettings from "./PlatformSettings";
import ProfileInfoCard from "./ProfileInfoCard";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import ProfileList from "./ProfileList";
import PopularProductCard from "./PopularProductCard";
import branch1 from "../../assets/branch1.png";
import branch2 from "../../assets/branch2.png";
import branch3 from "../../assets/branch3.png";
import branch4 from "../../assets/branch4.png";
import branch5 from "../../assets/branch5.png";
import popular1 from "../../assets/popular1.png";
import popular2 from "../../assets/popular2.png";
import popular3 from "../../assets/popular3.png";
import popular4 from "../../assets/popular4.jpeg";

import {
  fetchUserDetails, getUserDetails
  
} from "../../features/users/userSlice";

const Profile = () => {

  const dispatch = useDispatch();
  const profileBranchData = [
    {
      image: branch1,
      name: "Al Bidda",
      description: "Hi! I need more information..",
      action: {
        type: "internal",
        route: "/profile",
        color: "#ffe3a3",
        label: "remove",
      },
    },
    {
      image: branch2,
      name: "Pearl",
      description: "Awesome work, can you..",
      action: {
        type: "internal",
        route: "/profile",
        color: "#ffe3a3",
        label: "remove",
      },
    },
    {
      image: branch3,
      name: "Lusail",
      description: "About files I can..",
      action: {
        type: "internal",
        route: "/profile",
        color: "#ffe3a3",
        label: "remove",
      },
    },
    {
      image: branch4,
      name: "Al khor",
      description: "Have a great afternoon..",
      action: {
        type: "internal",
        route: "/profile",
        color: "#ffe3a3",
        label: "remove",
      },
    },
    {
      image: branch5,
      name: "Al Saad",
      description: "Hi! I need more information..",
      action: {
        type: "internal",
        route: "/profile",
        color: "#ffe3a3",
        label: "remove",
      },
    },
  ];

  const userDetails = useSelector(getUserDetails);
  console.log("Profile userDetails:",userDetails)


  useEffect(() => {
    console.log("Profile Details1")
    dispatch(fetchUserDetails());

  }, [dispatch]);
  return (
    <Box mb={2}>
      <ProfileHeader />
      <Box mt={5} mb={3}>
        <Grid container spacing={1}>
          {/* <Grid item xs={12} md={4} xl={4}>
            <PlatformSettings />
          </Grid> */}
          <Grid item xs={12} md={4} xl={4} sx={{ display: "flex" }}>
            <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
            <ProfileInfoCard
              title="profile information"
              description="Hi, I’m Alec Thompson, Decisions: If you can’t decide, the answer is no. If two equally difficult paths, choose the one more painful in the short term (pain avoidance is creating an illusion of equality)."
              info={{
                Name: userDetails.username,
                Mobile:  userDetails.phone,
                Email: userDetails.email,
               // location: "Turkey",
              }}
              social={[
                {
                  link: "https://www.facebook.com/people/Golalita-Rewards/100083027697442/",
                  icon: <FacebookIcon />,
                  color: "facebook",
                },
                {
                  link: "https://twitter.com/2030company",
                  icon: <TwitterIcon />,
                  color: "twitter",
                },
                {
                  link: "https://www.instagram.com/2030.dm/",
                  icon: <InstagramIcon />,
                  color: "instagram",
                },
              ]}
            //  action={{ route: "", tooltip: "Edit Profile" }}
              shadow={false}
            />
            <Divider orientation="vertical" sx={{ mx: 0 }} />
          </Grid>
          {/* <Grid item xs={12} md={4} xl={4}>
            <ProfileList
              title="branches"
              profiles={profileBranchData}
              shadow={false}
            />
          </Grid> */}

          {/* <Box pt={2} px={5} lineHeight={1.25}>
            <Typography
              variant="h6"
              fontWeight="bold"
              textTransform="uppercase"
            >
              Popualar Products
            </Typography>
          </Box>

          <Box p={5}>
            <Grid container spacing={6}>
              <Grid item xs={12} md={3} xl={3}>
                <PopularProductCard
                  image={popular1}
                  label="273 Sales"
                  title="scandinavian"
                  description="Music is something that everyone has their own specific opinion about."
                />
              </Grid>
              <Grid item xs={12} md={3} xl={3}>
                <PopularProductCard
                  image={popular2}
                  label="123 Sales"
                  title="scandinavian"
                  description="Music is something that everyone has their own specific opinion about."
                />
              </Grid>
              <Grid item xs={12} md={3} xl={3}>
                <PopularProductCard
                  image={popular3}
                  label="111 Sales"
                  title="minimalist"
                  description="Different people have different taste, and various types of music."
                />
              </Grid>
              <Grid item xs={12} md={3} xl={3}>
                <PopularProductCard
                  image={popular4}
                  label="100 Sales"
                  title="gothic"
                  description="Why would anyone pick blue over pink? Pink is obviously a better color."
                />
              </Grid>
            </Grid>
          </Box> */}
        </Grid>
      </Box>
    </Box>
  );
};

export default Profile;
