import { useTheme } from "@emotion/react";
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Switch,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import FlexBetween from "../../components/FlexBetween";
import Header from "../../components/Header";
import MoreMenu from "../../components/MoreMenu";
import AddProductCategory from "./AddProdCategory";
import AddIcon from "@mui/icons-material/Add";
import YellowSwitch from "../../components/YellowSwitch";
import qnb from "../../assets/qnb.png";
import ProductCategoryCard from "../../components/ProductCategoryCard";
import {
  fetchALLProductCategories,
  getAllProductCategories,
  saveNewProductCategory,
} from "../../features/products/productCategorySlice";
import { useDispatch, useSelector } from "react-redux";
import { setIsEditing } from "../../features/products/editSlice";
import { setIsEditingProdCategory } from "../../features/products/editCategorySlice";

const ProductCategory = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const allProductCategories = useSelector(getAllProductCategories);
  const isNonMobile = useMediaQuery("(min-width: 600px)");
  // const [checked, setChecked] = useState([]);

  // const handleToggle = (value) => () => {
  //   const currentIndex = checked.indexOf(value);

  //   const newChecked = [...checked];

  //   if (currentIndex === -1) {
  //     newChecked.push(value);
  //   } else {
  //     newChecked.splice(currentIndex, 1);
  //   }

  //   setChecked(newChecked);

  //   console.log(checked, "checked");
  // };

  useEffect(() => {
    dispatch(fetchALLProductCategories());
  }, [dispatch]);

  const editClick = () => {
    dispatch(setIsEditingProdCategory(true));
    console.log("from Product category Page");
    // dispatch(setIsEditing(true));
  };
  // const categoryList = [
  //   { category: "Fashion", img: qnb, id: 1 },
  //   { category: "Automotor", img: qnb, id: 2 },
  //   { category: "Flowers", img: qnb, id: 3 },
  // ];
  // categoryList.map((eachCat, i) => console.log(eachCat, i));

  return (
    <Box m="1.5rem 2.5rem">
      <FlexBetween gap="1.5rem">
        <Header
          title="PRODUCT CATEGORIES"
          subtitle="Manage product categories."
        />

        <Box>
          <FlexBetween gap="1.5rem" alignItems="center">
            <Link to="/addproductcategory" style={{ textDecoration: "none" }}>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: theme.palette.secondary[400],
                  color: theme.palette.primary[600],
                  "&:hover": {
                    backgroundColor: theme.palette.secondary[500],
                    color: "#000000",
                  },
                }}
                endIcon={<AddIcon />}
                onClick={() => <AddProductCategory />}
              >
                ADD PRODUCT CATEGORY
              </Button>
            </Link>
            <MoreMenu
              options={[
                "Products",
                "Product Category",
                "Add Product Category",
                "Export to Excel",
              ]}
            />
          </FlexBetween>
        </Box>
      </FlexBetween>
      <Box
        mt="20px"
        display="grid"
        gridTemplateColumns="repeat(4, minmax(0, 1fr))"
        justifyContent="space-between"
        rowGap="20px"
        columnGap="1.33%"
        sx={{
          "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
        }}
      >
        {console.log(allProductCategories, "AllCateorires")}
        {allProductCategories.map((productCategory) => (
          <ProductCategoryCard
            key={productCategory._id}
            id={productCategory._id}
            categoryName={productCategory.categoryName}
            categoryNameAr={productCategory.categoryNameAr}
            parentCategory={productCategory.parentCategory}
            categoryImg={productCategory.categoryImg}
            // editClick={editClick}
          />
        ))}
      </Box>
    </Box>
  );
};

export default ProductCategory;
