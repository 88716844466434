import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  Typography,
} from "@mui/material";

const InovoiceDialog = () => {
  return (
    <>
      <DialogTitle id="alert-dialog-title">
        <Typography>Date: 12 May 2023</Typography>
        <Typography>Time: 23:04</Typography>
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText id="alert-dialog-description">
          <Typography>TransactionID : 13139898</Typography>
          <Typography>SkipCashID : 13139898</Typography>
          <Typography>InvoiceID: 8736459382498</Typography>
          <Typography>Website Link: www.absccd.com</Typography>
          <Typography>Status: Transaction Completed Sucessfully !!</Typography>
        </DialogContentText>
      </DialogContent>
    </>
  );
};

export default InovoiceDialog;
