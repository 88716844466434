import React, { useState } from "react";
import Header from "../../components/Header";
import FlexBetween from "../../components/FlexBetween";
import {
  Box,
  useTheme,
  InputBase,
  IconButton,
  Button,
  ChipProps,Typography,
  Chip,
  useMediaQuery,
  Tooltip,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import DoneIcon from "@mui/icons-material/Done";
import WarningIcon from "@mui/icons-material/Warning";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import { DataGrid, GridRenderCellParams, GridToolbar } from "@mui/x-data-grid";
import { Link, useNavigate } from "react-router-dom";


import Chart from "react-apexcharts";
const Dashoard = () => {
  const theme = useTheme();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const navigate = useNavigate()
  const [userArchieve, setUserArchieve] = useState(false);
  const [options, setOptions] = useState({
    options: {
      chart: {
        type: 'donut',
      },
      labels: ['Category A', 'Category B', 'Category C'],
    },
    series: [3.5, 5 - 1.5], // Replace with your data
  });

  const optionsR   ={
    plotOptions: {
      radialBar: {
        hollow: {
          size: '50%', // Adjust this to control the size of the circle
        },
        dataLabels: {
          showOn: 'always',
          name: {
            offsetY: -10,
            show: true,
            color: '#888',
          },
          value: {
            color: '#FFFFFF',
            fontSize: '30px',
            show: true,
          },
        },
      },
    },
  };

  const seriesR = [2.5 * 20];

  // console.log(userArchieve)
  const handleClick = (event, cellValues) => {
    console.log(cellValues.row.id, userArchieve);
    setUserArchieve((prev) => !prev);
  };

  function getChipProps(params){
    if (params.value === "Archieve") {
      return {
        icon: <WarningIcon style={{ fill: "#D23030" }} />,
        label: params.value,
        style: {
          borderColor: "#D23030",
          color: "#D23030",
        },
      };
    } else {
      return {
        icon: <DoneIcon style={{ fill: "#66BB6A" }} />,
        label: params.value,
        style: {
          borderColor: "#66BB6A",
          color: "#66BB6A",
        },
      };
    }
  }

  const columns = [
    // {
    //   field: "_id",
    //   headerName: "ID",
    //   flex: 1,
    //  // sortable: true,
    // },
    {
      field: "merchantName",
      headerName: "Merchant Name",
      flex: 0.7,
    },
    {
      field: "totalEmployee",
      headerName: "Total Employee",
     // field: "email",
     // headerName: "Email",
      flex: 0.7,
    },
    {
      field: "rate",
      headerName: "Rate",
      flex: 0.5
    },
    {
      field: "totalPageOpen",
      headerName: "Total Page Open",
      flex: 0.5,
    }
  ];

  const rows = [
    {
      id: 1,
      _id: "63e026fd24cddc4076c1721e",
      merchantName: "Qatar Insurance",
      totalPageOpen: "342",
      totalEmployee: 23,
      rate: "3.6",
    },
    {
      id: 2,
      _id: "33a026fd24cddc4076c1721h",
      merchantName: "Gulf Exchange",
      totalPageOpen: "758",
      totalEmployee: 78,
      rate: "2.6",
    },
    {
      id: 3,
      _id: "57e026fd24cddc40786c1721f",
      merchantName: "Masraf Al Rayan",
      totalPageOpen: "328",
      totalEmployee: 65,
      rate: "1.2",
    },
    {
      id: 4,
      _id: "57e026fd24cddc40786c1721a",
      merchantName: "Adnoc",
      totalPageOpen: "547",
      totalEmployee: 36,
      rate: "4.8",
    },
    {
      id: 5,
      _id: "57e026fd24cddc40786c1721b",
      merchantName: "Qatar Insurance",
      totalPageOpen: "213",
      totalEmployee: 43,
      rate: "3.9",
    },
    {
      id: 6,
      _id: "54ve026fd24cddc40786c1721e",
      merchantName: "Dukhan",
      totalPageOpen: "546",
      totalEmployee: 23,
      rate: "3.0",
    },
    {
      id: 7,
      _id: "5we026fd24cddr40786c1721e",
      merchantName: "Zara",
      totalPageOpen: "345",
      totalEmployee: 44,
      rate: "2.9",
    },
    {
      id: 8,
      _id: "5we126fd24cddr40786c1721e",
      merchantName: "Al Reem",
      totalPageOpen: "124",
      totalEmployee: 64,
      rate: "3.1",
    },
    {
      id: 9,
      _id: "5we226fd24cddr40786c1721e",
      merchantName: "Al Jaber",
      totalPageOpen: "230",
      totalEmployee: 64,
      rate: "4.3",
    },
    {
      id: 10,
      _id: "5we326fd24cddr40786c1721e",
      merchantName: "RVSaloon",
      totalPageOpen: "200",
      totalEmployee: 25,
      rate: "3.6",
    },
  ];

  const columns1 = [
    // {
    //   field: "_id",
    //   headerName: "ID",
    //   flex: 1,
    //  // sortable: true,
    // },
    {
      field: "categoryName",
      headerName: "Category Name",
      flex: 0.7,
    },
    {
      field: "totalEmployee",
      headerName: "Total Employee",
     // field: "email",
     // headerName: "Email",
      flex: 0.7,
    },
    {
      field: "rate",
      headerName: "Rate",
      flex: 0.5
    },
    {
      field: "totalPageOpen",
      headerName: "Total Page Open",
      flex: 0.5,
    }
  ];

  const rows1 = [
    {
      id: 11,
      _id: "63e026fd24cddc4076c1721e",
      categoryName: "Qatar Insurance",
      totalPageOpen: "342",
      totalEmployee: 23,
      rate: "3.6",
    },
    {
      id: 22,
      _id: "33a026fd24cddc4076c1721h",
      categoryName: "Gulf Exchange",
      totalPageOpen: "758",
      totalEmployee: 78,
      rate: "2.6",
    },
    {
      id: 33,
      _id: "57e026fd24cddc40786c1721c",
      categoryName: "Masraf Al Rayan",
      totalPageOpen: "328",
      totalEmployee: 65,
      rate: "1.2",
    },
    {
      id: 44,
      _id: "57e026fd24cddc40786c1721d",
      categoryName: "Adnoc",
      totalPageOpen: "547",
      totalEmployee: 36,
      rate: "4.8",
    },
    {
      id: 55,
      _id: "57e026fd24cddc40786c1721e",
      categoryName: "Qatar Insurance",
      totalPageOpen: "213",
      totalEmployee: 43,
      rate: "3.9",
    },
    {
      id: 66,
      _id: "54ve026fd24cddc40786c1721e",
      categoryName: "Dukhan",
      totalPageOpen: "546",
      totalEmployee: 23,
      rate: "3.0",
    },
    {
      id: 77,
      _id: "5we426fd24cddr40786c1721e",
      categoryName: "Zara",
      totalPageOpen: "345",
      totalEmployee: 44,
      rate: "2,9",
    },
    {
      id: 88,
      _id: "5we526fd24cddr40786c1721e",
      categoryName: "Al Reem",
      totalPageOpen: "124",
      totalEmployee: 64,
      rate: "3.1",
    },
    {
      id: 98,
      _id: "5we626fd24cddr40786c1721e",
      categoryName: "Al Jaber",
      totalPageOpen: "230",
      totalEmployee: 64,
      rate: "4.3",
    },
    {
      id: 99,
      _id: "5we726fd24cddr40786c1721e",
      categoryName: "RVSaloon2",
      totalPageOpen: "200",
      totalEmployee: 25,
      rate: "3.6",
    },
  ];

const data = {
  options: {
    chart: {
      id: "basic-bar"
    },
    xaxis: {
      categories: ["Total Employee"]
    }
  },
  series: [
    {
      name: "Rating",
      data: [30]
    }
  ]
}

const data2 = {
  options: {
    plotOptions: {
      radialBar: {
        hollow: {
          size: '50%', // Adjust this to control the size of the circle
        },
        // dataLabels: {
        //   showOn: 'always',
        //   name: {
        //     offsetY: -10,
        //     show: true,
        //     color: '#888',
        //   },
        //   value: {
        //     color: '#FFFFFF',
        //     fontSize: '30px',
        //     show: true,
        //   },
        // },
      },
    },
  },
  series: [2.7 * 20],
  plotOptions: {

    
          dataLabels: {
              show: true,
              name: {
                  show: true,
                  fontSize: '16px',
                  fontFamily: undefined,
                  fontWeight: 600,
                  color: undefined,
                  offsetY: -10
                },
                value: {
                  show: true,
                  fontSize: '14px',
                  fontFamily: undefined,
                  fontWeight: 400,
                  color: undefined,
                  offsetY: 16,
                  formatter: function (val) {
                    return val + '%'
                  }
                },
                total: {
                  show: false,
                  label: 'Total',
                  color: '#373d3f',
                  fontSize: '16px',
                  fontFamily: undefined,
                  fontWeight: 600,
                  formatter: function (w) {
                    return w.globals.seriesTotals.reduce((a, b) => {
                      return a + b
                    }, 0) / w.globals.series.length + '%'
                  }
                }
          }
      }
  }


    
const data3 = {
  options: {
    chart: {
      id: "basic-bar"
    },
    xaxis: {
      categories: ["Total Page Open"]
    }
  },
  series: [
    {
      name: "Rating",
      data: [60]
    }
  ]
}

const dataPi = {}
const bar = {
  options: {
    chart: {
      id: "basic-bar"
    },
    xaxis: {
      categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998]
    }
  },
  series: [
    {
      name: "series-1",
      data: [30, 40, 45, 50, 49, 60, 70, 91]
    }
  ]
};

  return (
    <Box m="1.5rem 2.5rem"  width='90%'>

<Box display="flex" width='100%' flexDirection="row" alignItems="center" 
 //backgroundColor='red'//width="80%" 
 justifyContent="space-between" marginBottom="36px">
   <Box>
   <Box display="flex" flexDirection="row" alignItems="space-between" width="17rem">
        <Typography flex={1} variant="body1" fontSize="15px">Total no. of merchants used</Typography>
        <Typography variant="body1" fontSize="20px">2543</Typography>
      </Box> 
      <Box display="flex" flexDirection="row" alignItems="center" width="17rem">
        <Typography flex={1} variant="body1" fontSize="15px">Total sister companies</Typography>
        <Typography variant="body1" fontSize="20px">14</Typography>
      </Box>
   </Box>
   <Box>
      <Box display="flex" flexDirection="row" alignItems="center" width="17rem">
        <Typography flex={1} variant="body1" fontSize="15px">Total used points</Typography>
        <Typography variant="body1" fontSize="20px">191678</Typography>
      </Box>
      <Box display="flex" flexDirection="row" alignItems="center" width="17rem">
        <Typography flex={1} variant="body1" fontSize="15px">Total Remining point</Typography>
        <Typography variant="body1" fontSize="20px">78543</Typography>
      </Box>
      </Box>
      </Box>

      {/* <Box display="flex" height='200px' width='800px'>
      <Chart
              options={bar.options}
              series={bar.series}
              type="bar"
              width="100%"
            />
            </Box> */}
      {true && (<>
        <Box display="flex" flexDirection="row" justifyContent="space-between" paddingLeft='16px' paddingRight='16px' //width="80%"
         >
          <Chart
            options={data.options}
            series={data.series}
            type="bar"
            width="350"
          />
          <Chart
            options={data3.options}
            series={data3.series}
            type="bar"
            width="350"
          />
        </Box>
        <Box display="flex" flexDirection="row" justifyContent="space-between" paddingLeft='16px' paddingRight='16px' //width="80%"
         >
        
          <Chart
            options={data2.options}
            series={data2.series}
            type="radialBar"
            width={300}
          />
           <Chart
              options={bar.options}
              series={bar.series}
              type="bar"
              width={350}
            />
        </Box>
        </>
      )}

<FlexBetween gap="1.5rem">
        <Header title="Merchants" />
      </FlexBetween>
      <Box
        mt="10px"
        mb="40px"
        height="55vh"
        width="100%"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            // backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },

          "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
        }}
      >
        <DataGrid
          // loading={isLoading || !data}
          // getRowId={(row) => row._id}
          rows={rows}
          columns={columns}
          //slots={{ toolbar: GridToolbar }}
        />
      </Box>

      <FlexBetween gap="1.5rem">
        <Header title="Categories" gap="1.5rem" />
        <FlexBetween gap="1.5rem" alignItems="center">
          {/* <Link to="/adduser" style={{ textDecoration: "none" }}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: theme.palette.secondary[400],
                color: theme.palette.primary[600],
                "&:hover": {
                  backgroundColor: theme.palette.secondary[500],
                  color: "#000000",
                },
              }}
              endIcon={<AddIcon />}
              onClick={() => <AddUser />}
            >
              ADD USER
            </Button>
          </Link> */}

          {/* <InputBase placeholder="Search Users..." />
          <IconButton sx={{ color: theme.palette.secondary[300] }}>
            <Search />
          </IconButton> */}
        </FlexBetween>
      </FlexBetween>
      <Box
        mt="10px"
        height="55vh"
        width="100%"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            // backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },

          "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
        }}
      >
        <DataGrid
          // loading={isLoading || !data}
          //  getRowId={(row) => console.log("rowwww:",row._id) }
          rows={rows1}
          columns={columns1}
          // slots={{ toolbar: GridToolbar }}
        />
      </Box>
    </Box>
  );
};


export default Dashoard
