import axios from "axios";

const API_URL = "https://golalitatwffer.com/api/go/auth/";
//const API_URL = "http://161.97.91.128/api/go/auth/";
const API_URL1 = "http://localhost:9000/api/go/auth/";

const register = (username, email, password,phone) => {
  const a ={ 
    "username":username,
    "merhOrgId":"6285720582",
    "registrationCode":"skgjsgo9",
    "phone":phone,
    "email":email,
    "password":password,
    "privilege":"golalita", //// golalita merchant organization user
    "userType":"employee", //// employee || familyMember
    "identifier":"6235328502852323"
    ,"parentUser":"658a7fcaea3693663ec1cae1"/// if userType familyMember the id of the parent user id  
  
  }
  return axios.post(API_URL + "signup", a);
};

const forgotPassword = ( email) => {
  console.log("forgotPassword XXXXXXXXXXXXX22222:",email)
  return axios.post("http://localhost:9000/api/go/send_otp", {"tyeOperation":"update","email": `${email}`});
};


const updatePassword = ( x) => {
  console.log("updatePassword XXXXXXXXXXXXX22222:",x)
  return axios.put("http://localhost:9000/api/go/resetpassword",x);
};


const otpVerfication = (x) => {
  console.log("otpVerfication XXXXXXXXXXXXX22222:",x)
  return axios.post("http://localhost:9000/api/go/otp_verfication",x);
};



const login = (username, password) => {
  console.log("login1:",username, password)
  const body = {
    "login":`${username}`,
    "password":`${password}`,
    "device_type":"ios",
    "device_token":"efJzDXHNz0h-lj3HfdhFgc:APA91bGuNc8NylO-E0AmlCtgGhYctD-r26MUcGVs6ygJDB5WcxxfzHt6upie4opMmMwNgyOqAAQNmK9MLuxSb03J5h4b6hNQ1PTsc2BonntATQEM50qfrjqMYDiF2wVdhWZa9NmQ9ubz11"
  };
    console.log("body:",body)
  return axios
    .post(API_URL + "signin", body)
    .then((response) => {
      console.log("handleSubmit login service response:",response)
      if (response.data.accessToken) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }

      return response.data;
    })
};

const logout = () => {
  localStorage.removeItem("user");
};

const authService = {
  register,
  login,
  forgotPassword,
  otpVerfication,
  updatePassword,
  logout,
};

export default authService;