import React, { memo, useEffect, useState } from "react";
import {
  Box,
  Button,
  TextField,
  MenuItem,
  FormControlLabel,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import { useDispatch, useSelector } from "react-redux";
import { saveNewUser } from "../../features/users/userSlice";
import YellowSwitch from "../../components/YellowSwitch";
import ImgPreview from "../../components/ImgPreview";
import {
  fetchALLMerchants,
  getAllMerchants,
} from "../../features/merchants/merchantSlice";
import { fetchALLOrganizations } from "../../features/organizations/organizationSlice";
import { getAllOrganizations } from "../../features/organizations/organizationSlice";
// import YellowSwitch from "../../components/YellowSwitch";

const validFileExtensions = {
  file: ["jpg", "gif", "png", "jpeg", "svg", "webp"],
};
function isValidFileType(fileName, fileType) {
  return (
    fileName &&
    validFileExtensions[fileType].indexOf(fileName.split(".").pop()) > -1
  );
}

const UserForm = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const allMerchants = useSelector(getAllMerchants());
  const allOrgnaizations = useSelector(getAllOrganizations);
  const [formValues, setFormValues] = useState();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const dispatch = useDispatch();

  const userSchema = yup.object().shape({
    first_name: yup.string().required("required"),
    last_name: yup.string().required("required"),
    phone: yup.string().required("required"),
    email: yup.string().required("required"),
    username: yup.string().required("required"),
    userFromDate: yup.string().required("required"),
    userToDate: yup.string().required("required"),
    userType: yup.string().required("choose Product Type"),
    // profileImg: yup
    //   .mixed()
    //   .required()
    //   .test("is-valid-type", "Not a valid image type", (value) =>
    //     isValidFileType(value && value.name.toLowerCase(), "file")
    //   ),
    merhOrgId: yup.string().required("required"),
    privilege: yup.string().required("required"),
  });

  useEffect(() => {
    dispatch(fetchALLOrganizations());
    // dispatch(fetchALLProducts());
    // dispatch(fetchALLProductCategories());
    dispatch(fetchALLMerchants());
  }, [dispatch]);

  const initialValues = {
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    username: "",
    userFromDate: "",
    userToDate: "",
    userType: null,
    merhOrgId: null,
    profileImg: [],
    regCode: "",
    password: "",
    // archieve:archieve,
    privilege: "",
  };

  function deleteImageHandler(image) {
    setSelectedFiles(selectedFiles.filter((e) => e !== image));
    URL.revokeObjectURL(image);
  }

  const createNewUser = (data) => {
    dispatch(saveNewUser(data))
      .unwrap()
      .then(() => {
        // navigate("/");
      });

    // console.log("product Created in mongobd");
  };

  const handleFormSubmit = (values) => {
    setFormValues(values);
    var formData = new FormData();
    for (const key of Object.keys(values.profileImg)) {
      console.log("arry : " + values.profileImg[key]);
      formData.append("profileImg", values.profileImg[key]);
    }
    formData.append("regCode", values.regCode);
    formData.append("first_name", values.first_name);
    formData.append("last_name", values.last_name);
    formData.append("phone", values.phone);
    formData.append("email", values.email);
    formData.append("username", values.username);
    formData.append("userFromDate", values.userFromDate);
    formData.append("userToDate", values.userToDate);
    formData.append("userType", values.userType);
    formData.append("merhOrgId", values.merhOrgId);
    formData.append("password", values.password);

    createNewUser(formData);
  };
  console.log("user form values", formValues);

  return (
    <Box m="1rem" pb="0.5rem">
      <Header
        title="ADD USER"
        // subtitle="Fill in the details to add a new product"
      />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={userSchema}
        style={{ marginTop: "20px" }}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="First Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.first_name}
                name="first_name"
                error={!!touched.first_name && !!errors.first_name}
                helperText={touched.first_name && errors.first_name}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Last Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.last_name}
                name="last_name"
                error={!!touched.last_name && !!errors.last_name}
                helperText={touched.last_name && errors.last_name}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Phone Number"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.phone}
                name="phone"
                error={!!touched.phone && !!errors.phone}
                helperText={touched.phone && errors.phone}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Email"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
                name="email"
                error={!!touched.email && !!errors.email}
                helperText={touched.email && errors.email}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Username"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.username}
                name="username"
                error={!!touched.username && !!errors.username}
                helperText={touched.username && errors.username}
                sx={{ gridColumn: "span 2" }}
              />

              {/* <label htmlFor="file" sx={{ width:'100%'}}>
                Offer Period
              </label> */}
              <TextField
                fullWidth
                variant="filled"
                type="date"
                label="From"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.userFromDate}
                name="userFromDate"
                error={!!touched.userFromDate && !!errors.userFromDate}
                helperText={touched.userFromDate && errors.userFromDate}
                sx={{ gridColumn: "span 1" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="date"
                label="To"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.userToDate}
                name="userToDate"
                error={!!touched.userToDate && !!errors.userToDate}
                helperText={touched.userToDate && errors.userToDate}
                sx={{ gridColumn: "span 1" }}
              />
              <TextField
                fullWidth
                variant="filled"
                select // tell TextField to render select
                label="User From"
                onBlur={handleBlur}
                onChange={handleChange}
                // onChange={handleChange}
                // value={values.userType}
                name="userType"
                defaultValue=""
                error={!!touched.userType && !!errors.userType}
                helperText={touched.userType && errors.userType}
                sx={{ gridColumn: "span 2" }}
              >
                <MenuItem key={1} value="Merchant">
                  Merchant
                </MenuItem>
                <MenuItem key={2} value="Organization">
                  Organization
                </MenuItem>
              </TextField>

              {values.userType === "Merchant" ? (
                <TextField
                  fullWidth
                  variant="filled"
                  select // tell TextField to render select
                  label="Choose Merchant / Organization"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  // onChange={handleChange}
                  // value={values.userType}
                  name="merhOrgId"
                  defaultValue=""
                  error={!!touched.merhOrgId && !!errors.merhOrgId}
                  helperText={touched.merhOrgId && errors.merhOrgId}
                  sx={{ gridColumn: "span 2" }}
                >
                  {allMerchants?.map((merchant) => (
                    <MenuItem key={merchant._id} value={merchant._id}>
                      {merchant.merchName}
                    </MenuItem>
                  ))}
                </TextField>
              ) : (
                <TextField
                  fullWidth
                  variant="filled"
                  select // tell TextField to render select
                  label="Choose Merchant / Organization"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  // onChange={handleChange}
                  // value={values.userType}
                  name="merhOrgId"
                  defaultValue=""
                  error={!!touched.merhOrgId && !!errors.merhOrgId}
                  helperText={touched.merhOrgId && errors.merhOrgId}
                  sx={{ gridColumn: "span 2" }}
                >
                  {allOrgnaizations?.map((organization) => (
                    <MenuItem key={organization._id} value={organization._id}>
                      {organization.orgName}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              <TextField
                fullWidth
                variant="filled"
                label="Resgistration Code"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.regCode}
                name="regCode"
                error={!!touched.regCode && !!errors.regCode}
                helperText={touched.regCode && errors.regCode}
                sx={{ gridColumn: "span 1" }}
              />
              <TextField
                fullWidth
                variant="filled"
                label="User Password"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.password}
                name="password"
                error={!!touched.password && !!errors.password}
                helperText={touched.password && errors.password}
                sx={{ gridColumn: "span 1" }}
              />
              <TextField
                fullWidth
                variant="filled"
                select // tell TextField to render select
                label="Privilege"
                onBlur={handleBlur}
                onChange={handleChange}
                // onChange={handleChange}
                // value={values.userType}
                name="privilege"
                defaultValue=""
                error={!!touched.privilege && !!errors.privilege}
                helperText={touched.privilege && errors.privilege}
                sx={{ gridColumn: "span 1" }}
              >
                <MenuItem key={1} value="1">
                  Golalita user
                </MenuItem>
                <MenuItem key={2} value="2">
                  Merchant user
                </MenuItem>
                <MenuItem key={3} value="3">
                  Branch user
                </MenuItem>
                <MenuItem key={4} value="4">
                  Customer
                </MenuItem>
              </TextField>
              <Box
                sx={{
                  gridColumn: "span 4",
                  borderBottom: "1px solid white",
                  marginTop: "20px",
                }}
              >
                {" "}
                <input
                  type="file"
                  multiple
                  onChange={(event) => {
                    setFieldValue("profileImg", event.currentTarget.files);
                    if (event.target.files) {
                      const filesArray = Array.from(event.target.files).map(
                        (file) => URL.createObjectURL(file)
                      );

                      setSelectedFiles((prevImages) =>
                        prevImages.concat(filesArray)
                      );
                      Array.from(event.target.files).map(
                        (file) => URL.revokeObjectURL(file) // avoid memory leak
                      );
                    }
                  }}
                />
              </Box>
              {values.profileImg && (
                <ImgPreview
                  source={selectedFiles}
                  deleteImageHandler={deleteImageHandler}
                />
              )}
              {/* archieve user from user data grid */}
            </Box>
            <Box display="flex" justifyContent="end" m={2}>
              <Button type="submit" color="secondary" variant="contained">
                Add User
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default memo(UserForm);
