import React, { useState } from "react";
import ajwaSulthanhamat from "../assets/ajwa_sultanhmet.png";
import {
  TextField,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  ButtonGroup,
  Button,
  Badge,
  ListItemSecondaryAction,
  IconButton,
} from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

const SearchBarList = ({ suggestions, onBuyItemsChange }) => {
  const [searchValue, setSearchValue] = useState("");
  const [buyList, setBuyList] = useState([]);
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  // const [qtyCount, setQtyCount] = React.useState(1);

  const handleSearchChange = (event) => {
    const value = event.target.value.toLowerCase();
    const filteredSuggestions = suggestions.filter((suggestion) =>
      suggestion.name.toLowerCase().includes(value)
    );
    setSearchValue(event.target.value);
    setFilteredSuggestions(filteredSuggestions);
  };

  const handleSuggestionClick = (suggestion) => {
    const newItem = {
      product_id: suggestion.product_id,
      name: suggestion.name,
      price: suggestion.price,
      productQnt: 0,
    };
    setBuyList([...buyList, newItem]);
    // setBuyList([...buyList, { ...suggestion, productQnt: 0, option: "" }]);
    setSearchValue("");
    setFilteredSuggestions([]);
    onBuyItemsChange([...buyList]);
    // onGetItemsChange([...buyList]);
  };

  const handleDeleteItem = (id) => {
    const updatedItems = buyList.filter((item) => item.product_id !== id);
    setBuyList(updatedItems);
    onBuyItemsChange(updatedItems);
    // onGetItemsChange(updatedItems);
  };

  const handleQuantityChange = (itemId, newQuantity) => {
    newQuantity = Math.max(newQuantity, 0);
    const updatedItems = buyList.map((item) => {
      if (item.product_id === itemId) {
        return { ...item, productQnt: newQuantity };
      }
      return item;
    });
    setBuyList(updatedItems);
    onBuyItemsChange(updatedItems);
    // onGetItemsChange(updatedItems);
  };

  // console.log(buyList, "BuyList");
  // console.log(qtyCount,"qtyCount")
  return (
    <div>
      <TextField
        label="Search Products..."
        value={searchValue}
        onChange={handleSearchChange}
        fullWidth
        variant="outlined"
      />
      {filteredSuggestions.length > 0 && (
        <Paper sx={{ mt: 1, maxHeight: 200, overflow: "auto" }}>
          <List>
            {filteredSuggestions.map((suggestion) => (
              <ListItem
                button
                key={suggestion.name}
                onClick={(event) => handleSuggestionClick(suggestion)}
              >
                <ListItemText
                  primary={suggestion.name}
                  secondary={`Price: $${suggestion.price}`}
                />
              </ListItem>
            ))}
          </List>
        </Paper>
      )}
      {buyList.length > 0 && (
        // <Paper sx={{ mt: 1 }}>
        <List>
          {buyList.map((item) => (
            <ListItem button key={`${item.name}-${item.price}`}>
              <ListItemAvatar>
                <Avatar src={ajwaSulthanhamat} />
              </ListItemAvatar>
              <ListItemText primary={item.name} secondary={`$${item.price}`} />

              <ButtonGroup display="flex">
                <Button
                  aria-label="reduce"
                  color="secondary"
                  onClick={() =>
                    handleQuantityChange(item.product_id, item.productQnt - 1)
                  }
                >
                  <RemoveIcon fontSize="small" />
                </Button>
                <Badge
                  color="secondary"
                  badgeContent={Number(item.productQnt)}
                ></Badge>
                <Button
                  aria-label="increase"
                  color="secondary"
                  onClick={() =>
                    handleQuantityChange(item.product_id, item.productQnt + 1)
                  }
                >
                  <AddIcon fontSize="small" />
                </Button>
              </ButtonGroup>
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => handleDeleteItem(item.product_id)}
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
        // </Paper>
      )}
    </div>
  );
};

export default SearchBarList;
