import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Collapse,
  IconButton,
  Rating,
  Tooltip,
  Typography,
  useTheme,
  Chip,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import FlexBetween from "./FlexBetween";
import YellowSwitch from "./YellowSwitch";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import { Edit } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { setIsEditing } from "../features/products/editSlice";

const ProductCard = ({
  _id,
  rating,
  img,
  title,
  category,
  price,
  description,
  delivery,
  branch
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [isExpanded, setIsExpanded] = useState(false);
  // const isEditing = useSelector(setIsEditing);
  
  const branches = useState(branch);// ["Branches 1", "Branches 2", "Branches 3"];
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(setIsEditing(true));
  };
  // console.log(img, "Imge from producrt");
  return (
    <Card
      sx={{
        backgroundImage: "none",
        backgroundColor: theme.palette.background.alt,
        borderRadius: "0.55rem",
        position: "relative",
      }}
    >
      {/* <Badge
        color="error"
        badgeContent="Discount"
        sx={{ position: "absolute", top: 25, right: 35 }}
      ></Badge> */}
      <CardContent sx={{ display: "flex", flexDirection: "column" }}>
        <FlexBetween>
          <Box>
            <Typography
              sx={{ fontSize: 14 }}
              color={theme.palette.secondary[700]}
              gutterBottom
            >
              {category}
            </Typography>
            <Typography variant="h5" component="div">
              {title}
            </Typography>
            <Typography
              sx={{ mb: "1.5rem" }}
              color={theme.palette.secondary[400]}
            >
              QR &nbsp;{price}
            </Typography>
            <Rating value={rating} readOnly />
          </Box>
          <CardMedia
            component="img"
            sx={{ width: 60, height: 60 }}
            image={img}
            alt="product image preview"
          />
        </FlexBetween>

        <Typography variant="body2">{description}</Typography>
      </CardContent>
      <CardActions>
        <Tooltip title="show/hide product">
          <YellowSwitch defaultChecked />
        </Tooltip>
        {/* <Typography variant="small">show</Typography> */}
        <Box display="flex" justifyContent="flex-end" sx={{ width: "100%" }}>
          <IconButton
            // variant="primary"
            size="small"
            onClick={() => setIsExpanded(!isExpanded)}
          >
            {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </Box>
      </CardActions>
      <Collapse
        in={isExpanded}
        timeout="auto"
        unmountOnExit
        sx={{
          color: theme.palette.neutral[300],
        }}
      >
        <CardContent>
          {branches.map((i) => (
         <>
         {/* {
  console.log( "branchbranchbranch:",i)} */}
         <Chip key={i} label={i} sx={{ margin: "5px" }} />
         </>
         ))}
          {delivery && <Typography>Delivery Available</Typography>}

          <FlexBetween gap="1rem" marginTop="10px">
            <Button
              variant="primary"
              size="small"
              onClick={() => {
                handleClick();
                // navigate("/addproduct/"+_id);
                navigate(`/addproduct/${_id}`);
              }}
            >
              Edit Product
            </Button>
          </FlexBetween>
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default ProductCard;
