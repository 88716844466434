import axios from "axios";
import authHeader from "../../services/auth-header";
import {api_GetALLProducts,api_AddProducts} from "../../constants/api";


const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

export const fetchALLProducts = createAsyncThunk(
  "products/getAPI",
  async () => {
    console.log("fetchALLProducts response starts:")
    const response = await axios.post(api_GetALLProducts,
      {
        "pageNumber":1,
        "limit":12000
       },
      { headers: authHeader() }
      );
      console.log("fetchALLProducts response:", response)
    return response.data;
  }
);

export const fetchALLProductsByMerchants = createAsyncThunk(
  "productsByMerchant/getAPI",
  async (i) => {
    console.log("fetchALLProductsByMerchants response starts:")
    const response = await axios.post(api_GetALLProducts,
      {
        "merchantId":i
        },
      { headers: authHeader() }
      );
      console.log("fetchALLProductsByMerchants response:", response)
    return response.data;
  }
);


export const saveNewProduct = createAsyncThunk(
  "products/createAPI",
  async (payload) => {
    const response = await axios.post(api_AddProducts,
      payload,
      { headers: authHeader() }
    );
    console.log("saveNewProduct res:",response)
    return response.data;
  }
);

export const updateProduct = createAsyncThunk(
  "products/updateAPI",
  async (payload) => {
    console.log(payload, "Payload for reducer");
    const response = await axios.patch(
      `http://161.97.91.128:9000/products/${payload._id}`,
      payload
    );
    return response.data;
  }
);

const initialState = {
  productsData: [],
  productsDataByMerchants:[],
  loading: "idle",
};

const productslice = createSlice({
  name: "products",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchALLProducts.pending, (state, action) => {
      state.loading = "pending";
    });
    builder.addCase(fetchALLProducts.fulfilled, (state, action) => {
      state.loading = "idle";
      state.productsData = action.payload;
    });
    builder.addCase(fetchALLProductsByMerchants.pending, (state, action) => {
      state.loading = "pending";
    });
    builder.addCase(fetchALLProductsByMerchants.fulfilled, (state, action) => {
      state.loading = "idle";
      state.productsDataByMerchants = action.payload;
    });
    builder.addCase(saveNewProduct.pending, (state, action) => {
      state.loading = "pending";
    });
    builder.addCase(saveNewProduct.fulfilled, (state, action) => {
      state.loading = "idle";
      if (!Array.isArray(state.productsData)) {
        state.productsData.data = [];
      }
      state.productsData.data.unshift(action.payload);
    });

    builder.addCase(updateProduct.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(updateProduct.fulfilled, (state, action) => {
      state.loading = "idle";
      state.productsData = state.productsData.filter(
        (_) => _.id !== action.payload.id
      );
      state.productsData.unshift(action.payload);
    });
  },
});

export const getAllProducts = (state) =>  state.product.productsData;
export const getAllProductsByMerchants =  (state) =>state.product.productsDataByMerchants; 

export const getAllProductsByMerchantsForAddNotification = () => {
  return (state) =>state.product.productsDataByMerchants;
};


export const getLoading = (state) => state.product.loading;

export const getProductById = (id) => {
  return (state) => state.product.productsData.data.filter((_) => _._id === id)[0];
};

export default productslice.reducer;
