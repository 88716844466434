import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import FlexBetween from "../../components/FlexBetween";
import Header from "../../components/Header";
import OrderCard from "../../components/OrderCard";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddIcon from "@mui/icons-material/Add";
import MoreMenu from "../../components/MoreMenu";

const options = ["history", "menu"];

const Orders = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery("(min-width: 1000px)");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMore = Boolean(anchorEl);
  const handleMoreClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMoreClose = () => {
    setAnchorEl(null);
  };
  return (
    <Box m="1.5rem 2.5rem">
      <FlexBetween gap="1.5rem">
        <Header
          title="Orders Dashboard"
          subtitle="See your list of current orders."
        />
        <Link to="/orderhistory" style={{ textDecoration: "none" }}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: theme.palette.secondary[400],
              color: theme.palette.primary[600],
              "&:hover": {
                backgroundColor: theme.palette.secondary[500],
                color: "#000000",
              },
            }}
            endIcon={<AddIcon />}
          >
            Order History
          </Button>
        </Link>

        {/* <Box>
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={openMore ? "long-menu" : undefined}
            aria-expanded={openMore ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleMoreClick}
          >
            <MoreVertIcon />
          </IconButton> */}

        {/* <Menu
            id="long-menu"
            MenuListProps={{
              "aria-labelledby": "long-button",
            }}
            anchorEl={anchorEl}
            open={openMore}
            onClose={handleMoreClose}
            PaperProps={{
              style: {
                maxHeight: 48 * 4.5,
                width: "20ch",
              },
            }}
          >
            {options.map((option) => (
              <MenuItem
                key={option}
                //   selected={option === "orders"}
                onClick={() => {
                  // handleClose()
                  navigate(`/${option}`);
                }}
              >
                {option}
              </MenuItem>
            ))}
          </Menu> */}
        {/* <Tooltip title="Export to excel"> */}
        {/* <CSVLink> */}
        {/* <IosShareIcon
                fontSize="large"
                sx={{
                  color: theme.palette.secondary[300],
                  "&:hover": {
                    color: theme.palette.secondary[500],
                  },
                }}
              /> */}
        {/* </CSVLink> */}
        {/* </Tooltip> */}
        {/* </Box> */}
      </FlexBetween>
      <Box
        m="20px"
        display="grid"
        gridTemplateColumns="repeat(3, minmax(0, 1fr))"
        justifyContent="space-between"
        rowGap="20px"
        columnGap="1.33%"
        sx={{
          "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
        }}
      >
        <OrderCard />
        <OrderCard />
        <OrderCard />
        <OrderCard />
        <OrderCard />
        <OrderCard />
      </Box>
    </Box>
  );
};

export default Orders;
