import {
  Card,
  Box,
  Typography,
  Tooltip,
  Divider,
  useTheme,
} from "@mui/material";
import { Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import React from "react";
import PropTypes from "prop-types";
import UserForm from "../users/UserForm";
import YellowSwitch from "../../components/YellowSwitch";

const ProfileInfoCard = ({
  title,
  description,
  info,
  social,
  action,
  shadow,
}) => {
  const labels = [];
  const values = [];
  const theme = useTheme();
  //   const { socialMediaColors } = colors;
console.log("socialsocial:",social)
  Object.keys(info).forEach((el) => {
    if (el.match(/[A-Z\s]+/)) {
      const uppercaseLetter = Array.from(el).find((i) => i.match(/[A-Z]+/));
      const newElement = el.replace(
        uppercaseLetter,
        ` ${uppercaseLetter.toLowerCase()}`
      );

      labels.push(newElement);
    } else {
      labels.push(el);
    }
  });

  // Push the object values into the values array
  Object.values(info).forEach((el) => values.push(el));

  // Render the card info items
  const renderItems = labels.map((label, key) => (
    <Box key={label} display="flex" py={1} pr={2}>
      <Typography variant="button" fontWeight="bold" textTransform="capitalize">
        {label}: &nbsp;
      </Typography>
      <Typography variant="button" fontWeight="regular" color="text">
        &nbsp;{values[key]}
      </Typography>
    </Box>
  ));

  // Render the card social media icons
  const renderSocial = social && social.map(({ link, icon, color }) => (
    <Box
      key={color}
      component="a"
      href={link}
      target="_blank"
      rel="noreferrer"
      fontSize="large"
      color={theme.palette.secondary[300]}
      pr={1}
      pl={0.5}
      lineHeight={1}
    >
      {icon}
    </Box>
  ));

  return (
    <>
      <Card sx={{ height: "100%", minWidth: "25rem", maxWidth: "25rem" }}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          pt={2}
          px={2}
        >
          <Typography
            variant="h6"
            fontWeight="medium"
            textTransform="capitalize"
          >
            {title}
          </Typography>
          {/* <Typography
            component={Link}
            to={action.route}
            variant="body2"
            color="secondary"
          >
            <Link to="/adduser" style={{ color: theme.palette.secondary[300] }}>
              <Tooltip title={action.tooltip} placement="top">
                <EditIcon />
              </Tooltip>
            </Link>
          </Typography> */}
        </Box>

        <Box p={2}>
          {/* <Box mb={2} lineHeight={1}>
          <Typography variant="button" color="text" fontWeight="light">
            {description}
          </Typography>
        </Box> */}
          {/* <Box opacity={0.3}>
          <Divider />
        </Box> */}
          <Box>
            {renderItems}
         {/* {social != undefined &&   <Box display="flex" py={1} pr={2}>
              <Typography
                variant="button"
                fontWeight="bold"
                textTransform="capitalize"
              >
                social: &nbsp;
              </Typography>
              {renderSocial}
            </Box>} */}
          </Box>
        </Box>
      </Card>
    </>
  );
};

ProfileInfoCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  info: PropTypes.objectOf(PropTypes.string).isRequired,
  //social: PropTypes.arrayOf(PropTypes.object).isRequired,
  action: PropTypes.shape({
    route: PropTypes.string.isRequired,
  //  tooltip: PropTypes.string.isRequired,
  }).isRequired,
  shadow: PropTypes.bool,
};

export default ProfileInfoCard;
