import React,{useState, useEffect,useMemo} from "react";
import {
  Box,
  TextField,
  Autocomplete,
  useMediaQuery,
  // useTheme,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import SearchBar from "./SearchBar";import {
  fetchALLMerchants,
  getAllMerchants,
  getAllMerchantsNameId,
 fetchALLBranches,
 fetchALLMerchantsNameId
} from "../features/merchants/merchantSlice";
import { fetchALLOrganizations,getAllOrganizations } from "../features/organizations/organizationSlice";
import {
  fetchALLUsers,
  getAllUsers
} from "../features/users/userSlice";
import { useDispatch, useSelector } from "react-redux";

const FilterLoyalityPoints = ({onSearchHandler,...props}) => {
  console.log("FilterLoyalityPoints onSearchHandler:", onSearchHandler);
  // const theme = useTheme();

  const dispatch = useDispatch();
  const [typeSende, setTypeSende] = useState(null);
  const [senderList, setSenderList] = useState([]);
  const [receiverList, setReceiverList] = useState([]);
  const [selectedSender, setSelectedSender] = useState(null);
  const [selectedReceiver, setSelectedReceiver] = useState(null);
  const [typeReceiver, setTypeReceiver] = useState(null);
  const isNonMobile = useMediaQuery("(min-width: 1000px)");
  const Users = useSelector(getAllUsers);
  const Merchants = useSelector(getAllMerchantsNameId());
  const Organizations = useSelector(getAllOrganizations);
 
  //console.log("searchHandler allUsers:",Users)
  //console.log("searchHandler allMerchants:",Merchants)
  //console.log("searchHandler allOrganizations:",Organizations)


  useEffect(() => {
    dispatch(fetchALLUsers());
    dispatch(fetchALLMerchantsNameId());
    dispatch(fetchALLOrganizations());

  }, [dispatch]);

  const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
    gridColumn: "span 1",
    "& .MuiOutlinedInput-root": {
      color: theme.palette.secondary[500],
      borderRadius: "2rem",
    },
    "& .MuiInputLabel-root": {
      color: theme.palette.secondary[400],
    },
    "& + .MuiAutocomplete-popper .MuiAutocomplete-option": {
      backgroundColor: theme.palette.primary[500],
    },
    "& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected='true']":
      {
        color: theme.palette.secondary[500],
      },
    "& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected ='true'].Mui-focused":
      {
        color: theme.palette.secondary[500],
      },
  }));

  const searchHandler = (a) => {
    // console.log("searchHandler triggered");
    // console.log("searchHandler triggered typeSende:", typeSende);
    // console.log("searchHandler triggered selectedSender:", selectedSender);
    // console.log("searchHandler triggered typeReceiver:", typeReceiver);
    // console.log("searchHandler triggered selectedReceiver:", selectedReceiver);
    // if ((typeSende || selectedSender || typeReceiver || selectedReceiver) == null ) {
    // alert("asdf")
    // } else {
     
      onSearchHandler(a);
      console.log("searchHandler triggered Overall:", a);
 //   }
  };

  
  useMemo(() => {
    console.log("inside useMemo:", selectedSender);
    const a = {
       typeSende: typeSende ,
       senderId: selectedSender ,
       typeReceiver: typeReceiver,
       receiverId: selectedReceiver ,
    };
    searchHandler(a);
  }, [typeSende,setTypeSende,typeReceiver,setTypeReceiver,selectedSender,selectedReceiver]);


  const senderTypeChangeHandler = (i)=>{
    console.log("senderTypeChangeHandler triggered",i)
    setTypeSende(i);
    if (i == "Merchant"){
      setSenderList(Merchants)
    }
    else if (i == "Organisation"){
      setSenderList(Organizations)
    }
    else if (i == "User") {
      setSenderList(Users)
    }
    else if (i == 'Golalita') {
      setSelectedSender("65844c69179bf8d29ec307d6")
    }
    else {
      setSelectedSender(null)

    }
  }

  const receiverTypeChangeHandler = (i)=>{
    console.log("receiverTypeChangeHandler triggered",i)
    setTypeReceiver(i);
    if (i == "Merchant"){
    setReceiverList(Merchants)
    }
    else if (i == "Organisation"){
      setReceiverList(Organizations)
    }
    else if (i == "User") {
      setReceiverList(Users)
    }
    else if (i == 'Golalita') {
      setSelectedReceiver("65844c69179bf8d29ec307d6")
    }
    else {
      setSelectedReceiver(null)
    }
  }



  return (
    <Box
      display="grid"
      gap="30px"
      gridTemplateColumns="repeat(5, minmax(0, 1fr))"
      sx={{
        "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
      }}
    >
      <StyledAutocomplete
        disablePortal
        id="combo-box-demo1"
        options={senderTypes}
        value={typeSende} 
        onChange={(event, newValue) => {
          console.log("StyledAutocomplete onchange:",newValue)
          senderTypeChangeHandler(newValue.label);
        }}
        renderInput={(params) => <TextField {...params} label="Sender Type" />}
      />
      {/* { console.log("&&&&:",senderList) } */}
{typeSende != 'Golalita' &&  //console.log("&&&&:",senderList) &&
      <StyledAutocomplete
        disablePortal
        id="combo-box-demo2"
        options={senderList && senderList}
        value={selectedSender} 
        onChange={(event, newValue) => {
          console.log("StyledAutocomplete onchange Sender:",newValue)
          setSelectedSender(newValue._id);
        }}
        getOptionLabel={(option) => option.merchName || option.username || option.orgName}
        renderInput={(params) => <TextField {...params} label="Sender" />}
      />}
      <StyledAutocomplete
        disablePortal
        id="combo-box-demo3"
        options={receiverTypes}
        value={typeReceiver} 
        onChange={(event, newValue) => {
           setTypeReceiver(newValue.label)
          console.log("StyledAutocomplete onchange:",newValue)
          receiverTypeChangeHandler(newValue.label);
        }}
        renderInput={(params) => (
          <TextField {...params} label="Receiver Type" />
        )}
      />
    {(typeReceiver !== 'Golalita' && typeReceiver !== 'All') &&
      <StyledAutocomplete
        disablePortal
        id="combo-box-demo4"
        getOptionLabel={(option) => option.merchName || option.username || option.orgName}
        options={receiverList && receiverList}
        value={selectedReceiver} 
        onChange={(event, newValue) => {
          console.log("StyledAutocomplete onchange Receiver:",newValue)
          setSelectedReceiver(newValue._id);
        }}
        renderInput={(params) => <TextField {...params} label="Receiver" />}
      />}
      <SearchBar placeholder="Search..." />
    </Box>
  );
};

export default FilterLoyalityPoints;

const senderTypes = [
  { label: 'Golalita' },
  { label: "Merchant" },
  { label: "Organisation" },
  { label: "User" },
];

const receiverTypes = [
  { label: "All" },
  { label: 'Golalita' },
  { label: "Merchant" },
  { label: "Organisation" },
  { label: "User" },
];
