import axios from "axios";
import authHeader from "../../services/auth-header";

import {api_get_all_user,api_get_userdetails} from "../../constants/api";


const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

export const fetchALLUsers = createAsyncThunk(
  "users/getAPI",
  async () => {
    const response = await axios.get(api_get_all_user,
       { headers: authHeader() });
    console.log("fetchALLUsers response:", response)
    return response.data;
  }
);

export const fetchUserDetails = createAsyncThunk(
  "userdetails/getAPI",
  async () => {
    const response = await axios.get(api_get_userdetails,
       { headers: authHeader() });
    console.log("fetchUserDetails response:", response)
    return response.data;
  }
);

export const saveNewUser = createAsyncThunk(
  "users/createAPI",
  async (payload) => {
    console.log("saveNewUser payload:", payload)
    const response = await axios.post(
      "http://localhost:9000/api/go/auth/signup",
    //  "http://localhost:9000/api/go/auth/signup",
    //  "http://161.97.91.128:9000/users",
      payload
    );
    console.log("saveNewUser response:", response)
    return response.data;
  }
);

export const updateUser = createAsyncThunk(
  "users/updateAPI",
  async (payload) => {
    const response = await axios.put(
      `http://161.97.91.128:9000/users/${payload.id}`,
      payload
    );
    return response.data;
  }
);

const initialState = {
  usersData: [],
  usersDetails: [],
  loading: "idle",
};

const userslice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchALLUsers.pending, (state, action) => {
      state.loading = "pending";
    });
    builder.addCase(fetchALLUsers.fulfilled, (state, action) => {
      state.loading = "idle";
      state.usersData = action.payload;
    });

    builder.addCase(fetchUserDetails.pending, (state, action) => {
      state.loading = "pending";
    });
    builder.addCase(fetchUserDetails.fulfilled, (state, action) => {
      state.loading = "idle";
      state.usersDetails = action.payload;
    });
    builder.addCase(saveNewUser.pending, (state, action) => {
      state.loading = "pending";
    });
    builder.addCase(saveNewUser.fulfilled, (state, action) => {
      state.loading = "idle";
      state.usersData.unshift(action.payload);
    });

    builder.addCase(updateUser.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(updateUser.fulfilled, (state, action) => {
      state.loading = "idle";
      state.usersData = state.usersData.filter(
        (_) => _.id !== action.payload.id
      );
      state.usersData.unshift(action.payload);
    });
  },
});

export const getAllUsers = (state) => state.user.usersData.resultat;
export const getUserDetails = (state) => state.user.usersDetails;
export const getLoading = (state) => state.user.loading;

export const getUserById = (id) => {
  return (state) => state.user.usersData.filter((_) => _.id === id)[0];
};

export default userslice.reducer;
