import axios from "axios";
import authHeader from "../../services/auth-header";

import {api_GetALLOffers,api_Update_Offers ,api_Add_Offers,token} from "../../constants/api";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

export const fetchALLOffers = createAsyncThunk(
  "offers/getAPI",
  async (i) => {
    //console.log("fetchALLOffers:",i)
    const response = await axios.post(api_GetALLOffers,
      { },
       { headers: authHeader() }
      // {
      //   headers: {
      //     "x-access-token": token
      //   },
      // }
      );
    //console.log("fetchALLOffers response:", response)
    return response.data;
  }
);


export const saveNewOffer = createAsyncThunk(
  "offers/createAPI",
  async (payload) => {
    console.log("payloadpayload:",payload)
    const response = await axios.post(api_Add_Offers,
      payload,
      { headers: authHeader() }
      // {
      //   headers: {
      //     "x-access-token": token
      //   },
      // }
    );
    console.log("saveNewOffer response:", response)
    return response;
  }
);

export const updateOffer = createAsyncThunk(
  "offers/updateAPI",
  async (payload) => {
    console.log(payload, "Payload for reducer");
    const response = await axios.patch(api_Update_Offers,
      payload,
      { headers: authHeader() }
      // {
      //   headers: {
      //     "x-access-token": token
      //   },
      // }
    );
    console.log("updateOffer response:", response)
    return response;
  }
);



const initialState = {
  offersData: [],
  loading: "idle",
};

const offerslice = createSlice({
  name: "offers",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchALLOffers.pending, (state, action) => {
      state.loading = "pending";
    });
    builder.addCase(fetchALLOffers.fulfilled, (state, action) => {
      state.loading = "idle";
      state.offersData = action.payload;
    });

  },
});

// export const getAllMerchants = (state) => state.merchant.merchantsData;
export const getAllOffers = () => {
  return (state) =>
//console.log("state.offer.offer::::",state.offer)
state.offer.offersData      
//state.merchant.merchantsData.filter((_) => _.type === "merchant");
};
export const getLoading = (state) => state.offer.loading;

export const getOfferById = (id) => {
  return (state) => 
    //console.log("state.offer.offersData.:",state.offer.offersData)
   state.offer.offersData && state.offer.offersData.data && state.offer.offersData.data.filter((_) => _._id === id)[0];
};

export const getOferType = () => {
  return (state) =>
    state.offer.offersData.data.filter((_) => _.type === "branch");
};
// console.log(getMerchantType("645d5d43e70515bc1bf8da0e"),"reducer")
export default offerslice.reducer;
