import React, { useState } from "react";
import ajwaSulthanhamat from "../assets/ajwa_sultanhmet.png";
import {
  TextField,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  ButtonGroup,
  Button,
  Badge,
  ListItemSecondaryAction,
  IconButton,
  MenuItem,
  ToggleButtonGroup,
  ToggleButton,
  Box,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

const SearchBarList = ({ suggestions, onDiscountItemsChange }) => {
  const [buyList, setBuyList] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [selectedItem, setSelectedItem] = useState([]);
  const handleSearchChange = (event) => {
    const value = event.target.value.toLowerCase();
    const filteredSuggestions = suggestions.filter((suggestion) =>
      suggestion.productName.toLowerCase().includes(value)
    );
    setSearchValue(event.target.value);
    setFilteredSuggestions(filteredSuggestions);
  };

  const handleSuggestionClick = (suggestion) => {
    console.log("handleSuggestionClick1:",suggestion)
    const newItem = {
      Products: suggestion._id,
      productName: suggestion.productName,
      price: suggestion.productPrice[0].price,
      discount: 0,
      option: "discount",
    };
    console.log("handleSuggestionClick newItem:",newItem)
    const customItem = {
      product_id: suggestion.product_id,
      discount: Number(suggestion.discount),
      option: "discount",
    };
    // setSelectedItem([...selectedItem, newItem]);
    setBuyList([...buyList, newItem]);
    setSearchValue("");
    setFilteredSuggestions([]);
    // buyList.map(eachItem => {
    //   onDiscountItemsChange
    // })
    // onDiscountItemsChange([...buyList]);
  
  };

  const handleDeleteItem = (id) => {
    const updatedItems = buyList.filter((item) => item.product_id !== id);
    // const updatedItems = selectedItem.filter((item) => item.product_id !== id)
    setBuyList(updatedItems);
    // setSelectedItem(updatedItems)
    onDiscountItemsChange(updatedItems);
  };

  const handleDiscountChange = (event, index) => {
    const newSelectedItems = [...buyList];
    // const newSelectedItems = [...selectedItem];
    newSelectedItems[index].discount = event.target.value;
    setBuyList(newSelectedItems);
    // setSelectedItem(newSelectedItems)
    onDiscountItemsChange(newSelectedItems);
  };

  const handleOptionChange = (event, index, newOption) => {
    // const newSelectedItems = [...selectedItem];
    const newSelectedItems = [...buyList];
    newSelectedItems[index].option = newOption;
    setBuyList(newSelectedItems);
    // setSelectedItem(newSelectedItems)
    onDiscountItemsChange(newSelectedItems);
  };

  // console.log(newItem, "newItem");
  return (
    <div>
      <TextField
        label="Search Products..."
        value={searchValue}
        onChange={handleSearchChange}
        fullWidth
        variant="outlined"
      />
      {filteredSuggestions.length > 0 && (
        <Paper sx={{ mt: 1, maxHeight: 200, overflow: "auto" }}>
          <List>
            {filteredSuggestions.map((suggestion) => (
              <ListItem
                button
                key={suggestion.productName}
                onClick={(event) => handleSuggestionClick(suggestion)}
              >
                <ListItemText
                  primary={suggestion.productName}
                  secondary={`Price: $${suggestion.productPrice[0].price}`}
                />
              </ListItem>
            ))}
          </List>
        </Paper>
      )}
      {buyList.length > 0 && (
        <List>
          {buyList.map((item, index) => (
           <>
            <ListItem button key={`${item.productName}-${item.price}`}>
              <ListItemAvatar>
                <Avatar src={ajwaSulthanhamat} />
              </ListItemAvatar>
              <ListItemText primary={item.productName} secondary={`$${item.price}`} />
              <Box sx={{ marginX: "20px" }}>
                <ToggleButtonGroup
                  color="secondary"
                  value={item.option}
                  exclusive
                  onChange={(event, newOption) =>
                    handleOptionChange(event, index, newOption)
                  }
                >
                  <ToggleButton value="discount">Discount</ToggleButton>
                  <ToggleButton value="specialPrice">
                    Special Price
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>
              <TextField
                type="number"
                value={Number(item.discount)}
                onChange={(event) => handleDiscountChange(event, index)}
                label={
                  item.option === "specialPrice"
                    ? "Special Price"
                    : "Discount %"
                }
              />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => handleDeleteItem(item.product_id)}
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
           </>
          ))}
        </List>
      )}
    </div>
  );
};

export default SearchBarList;
