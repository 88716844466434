import axios from "axios";
import authHeader from "../../services/auth-header";

import {api_GetAppFeedbacks} from "../../constants/api";


const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

export const fetchAppfeedbacks = createAsyncThunk(
  "feedbacks/getAPI",
  async () => {
    const response = await axios.post(api_GetAppFeedbacks,
      {},
      { headers: authHeader() });
    console.log("fetchAppfeedbacks res:",response)
    return response.data;
  }
);

export const saveNewfeedback = createAsyncThunk(
  "feedbacks/createAPI",
  async (payload) => {
    const response = await axios.post(
      "http://161.97.91.128:9000/feedbacks",
      payload
    );
    return response.data;
  }
);

export const updatefeedback = createAsyncThunk(
  "feedbacks/updateAPI",
  async (payload) => {
    const response = await axios.put(
      `http://161.97.91.128:9000/feedbacks/${payload.id}`,
      payload
    );
    return response.data;
  }
);

const initialState = {
  feedbacksData: [],
  appFeedbacksData: [],
  loading: "idle",
};

const feedbackslice = createSlice({
  name: "feedbacks",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAppfeedbacks.pending, (state, action) => {
      state.loading = "pending";
    });
    builder.addCase(fetchAppfeedbacks.fulfilled, (state, action) => {
      state.loading = "idle";
      state.appFeedbacksData = action.payload;
    });
    builder.addCase(saveNewfeedback.pending, (state, action) => {
      state.loading = "pending";
    });
    builder.addCase(saveNewfeedback.fulfilled, (state, action) => {
      state.loading = "idle";
      state.feedbacksData.unshift(action.payload);
    });

    builder.addCase(updatefeedback.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(updatefeedback.fulfilled, (state, action) => {
      state.loading = "idle";
      state.feedbacksData = state.feedbacksData.filter(
        (_) => _.id !== action.payload.id
      );
      state.feedbacksData.unshift(action.payload);
    });
  },
});

export const getAppfeedbacks = () => {
  return (state) =>
  state.feedback.appFeedbacksData     
};
export const getLoading = (state) => state.feedback.loading;

export const getfeedbackById = (id) => {
  return (state) => state.feedback.feedbacksData.filter((_) => _.id === id)[0];
};

export default feedbackslice.reducer;
