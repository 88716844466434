import React, { useState, useEffect } from "react";
import { useTheme } from "@emotion/react";
import { Button, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import { useNavigate,Link } from "react-router-dom";
import FlexBetween from "../../components/FlexBetween";
import Header from "../../components/Header";
import torchDoha from "../../assets/torchDoha.jpeg";
import NotificationCard from "../../components/NotificationCard";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchNotifications,
  getNotifications,
  pushnotification
} from "../../features/notifications/notificationSlice";

const Notifications = () => {
  const isNonMobile = useMediaQuery("(min-width: 1000px)");
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const notifications = useSelector(getNotifications());

  console.log(notifications, "notifications");
  useEffect(() => {
    dispatch(fetchNotifications());
  }, [dispatch]);

  const pushNotificationHandler = (i) => {
    console.log("pushNotificationHandler:",i)
    const b = {
      "notificationId":i
    }
    dispatch(pushnotification(b))
    .unwrap()
    .then((res) => {
      console.log("updateOffer res:",res)
      if(res.status == 200){
      //  navigate("/notifications");
  //      window.location.reload();
    dispatch(fetchNotifications());
      }
      // 
    });
  };

  return (
    <Box m="1rem" pb="0.5rem">
      <FlexBetween gap="1.5rem">
        <Header title="NOTIFICATIONS" subtitle="Notifications History" />

        <Box>
          <Link to="/addnotification" style={{ textDecoration: "none" }}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: theme.palette.secondary[400],
                color: theme.palette.primary[600],
                "&:hover": {
                  backgroundColor: theme.palette.secondary[500],
                  color: "#000000",
                },
              }}
            >
              CREATE NOTIFICATION
            </Button>
          </Link>
        </Box>
      </FlexBetween>
      <Box
        mt="20px"
        display="grid"
        gridTemplateColumns="repeat(4, minmax(0, 1fr))"
        justifyContent="space-between"
        rowGap="20px"
        columnGap="1.33%"
        sx={{
          "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
        }}
      >
        {notifications &&
          notifications.map((i) =>  <NotificationCard
              title={i.notificationTitle}
              image={i.notificationImageSmall[0]}
              description={i.notificationMessage}
              pushed={i.pushed}
              pushNotificationButton={()=>pushNotificationHandler(i._id)}
              chip={"Buy 1 Get 1"}
              date="12 May 2023"
            />
          )}
        {/* <NotificationCard
          title="Best offers of the holy month"
          image={torchDoha}
          description="Gucci Offer"
          chip="Special Rate"
          date="03 June 2023"
        />

        <NotificationCard
          title="Offers are pouring in, don't miss out"
          image={torchDoha}
          description="Gucci Offer"
          chip="Buy 2 Get 2"
          date="13 October 2023"
        />

        <NotificationCard
          title="Offers are pouring in, don't miss out"
          image={torchDoha}
          description="Gucci Offer"
          chip="Buy 2 Get 2"
          date="13 October 2023"
        /> */}
      </Box>
    </Box>
  );
};

export default Notifications;
