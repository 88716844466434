import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Collapse,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import FlexBetween from "./FlexBetween";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditIcon from "@mui/icons-material/Edit";
import { setIsEditingOrg } from "../features/organizations/editSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

function OrganizationCard({ title, logo,_id }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isExpanded, setIsExpanded] = useState(false);

  const handleClick = () => {
    dispatch(setIsEditingOrg(true));
  };

  return (
    <Card
      sx={{
        minWidth: 275,
        backgroundImage: "none",
        backgroundColor: theme.palette.background.alt,
        borderRadius: "0.55rem",
        position: "relative",
      }}
    >
      <CardMedia
        component="img"
        height="194"
        sx={{
          padding: 3,
          objectFit: "contain",
        }}
        image={logo}
        alt="Premium Organization Logo"
      />
      <CardContent>
        <Typography
          sx={{ textAlign: "center" }}
          gutterBottom
          variant="h5"
          component="div"
        >
          {title}
        </Typography>

        {/* <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            height: "100%",
            width: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.4)",
            zIndex: 1,
            opacity: 0,
            transition: "opacity 0.3s ease-in-out",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            "&:hover": {
              opacity: 1,
            },
          }}
        >Hello</Box> */}
        <FlexBetween sx={{ width: "100%" }}>
          <Tooltip title="Edit Organization">
            <IconButton
              variant="primary"
              size="small"
              // onClick={() => setIsExpanded(!isExpanded)}
            >
              <EditIcon
                sx={{
                  "&:hover": {
                    color: theme.palette.secondary[500],
                  },
                }}
                onClick={() => {
                  handleClick();
                  navigate(`/addorganization/${_id}`);
                }}
              />
            </IconButton>
          </Tooltip>
          <IconButton
            // variant="primary"
            size="small"
            onClick={() => setIsExpanded(!isExpanded)}
          >
            {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </FlexBetween>
      </CardContent>
      <Collapse
        in={isExpanded}
        timeout="auto"
        unmountOnExit
        sx={{
          color: theme.palette.neutral[300],
        }}
      >
        <CardContent>
          <Typography>Number of users: 846</Typography>
          <Typography>Contact Number: 846784</Typography>
          <Typography>Email: something@gmail.com</Typography>
          <Typography>Contract Expiry: May 2024</Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
}

export default OrganizationCard;
