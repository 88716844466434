import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import FlexBetween from "../../components/FlexBetween";
import {
  Box,
  useTheme,
  InputBase,
  IconButton,
  Button,
  ChipProps,
  Chip,
  useMediaQuery,
  Tooltip,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Search } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import DoneIcon from "@mui/icons-material/Done";
import WarningIcon from "@mui/icons-material/Warning";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import { DataGrid, GridRenderCellParams, GridToolbar } from "@mui/x-data-grid";
import { Link, useNavigate } from "react-router-dom";
import AddUser from "./UserForm";

import {
  fetchALLUsers,
  getAllUsers
} from "../../features/users/userSlice";
const Users = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const navigate = useNavigate()
  const [userArchieve, setUserArchieve] = useState(false);
  const allUsers = useSelector(getAllUsers);
   console.log("allUsers:",allUsers)
  const handleClick = (event, cellValues) => {
    console.log(cellValues.row.id, userArchieve);
    setUserArchieve((prev) => !prev);
  };


  useEffect(() => {
    console.log("users********* users*****")
    dispatch(fetchALLUsers());
    console.log("users********* users*****222")

  }, [dispatch]);


  function getChipProps(params){
    if (params.value === "Archieve") {
      return {
        icon: <WarningIcon style={{ fill: "#D23030" }} />,
        label: params.value,
        style: {
          borderColor: "#D23030",
          color: "#D23030",
        },
      };
    } else {
      return {
        icon: <DoneIcon style={{ fill: "#66BB6A" }} />,
        label: params.value,
        style: {
          borderColor: "#66BB6A",
          color: "#66BB6A",
        },
      };
    }
  }

  const columns = [
    {
      field: "_id",
      headerName: "ID",
      flex: 1,
      sortable: true,
    },
    {
      field: "username",
      headerName: "Name",
      flex: 0.7,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 0.7,
    },
    {
      field: "phone",
      headerName: "Phone Number",
      flex: 0.5,
      renderCell: (params) => {
        return params.value.replace(/^(\d{3})(\d{3})(\d{4})/, "($1)$2-$3");
      },
    },
    {
      field: "userType",
      headerName: "Type",
      flex: 0.5,
    },
    // {
    //   field: "status",
    //   headerName: "Status",
    //   // type: "boolean",
    //   type: "singleSelect",
    //   valueOptions: ["Archieve", "Available"],
    //   editable: true,
    //   flex: 0.7,
    //   renderCell: (params) => {
    //     return (
    //       <Chip variant="outlined" size="small" {...getChipProps(params)} />
    //     );
    //   },
    // },
    // {
    //   field: "actions",
    //   headerName: "Action",

    //   flex: 0.5,
    //   renderCell: (cellValues) => {
    //     return (
    //       <FlexBetween gap="0.5rem">
    //         {/* <Button
    //           variant="contained"
    //           sx={{
    //             backgroundColor: theme.palette.secondary[400],
    //             color: theme.palette.primary[600],
    //             "&:hover": {
    //               backgroundColor: theme.palette.secondary[500],
    //               color: "#000000",
    //             },
    //             height: "1.3rem",
    //             borderRadius: "10px",
    //           }}
    //           onClick={(event) => {
    //             handleClick(event, cellValues);
    //           }}
    //         >
    //           Edit
    //         </Button> */}
    //         <Tooltip title="Edit">
    //           <IconButton onClick={() => navigate('/adduser')}>
    //             <CreateOutlinedIcon
    //               sx={{ color: theme.palette.secondary[400] }}
    //             />
    //           </IconButton>
    //         </Tooltip>
    //       </FlexBetween>
    //     );
    //   },
    // },
  ];

  const rows = [
    {
      id: 1,
      _id: "63e026fd24cddc4076c1721e",
      name: "Johnas Peter",
      email: "johnaspeter@gmail.com",
      country: "qatar",
      phoneNumber: "97456958645",
      lolPoints: 7464,
      status: "Available",
    },
    {
      id: 2,
      _id: "33a026fd24cddc4076c1721h",
      name: "Smith Peter",
      email: "smith@gmail.com",
      country: "UAE",
      phoneNumber: "97456958645",
      lolPoints: 5464,
      status: "Available",
    },
    {
      id: 3,
      _id: "57e026fd24cddc40786c1721e",
      name: "Fatima Khalid",
      email: "fatom@gmail.com",
      country: "Bahrain",
      phoneNumber: "97456958645",
      lolPoints: 4464,
      status: "Available",
    },
    {
      id: 4,
      _id: "57e026fd24cddc40786c1721e",
      name: "Thahsin Shaik",
      email: "fatom@gmail.com",
      country: "qatar",
      phoneNumber: "97456958645",
      lolPoints: 4464,
      status: "Available",
    },
    {
      id: 5,
      _id: "57e026fd24cddc40786c1721e",
      name: "Yazed",
      email: "fatom@gmail.com",
      country: "qatar",
      phoneNumber: "97456958645",
      lolPoints: 4464,
      status: "Available",
    },
    {
      id: 6,
      _id: "54ve026fd24cddc40786c1721e",
      name: "Aysha Rashid",
      email: "fatom@gmail.com",
      country: "qatar",
      phoneNumber: "97456958645",
      lolPoints: 4464,
      status: "Available",
    },
    {
      id: 7,
      _id: "5we026fd24cddr40786c1721e",
      name: "Sara Yousuf",
      email: "fatom@gmail.com",
      country: "qatar",
      phoneNumber: "97456958645",
      lolPoints: 4464,
      status: "Available",
    },
    {
      id: 8,
      _id: "5we026fd24cddr40786c1721e",
      name: "Reem Nasar",
      email: "fatom@gmail.com",
      country: "qatar",
      phoneNumber: "97456958645",
      lolPoints: 4464,
      status: "Archieve",
    },
    {
      id: 9,
      _id: "5we026fd24cddr40786c1721e",
      name: "Maraim Jaber",
      email: "fatom@gmail.com",
      country: "qatar",
      phoneNumber: "97456958645",
      lolPoints: 4464,
      status: "Available",
    },
    {
      id: 10,
      _id: "5we026fd24cddr40786c1721e",
      name: "For You Saloon",
      email: "fatom@gmail.com",
      country: "qatar",
      phoneNumber: "97456958645",
      lolPoints: 4464,
      status: "Available",
    },
  ];

  return (
    <Box m="1.5rem 2.5rem">
      <FlexBetween gap="1.5rem">
        <Header title="Users Data" />
        <FlexBetween gap="1.5rem" alignItems="center">
          <Link to="/adduser" style={{ textDecoration: "none" }}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: theme.palette.secondary[400],
                color: theme.palette.primary[600],
                "&:hover": {
                  backgroundColor: theme.palette.secondary[500],
                  color: "#000000",
                },
              }}
              endIcon={<AddIcon />}
              onClick={() => <AddUser />}
            >
              ADD USER
            </Button>
          </Link>

          {/* <InputBase placeholder="Search Users..." />
          <IconButton sx={{ color: theme.palette.secondary[300] }}>
            <Search />
          </IconButton> */}
        </FlexBetween>
      </FlexBetween>
      <Box
        mt="10px"
        height="75vh"
        width="100%"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            // backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },

          "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
        }}
      >
   {allUsers != undefined &&     <DataGrid
          // loading={isLoading || !data}
           getRowId={(row) => row._id}
          rows={allUsers && allUsers}
          columns={columns}
          slots={{ toolbar: GridToolbar }} 
        />}
      </Box>
    </Box>
  );
};

export default Users;
